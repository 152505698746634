import React from "react";
import i18n from "../../../i18n/i18n";

const getOrderOptionButton = (
  orderOptionID,
  handleChange,
  text,
  clickedOrderOptionID
) => {
  return (
    <button
      onClick={() => handleChange("OrderOption", orderOptionID)}
      className={
        clickedOrderOptionID === orderOptionID
          ? "single-product-button_clicked"
          : "single-product-button"
      }
    >
      {text}
    </button>
  );
};

const getOrderOptions = (
  isDeliverable,
  isProductToGo,
  handleChange,
  clickedOrderOptionID
) => {
  if (isDeliverable) {
    return getOrderOptionButton(
      3,
      handleChange,
      i18n.t("POST_EXPRESS_DELIVERY"),
      clickedOrderOptionID
    );
  } else if (isProductToGo) {
    return getOrderOptionButton(
      0,
      handleChange,
      i18n.t("ORDER_TO_GO"),
      clickedOrderOptionID
    );
  } else {
    return (
      <React.Fragment>
        {getOrderOptionButton(
          1,
          handleChange,
          i18n.t("ORDER_AHEAD"),
          clickedOrderOptionID
        )}
        {getOrderOptionButton(
          2,
          handleChange,
          i18n.t("ORDER_STORE"),
          clickedOrderOptionID
        )}
      </React.Fragment>
    );
  }
};

export default getOrderOptions;
