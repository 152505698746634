import React from "react";

const InfoTextPage = (props) => {
  const { text } = props;

  return (
    <div className="info-text">
      <p className="info-text_text">{text}</p>
    </div>
  );
};

export default InfoTextPage;
