import React from "react";
import { useTranslation } from "react-i18next";

// Constants
import getOrderOptions from "./orderingOptionsHelper";

const OrderingOptions = (props) => {
  const { handleChange, clickedOrderOptionID, isDeliverable, isProductToGo } =
    props;

  const { t } = useTranslation();

  return (
    <div>
      <p className="order-details-title">{t("ORDERING_OPTIONS")}</p>

      <div className="ordering-options-button-container">
        {getOrderOptions(
          isDeliverable,
          isProductToGo,
          handleChange,
          clickedOrderOptionID
        )}
      </div>
    </div>
  );
};

export default OrderingOptions;
