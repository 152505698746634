export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const handleValidField = (
  value,
  setValidField,
  setShowError,
  setEmptyFieldError,
  regex
) => {
  const condition = regex ? regex.test(value?.trim()) : value?.length >= 8;

  if (!value) {
    setValidField(true);
    setShowError(false);
    setEmptyFieldError(true);
  } else {
    if (condition) {
      setValidField(true);
      setShowError(false);
      setEmptyFieldError(false);
    } else {
      setValidField(false);
      setShowError(true);
      setEmptyFieldError(false);
    }
  }
};

export const handleEmptyField = (value, setEmptyFieldError) => {
  if (!value) {
    setEmptyFieldError(true);
  } else {
    setEmptyFieldError(false);
  }
};
