// Services
import { FETCH_AllOrdersByUserId } from "../../services/orderService";
import { FETCH_MyReviews } from "../../services/reviewService";
import { FETCH_MyArticles } from "../../services/articleService";

// Components
import OrderHistory from "../../components/userProfilePage/OrderHistory/OrderHistory";
import Review from "../../components/review/Review";
import ArticleWithDescription from "../../components/knowledgePage/ArticleWithDescription/ArticleWithDescription";
import CreditCard from "../../components/userProfilePage/creditCard/CreditCard";

const getDashboardButtonData = (
  active,
  userID,
  token,
  setRenderData,
  orderButtonID,
  userActiveOrders,
  creditCards
) => {
  switch (active) {
    case "orders":
      if (orderButtonID === 1) {
        const data = userActiveOrders?.map((e) => (
          <OrderHistory key={e.id} order={e} />
        ));

        setRenderData(<div className="order-bars-container">{data}</div>);
      } else {
        (async function fetchOrderHistory() {
          const orders = await FETCH_AllOrdersByUserId(userID, token);

          const data = orders?.map((e, index) => (
            <OrderHistory key={`${index}-${orders.length}`} order={e} />
          ));

          setRenderData(<div className="order-bars-container">{data}</div>);
        })();
      }

      break;

    case "experiences":
      (async function fetchReviews() {
        const reviews = await FETCH_MyReviews(token);

        const data = reviews.map((e) => (
          <Review key={e.id} review={e} showAuthor={true} />
        ));

        setRenderData(<div className="card-row-container">{data}</div>);
      })();
      break;

    case "articles":
      (async function fetchArticles() {
        const articles = await FETCH_MyArticles(token);

        const data = articles.map((e) => (
          <ArticleWithDescription article={e} showDescription={false} />
        ));

        setRenderData(<div className="card-row-container">{data}</div>);
      })();
      break;

    case "creditCards":
      const data = creditCards?.map((e) => <CreditCard key={e.id} card={e} />);
      setRenderData(<div className="credit-card-row-container">{data}</div>);

      break;
    default:
      setRenderData();
  }
};

export default getDashboardButtonData;
