import i18next from "i18next";
import i18n from "../../i18n/i18n";

const translation = (updateTranslations) => {
  if (i18n.isInitialized) {
    updateTranslations();
  }

  i18next.on("languageChanged", () => {
    updateTranslations();
  });

  i18next.on("loaded", () => {
    updateTranslations();
  });
};

export default translation;
