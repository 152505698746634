import React from "react";

// Icons
import VisaLogo from "../../assets/icons/visa.svg";
import MasterCardLogo from "../../assets/icons/master.svg";
import MaestroLogo from "../../assets/icons/maestro.svg";
import Amex from "../../assets/icons/amexLogo.png";
import DinaCardLogo from "../../assets/icons/dinacard.svg";
import AllSecureLogo from "../../assets/icons/allsecure.svg";
import BancaIntesaLogo from "../../assets/images/footer/banca_intesa_logo.png";
import VisaSecureLogo from "../../assets/icons/visa_secure.svg";
import MasterCardIDCheckLogo from "../../assets/icons/mc_idcheck.png";

const FooterLogos = () => {
  return (
    <div className="footer-logos">
      <img alt="maestro" src={MaestroLogo} className="footer-logos_icon" />
      <img alt="master-card" src={MasterCardLogo} className="footer-logos_icon" />
      <img alt="dina-card" src={DinaCardLogo} className="footer-logos_icon" />
      <img alt="visa" src={VisaLogo} className="footer-logos_icon" />
      <img alt="amex" src={Amex} className="footer-logos_icon amex-logo" />

      <a
        rel="noreferrer"
        target="_blank"
        href="https://www.allsecure.rs/"
        className="footer-logos_link"
      >
        <img
          alt="all-secure"
          src={AllSecureLogo}
          className="footer-logos_icon"
        />
      </a>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://www.bancaintesa.rs/"
        className="footer-logos_link"
      >
        <img
          alt="banca-intesa"
          src={BancaIntesaLogo}
          className="footer-logos_icon_intesa"
        />
      </a>

      <a
        href="http://www.mastercard.com/rs/consumer/credit-cards.html"
        rel="noreferrer"
        target="_blank"
        className="footer-logos_link"
      >
        <img
          alt="master-card-id-check"
          src={MasterCardIDCheckLogo}
          className="footer-logos_icon_secure"
        />
      </a>

      <a
        href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
        rel="noreferrer"
        target="_blank"
        className="footer-logos_link"
      >
        <img
          alt="visa-secure"
          src={VisaSecureLogo}
          className="footer-logos_icon_secure"
        />
      </a>
    </div>
  );
};

export default FooterLogos;