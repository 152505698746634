import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";

// Components
import ShippingInfoContainer from "../../components/orderFlow/shippingInfoContainer/ShippingInfoContainer";
import OrderingOptions from "../../components/orderFlow/orderingOptions/OrderingOptions";
import OrderDetailsContainer from "../../components/orderFlow/orderDetailsContainer/OrderDetailsContainer";
import ModalButton from "../../components/buttons/modalButton/ModalButton";

// Hooks
import { useIsProductToGo } from "../../hooks/useIsProductToGo";
import { useIsProductDeliverable } from "../../hooks/useIsProductDeliverable";
import { useGetDeliveryPrice } from "../../hooks/useGetDeliveryPrice";
import { useIsProductAvailable } from "../../hooks/useIsProductAvailable";

// Constants
import {
  getArrivalTimeString,
  newDateISOString,
} from "../../util/functions/DateHelper";
import { handleModals } from "../../util/functions/ModalHelper";
import { getCurrentUserCart } from "../../util/functions/OrderFlowHelper";
import { handleContinueToConfirmation } from "../../util/functions/OrderCheckoutFunctions";
import {
  EMAIL_REGEX,
  handleValidField,
} from "../../util/constants/Validations";

// Images
import timeIcon from "../../assets/images/singleProductPage/timeIcon.png";
import locationIcon from "../../assets/images/singleProductPage/locationIcon.png";

const OrderCheckoutPage = () => {
  const user = useSelector((state) => state.userReducer.user);
  const cart = useSelector((state) => state.localStorageReducer.cart);

  const [shopLocation, setShopLocation] = useState();
  const [showModal, setShowModal] = useState();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showEmptyFieldError, setEmptyFieldError] = useState(false);
  const [email, setEmail] = useState("");
  const [order, setOrder] = useState({});
  const [addressData, setAddressData] = useState({
    streetAddress: user?.streetAddress,
    city: user?.city,
    postalCode: user?.postalCode,
    phoneNumber: user?.phoneNumber,
  });

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const reactAlert = useAlert();

  const { isProductToGo } = useIsProductToGo();
  const { isProductDeliverable } = useIsProductDeliverable();

  const regex = EMAIL_REGEX;
  const userCart = getCurrentUserCart(user, cart);

  useEffect(() => {
    setOrder({
      ...order,
      OrderLine: userCart?.cart,
      PriceTotal: userCart?.cart.reduce(
        (acc, val) => acc + val.amount * val.price,
        0
      ),
      CustomerName: email || user?.email,
      AddressAndBuildingNumber:
        addressData?.streetAddress || user?.streetAddress,
      City: addressData?.city || user?.city,
      PostalCode: addressData?.postalCode || addressData?.postalCode,
      PhoneNumber: addressData?.phoneNumber || user?.phoneNumber,
      Date: newDateISOString,
      UserId: user?.id || null
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, cart, addressData, email]);

  const { deliveryPrice } = useGetDeliveryPrice(
    order?.OrderLine,
    isProductDeliverable
  );

  const isProductAvailable = useIsProductAvailable(
    order?.OrderLine,
    shopLocation?.id
  );

  const handleShowModal = (option) => {
    setShowModal(option);
  };

  const handleShopLocationChange = (shopLocation) => {
    setShopLocation(shopLocation);
  };

  const handleAddressChange = (name, value) => {
    setAddressData({ ...addressData, [name]: value });
  };

  const handleOrderChange = (name, value) => {
    setOrder({ ...order, [name]: value });
  };

  const handleContinue = async () => {
    handleContinueToConfirmation(
      user,
      email,
      isValidEmail,
      location,
      isProductDeliverable,
      isProductAvailable,
      reactAlert,
      order,
      history
    );
  };

  return (
    <div className="order-checkout-main-container">
      <p style={{ textAlign: "center" }} className="register-title">
        {t("ORDER_CHECKOUT")}
      </p>
      <p style={{ textAlign: "center" }} className="register-subtitle">
        {t("CONFIRM_ORDER")}
      </p>

      <div className="order-checkout-container">
        <div className="about-you-container">
          <div className="bio-container">
            <p className="card-info-title">{t("YOUR_BIO")}</p>

            <input
              className="order-checkout-container_large-input"
              type="text"
              placeholder={t("EMAIL")}
              value={user?.email || email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={(e) =>
                handleValidField(
                  e.target.value,
                  setIsValidEmail,
                  setShowEmailError,
                  setEmptyFieldError,
                  regex
                )
              }
            />
          </div>

          {showEmailError && (
            <p className="input-field_email-error">{t("WRONG_EMAIL_FORMAT")}</p>
          )}

          {showEmptyFieldError && !user?.email && (
            <p className="input-field_email-error">
              {t("THIS_FIELD_CAN_NOT_BE_EMPTY")}
            </p>
          )}

          {isProductDeliverable && (
            <ShippingInfoContainer
              addressData={addressData}
              handleChange={handleAddressChange}
            />
          )}

          <div className="ordering-options-container">
            <OrderingOptions
              handleChange={handleOrderChange}
              clickedOrderOptionID={order?.OrderOption}
              isDeliverable={isProductDeliverable}
              isProductToGo={isProductToGo}
            />

            <div className="modal-buttons-container">
              <ModalButton
                icon={locationIcon}
                text={
                  shopLocation
                    ? shopLocation.name + ", " + shopLocation.address
                    : t("CHOOSE_YOUR_CNF")
                }
                onClick={() => handleShowModal("shopLocation")}
                showModal={showModal}
                locationModal={true}
              />

              <ModalButton
                icon={timeIcon}
                text={
                  order?.ArrivalTime
                    ? getArrivalTimeString(order?.ArrivalTime)
                    : t("CHOOSE_YOUR_TIME")
                }
                onClick={() => handleShowModal("timePicker")}
                showModal={showModal}
              />
            </div>

            {handleModals(
              showModal,
              handleShowModal,
              handleOrderChange,
              handleShopLocationChange
            )}
          </div>
        </div>

        <OrderDetailsContainer
          order={order}
          totalPrice={order?.PriceTotal + deliveryPrice}
          handleContinue={handleContinue}
          orderCheckoutPage={true}
          deliveryPrice={deliveryPrice}
        />
      </div>
    </div>
  );
};

export default OrderCheckoutPage;
