import API from "../config/api/api";

const ENDPOINTS = {
  FETCH_REVIEWS: "/review/getall",
  FETCH_SINGLE: "/review/getbyid",
  FETCH_MY_REVIEWS: "/review/getallbyuserid",
};

const getLanguage = () => {
  if (localStorage.getItem("language") === "sr") return 0;
  return 1;
};

export const FETCH_Reviews = async (id) => {
  try {
    const response = await API.get(`${ENDPOINTS.FETCH_REVIEWS}`, {
      headers: {
        Language: getLanguage(),
      },
    });
    return response.data;
  } catch (err) {
    console.log("Error @ reviewService.js: FETCH_Reviews ->", err);
  }
};

export const FETCH_SingleReview = async (id) => {
  try {
    const response = await API.get(`${ENDPOINTS.FETCH_SINGLE}/${id}`, {
      headers: {
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ reviewService.js: FETCH_Reviews ->", err);
  }
};

export const FETCH_MyReviews = async (token) => {
  try {
    const response = await API.get(`${ENDPOINTS.FETCH_MY_REVIEWS}`, {
      headers: {
        Authorization: "Bearer ".concat(token),
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ reviewService.js: FETCH_MyReviews ->", err);
  }
};
