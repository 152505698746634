import React, { useEffect } from "react";

const ModalButton = (props) => {
  const { onClick, icon, text, locationModal, showModal } = props;

  const iconClassName = locationModal ? "icon-location" : "icon-time";

  useEffect(() => {
    let style = document.head.appendChild(document.createElement("style"));
    if (locationModal && showModal) {
      style.innerHTML =
        "#modal-button-container::before {border-bottom: 2px solid #e23036}";
    } else {
      style.innerHTML =
        "#modal-button-container::before {border-bottom: 2px solid #a2a2a2}";
    }
  }, [locationModal, showModal]);

  return (
    <button onClick={onClick} id="modal-button-container">
      <img className={iconClassName} src={icon} alt="location-icon" />
      <span>{text}</span>
    </button>
  );
};

export default ModalButton;
