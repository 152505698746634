import API from "../config/api/api";

const ENDPOINTS = {
  FETCH_USER_DATA_FROM_TOKEN: "/user/getuserdatafromtoken",
  PUT_USER_PROFILE_IMAGE: "/user/changeprofilephoto",
  PUT_USER_COVER_IMAGE: "/user/changecoverphoto",
  VERIFY_USER: "/user/verify",
  GET_CREDIT_CARDS: "/user/getcreditcards",
  DELETE_CREDIT_CARD: "/payment/DeregisterCard",
};

const getLanguage = () => {
  if (localStorage.getItem("language") === "sr") return 0;
  return 1;
};

export const FETCH_UserDataFromToken = async (token) => {
  try {
    const response = await API.get(ENDPOINTS.FETCH_USER_DATA_FROM_TOKEN, {
      headers: {
        Authorization: "Bearer ".concat(token),
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ userService.js: FETCH_UserDataFromToken");
  }
};

export const PUT_UserProfileImage = async (formData, token) => {
  try {
    const response = await API.put(ENDPOINTS.PUT_USER_PROFILE_IMAGE, formData, {
      headers: {
        Authorization: "Bearer ".concat(token),
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ userService.js: PUT_UserProfileImage");
  }
};

export const PUT_UserCoverImage = async (formData, token) => {
  try {
    const response = await API.put(ENDPOINTS.PUT_USER_COVER_IMAGE, formData, {
      headers: {
        Authorization: "Bearer ".concat(token),
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ userService.js: PUT_UserCoverImage");
  }
};

export const VERIFY_User = async (id) => {
  try {
    const response = await API.get(`${ENDPOINTS.VERIFY_USER}/${id}`);

    return response.data;
  } catch (err) {
    console.log("Error @ userService.js: VERIFY_User");
  }
};

export const GET_CreditCards = async (token) => {
  try {
    const creditCards = await API.get(ENDPOINTS.GET_CREDIT_CARDS, {
      headers: {
        Authorization: "Bearer ".concat(token),
      },
    });

    return creditCards.data;
  } catch (err) {
    console.log("Error @ userService.js: GET_CreditCards");
  }
};

export const DELETE_CreditCard = async (card, token) => {
  try {
    const response = await API.delete(
      `${ENDPOINTS.DELETE_CREDIT_CARD}/${card.id}`,
      {
        headers: {
          Authorization: "Bearer ".concat(token),
        },
      }
    );

    return response;
  } catch (err) {
    console.log("Error @ userService.js: DELETE_CreditCard");
  }
};
