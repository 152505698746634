import React from "react";
import { useTranslation } from "react-i18next";

// Components
import OrderInfoText from "../orderInfoText/OrderInfoText";

const CheckoutContainer = (props) => {
  const { handleContinue, handleSignIn, handleSignUp, products, totalPrice } =
    props;

  const { t } = useTranslation();

  return (
    <div className="check-out-container">
      <div className="sign-in-container">
        <p className="guest-title">{t("SIGN_UP_OR_SIGN_IN")}</p>
        <p className="guest-subtitle">
          {t("SIGN_IN_TO_SPEED_UP_THE_CHECKOUT")}
        </p>

        <button className="button-order-checkout" onClick={handleSignIn}>
          {t("SIGN_IN")}
        </button>
        <button className="button-order-checkout-white" onClick={handleSignUp}>
          {t("SIGN_UP")}
        </button>
      </div>

      <div className="order-summary-container">
        <p className="guest-title">{t("ORDER_SUMMARY")}</p>

        <div className="order-details-block">
          {products?.map((product) => (
            <OrderInfoText
              guestOrderCheckout={true}
              key={product.productId}
              product={product}
            />
          ))}
          <OrderInfoText guestOrderCheckout={true} totalPrice={totalPrice} />
        </div>

        <div style={{ marginTop: 30 }}>
          <button className="button-order-checkout" onClick={handleContinue}>
            {t("CONTINUE")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutContainer;
