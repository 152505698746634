import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// Store
import { LOGOUT_USER_SAGA } from "../../../store/sagas/authSaga";
import { FETCH_CONNECTIONS_SAGA } from "../../../store/sagas/webSocketSaga";

const NavbarButton = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { title, forRegistration, forLogout } = props;

  const connections = useSelector(
    (state) => state.webSocketReducer.connections
  );
  const user = useSelector((state) => state.userReducer.user);

  const [userConnections, setUserConnections] = useState([]);

  const navbarButtonClassName =
    forRegistration || forLogout
      ? "navbar-registration-button"
      : "navbar-button";

  useEffect(() => {
    dispatch({ type: FETCH_CONNECTIONS_SAGA });
  }, [dispatch]);

  useEffect(() => {
    const userIndex = connections?.findIndex(
      (connection) => connection.userID === user?.id
    );

    if (userIndex >= 0) {
      setUserConnections(connections[userIndex].connections);
    }
  }, [connections, user?.id]);

  const handleClick = () => {
    switch (title) {
      case t("SHOP"): {
        history.push("/");
        break;
      }
      case t("KNOWLEDGE"): {
        history.push("/knowledge");
        break;
      }
      case t("COMMUNITY"): {
        history.push("/community");
        break;
      }
      case t("PROFILE"): {
        history.push("/profile");
        break;
      }
      case t("LOGOUT"):
        if (userConnections?.length > 0) {
          userConnections.forEach((connection) => connection.stop());
        }
        localStorage.removeItem("cardData");
        localStorage.removeItem("transactionToken");
        localStorage.removeItem("lastFourDigits");
        localStorage.removeItem("cardType");
        dispatch({ type: LOGOUT_USER_SAGA });

        window.location = "/";
        break;
      case t("REGISTER"):
        history.push("/register");
        break;
      default: {
        history.push(`/${title.toLowerCase()}`);
        break;
      }
    }
  };

  return (
    <button className={navbarButtonClassName} onClick={handleClick}>
      {title}
    </button>
  );
};

export default NavbarButton;
