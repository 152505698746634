import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Store
import { GET_USERS_CREDIT_CARDS_SAGA } from "../../../store/sagas/userSaga";

// Images
import ovalImage from "../../../assets/images/orderCheckout/Oval.png";

const CardPayment = (props) => {
  const creditCards = useSelector((state) => state.userReducer.creditCards);
  const token = useSelector((state) => state.authReducer.token);

  const { handleAddCard, card, setCreditCard } = props;

  const creditCardData = JSON.parse(localStorage.getItem("cardData"));

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const cardType = card?.cardHolder
    ? JSON.parse(localStorage.getItem("cardType"))
    : card?.cardName;
  const lastFourDigits = card?.cardHolder
    ? JSON.parse(localStorage.getItem("lastFourDigits"))
    : card?.lastDigits;
  const cardMonth = card?.month ?? card?.expiryMonth;
  const cardYear = card?.year ?? card?.expiryYear;

  const cardNumberDisplay = cardType + " - **** **** **** " + lastFourDigits;
  const cardDateDisplay = cardMonth + " / " + cardYear?.slice(-2);

  const handleSelect = (e) => {
    const newCard = creditCards.find(
      (card) => card.id === parseInt(e.target.value)
    );

    setCreditCard(newCard);
  };

  useEffect(() => {
    if (creditCards && !creditCardData) {
      setCreditCard(creditCards[0]);
    } else {
      setCreditCard(creditCardData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCards, setCreditCard]);

  useEffect(() => {
    token && dispatch({ type: GET_USERS_CREDIT_CARDS_SAGA, token: token });
  }, [token, dispatch]);

  return (
    <div className="card-payment-container">
      <div className="add-card-container">
        <div onClick={handleAddCard} className="add-card-container-part">
          <img className="oval-image" src={ovalImage} alt="Add" />
          <p className="add-card-title">{t("ADD_CARD")}</p>
        </div>
        {token && (
          <div>
            <label className="add-card-container_text" htmlFor="credit-cards">
              {t("CREDIT_CARDS")}
            </label>
            <select id="credit-cards" onChange={(e) => handleSelect(e)}>
              <option value="" hidden>
                {t("SELECT_BANK_CARD")}
              </option>
              {creditCards?.map((card) => (
                <option
                  className="add-card-container_text"
                  key={card.id}
                  value={card.id}
                >
                  {card.cardName.toUpperCase()} - {card.lastDigits}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <input
        className="card-payment-container_input-payment-card"
        disabled
        placeholder={t("CARD_NUMBER")}
        value={cardType ? cardNumberDisplay : ""}
      />

      <input
        className="card-payment-container_input-payment-card"
        disabled
        placeholder={t("EXPIRATION_DATE")}
        value={cardMonth ? cardDateDisplay : ""}
      />
    </div>
  );
};

export default CardPayment;
