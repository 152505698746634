import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Constants
import { addZeroes } from "../../../util/functions/AddZeroes";

const ProductsListButton = (props) => {
  const { product } = props;

  const history = useHistory();

  const { t } = useTranslation();

  const goToSingleProductPage = () => {
    history.push({
      pathname: "/single-product-page",
      state: {
        product: product,
      },
    });
  };

  return (
    <div onClick={goToSingleProductPage} className="product">
      <img
        src={product?.imageUrl}
        alt={product.name}
        className="product_image"
      ></img>

      <p className="product_button-text">{product.name}</p>
      <p className="product_button-price">
        {addZeroes(product.sizes[0].price)} {t("DIN")}
      </p>
    </div>
  );
};
export default ProductsListButton;
