import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

// Store
import { UPDATE_CART_SAGA } from "../../../store/sagas/localStorageSaga";

// Constants
import {
  addToEmptyCart,
  addToExistingUserCart,
  formatTemporaryCart,
  getCurrentUserIndex,
} from "../../../util/functions/OrderFlowHelper";

// Images
import MinusIcon from "../../../assets/images/singleProductPage/minus.png";
import PlusIcon from "../../../assets/images/singleProductPage/plus.png";

const QuantityContainer = (props) => {
  const currentUser = useSelector((state) => state.userReducer.user);
  const guestUser = useSelector((state) => state.userReducer.guest);
  const token = useSelector((state) => state.authReducer.token);
  const cart = useSelector((state) => state.localStorageReducer.cart);

  const [user, setUser] = useState();

  const { product, productSize, amount, handleSetAmount } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const alert = useAlert();

  const setCounterLower = () => {
    if (amount > 1) {
      handleSetAmount(amount - 1);
    }
  };

  const setCounterHigher = () => {
    handleSetAmount(amount + 1);
  };

  const addToCart = (cartObject) => {
    alert.success(t("ADD_ITEM_TO_CART"));
    dispatch({ type: UPDATE_CART_SAGA, cart: cartObject });
  };

  const handleAddToCart = () => {
    let newCart = {};
    let temporaryCartObject = [...cart];
    let temporaryCart = formatTemporaryCart(amount, productSize, product);

    if (temporaryCartObject?.length === 0) {
      addToEmptyCart(
        user,
        newCart,
        temporaryCartObject,
        temporaryCart,
        addToCart
      );
    } else {
      let currentUserIndex = getCurrentUserIndex(user, temporaryCartObject);

      if (currentUserIndex >= 0) {
        addToExistingUserCart(
          currentUserIndex,
          temporaryCartObject,
          temporaryCart,
          addToCart
        );
      } else {
        addToEmptyCart(
          user,
          newCart,
          temporaryCartObject,
          temporaryCart,
          addToCart
        );
      }
    }
  };

  useEffect(() => {
    token ? setUser(currentUser) : setUser(guestUser);
  }, [currentUser, guestUser, token]);

  return (
    <div className="buy-quantity-and-add-to-cart-container">
      <div className="quantity-container">
        <button
          className="single-product-quantity-button"
          onClick={setCounterLower}
        >
          <img alt="minus" src={MinusIcon}></img>
        </button>
        <p className="single-product-counter">{amount}</p>
        <button
          className="single-product-quantity-button"
          onClick={setCounterHigher}
        >
          <img alt="plus" src={PlusIcon}></img>
        </button>
      </div>

      <div className="add-to-cart-and-buy-container">
        <button onClick={handleAddToCart} className="add-buy-product-button">
          {t("ADD_TO_CART")}
        </button>
      </div>
    </div>
  );
};

export default QuantityContainer;
