import React from "react";

const InputField = (props) => {
  const { type, placeholder, size, onChange, value, onBlur } = props;

  const getClassName = () => {
    switch (size) {
      case "small":
        return "input-field-small";
      case "medium":
        return "input-field-medium";
      case "large":
        return "input-field-large";
      default:
        return "input-field-large";
    }
  };

  return (
    <React.Fragment>
      <input
        className={getClassName()}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
      />
    </React.Fragment>
  );
};

export default InputField;
