import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { RatingView } from "react-simple-star-rating";

// Services
import { FETCH_SingleReview } from "../../../services/reviewService";

// Components
import TitleWithDescription from "../../../components/title/TitleWithDescription";
import UserDetailsSmall from "../../../components/userDetailsSmall/UserDetailsSmall";
import OrderDetailsContainer from "../../../components/orderDetailsContainer/OrderDetailsContainer";
import LoadingIndicator from "../../../components/loadingIndicator/LoadingIndicator";

// Constants
import { getReviewTitleWithValue } from "../../../util/functions/GetReviewTitle";
import {
  getTopContainerImageStyle,
  renderImages,
} from "../../../util/functions/SingleReviewHelper";

const ShowSingleReview = () => {
  const [review, setReview] = useState();
  const [title, setTitle] = useState("");

  const { id } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    if (review) {
      const title = getReviewTitleWithValue(review.description, review.value);

      setTitle(title);
    }
  }, [review]);

  useEffect(() => {
    (async function fetchReview() {
      const review = await FETCH_SingleReview(id);

      setReview(review);
    })();
  }, [id]);

  return (
    <main className="community-page-main-container">
      {!review && <LoadingIndicator />}
      {review && (
        <>
          <div className="top-container">
            <div className="text-container">
              <TitleWithDescription
                singleReviewPage={true}
                title={t("REVIEW_DETAILS")}
              />

              <UserDetailsSmall
                levelText={review.userLevelText}
                imageUrl={review.userProfileImageUrl}
                fullName={review.userFullName}
              />

              <OrderDetailsContainer review={review} />
            </div>

            <div
              className="image-container"
              style={getTopContainerImageStyle(review)}
            />
          </div>

          <hr />

          <RatingView
            className="rating-container"
            ratingValue={review.value}
            transition={false}
            size={42}
          />

          <div className="review-details-container">
            <p className="review-title">{title}</p>
            <p className="review-description-font">{review.description}</p>
          </div>

          <div className="photos-section-container">
            <p className="review-title">{t("PHOTOS")}</p>
            <div className="images-container">{renderImages(review)}</div>
          </div>
        </>
      )}
    </main>
  );
};

export default ShowSingleReview;
