import { all } from "redux-saga/effects";

// Sagas
import {
  watchRegisterUser,
  watchLoginUser,
  watchLogoutUser,
} from "../../store/sagas/authSaga";
import {
  watchFetchActiveOrders,
  watchFetchCart,
  watchSetToken,
  watchUpdateActiveOrders,
  watchUpdateCart,
} from "../../store/sagas/localStorageSaga";
import {
  watchDeleteCreditCard,
  watchGetUserByToken,
  watchGetUsersCreditCards,
} from "../../store/sagas/userSaga";
import {
  watchFetchConnections,
  watchUpdateConnections,
} from "../../store/sagas/webSocketSaga";

export default function* rootSaga() {
  yield all([
    watchRegisterUser(),
    watchLoginUser(),
    watchLogoutUser(),
    watchGetUserByToken(),
    watchGetUsersCreditCards(),
    watchDeleteCreditCard(),
    watchSetToken(),
    watchFetchCart(),
    watchUpdateCart(),
    watchFetchActiveOrders(),
    watchUpdateActiveOrders(),
    watchFetchConnections(),
    watchUpdateConnections(),
  ]);
}
