import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

// Services
import {
  FETCH_Subcategories_and_products,
  FETCH_CategoryById,
} from "../../services/productsService";
import {
  SEARCH_products_by_subcategory,
  SEARCH_products_by_subcategory_and_name,
} from "../../services/searchService";

// Components
import ProductsListButton from "../../components/buttons/productsListButton/ProductsListButton";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";

// Constants
import { getSearchIcon } from "../../util/constants/Icons";

const ProductsListPage = () => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [subcategoryId, setSubcategoryId] = useState();
  const [subcategories, setSubcategories] = useState([]);
  const [searchText, setSearchText] = useState("");

  const { id } = useParams();

  const { t } = useTranslation();

  const displayLoadingIndicator = products && products.length === 0;

  const handleSearchChange = (e) => {
    setSearchText(e);
  };

  const handleClickSubcategoryButton = (subcategoryID) => {
    setSubcategoryId(subcategoryID);
    searchSubcategoryProducts(subcategoryID);
  };

  async function searchSubcategoryProducts(id) {
    const response = await SEARCH_products_by_subcategory(id);
    setSearchedProducts(response);
    return searchedProducts;
  }

  useEffect(() => {
    async function fetchSubcategories() {
      const subcategories = await FETCH_Subcategories_and_products(id);
      setProducts(subcategories[0].products);
      setSubcategoryId(subcategories[0].id);
      setSubcategories(subcategories);
    }

    fetchSubcategories();
  }, [id]);

  useEffect(() => {
    async function searchSubcategoryProductsAll() {
      if (subcategoryId > 0) {
        const response = await SEARCH_products_by_subcategory_and_name(
          subcategoryId,
          searchText
        );
        setSearchedProducts(response);
      }
    }

    searchSubcategoryProductsAll();
  }, [subcategoryId, searchText]);

  useEffect(() => {
    if (searchedProducts) setProducts(searchedProducts);
  }, [searchedProducts]);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchCategory() {
      const category = await FETCH_CategoryById(id);
      setCategory(category);
    }

    fetchCategory();
  }, [id]);

  return (
    <div className="product-list-page-container">
      {displayLoadingIndicator && <LoadingIndicator />}
      {!displayLoadingIndicator && (
        <React.Fragment>
          <div className="product-list-page-title-container">
            <p className="products_subtitle">{category.name}</p>
            <img
              alt="category-icon"
              className="category-image"
              src={category.iconUrl}
            />
          </div>

          <div className="product-list-container">
            <div className="products">
              {products.map((product) => (
                <ProductsListButton key={product.id} product={product} />
              ))}
            </div>

            <div className="search-filter-products-container">
              <input
                className="search-products-input"
                placeholder={t("SEARCH")}
                type="text"
                value={searchText}
                onChange={(e) => handleSearchChange(e.target.value)}
              />

              <span style={{ marginTop: 18 }} className="search-icon">
                {getSearchIcon()}
              </span>

              <p className="subcategory-title-text">{t("SUBCATEGORY")}</p>
              {subcategories.map((subcat) => (
                <button
                  id={
                    subcat.id === subcategoryId
                      ? "product-subcategory-button_clicked"
                      : "product-subcategory-button"
                  }
                  key={subcat.id}
                  onClick={() => handleClickSubcategoryButton(subcat.id)}
                >
                  <p
                    id={
                      subcat.id === subcategoryId
                        ? "product-subcategory-button_text_clicked"
                        : "product-subcategory-button_text"
                    }
                  >
                    {subcat.name}
                  </p>
                </button>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ProductsListPage;
