import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Components
import PrimaryButton from "../../components/buttons/primaryButton/PrimaryButton";

// Icons
import OrderError from "../../assets/icons/order_error.svg";

const ErrorPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push("/");
  };

  return (
    <div className="error-page">
      <img src={OrderError} alt="order-error"></img>
      <p className="error-page_subtitle">{t("ORDER_ERROR")}</p>
      <PrimaryButton title="OK" size="small" onClick={handleClick} />
    </div>
  );
};

export default ErrorPage;
