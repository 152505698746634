export const exportForm = (publicIntegrationKey, token) => {
  return ` 
<div id="div_payment_form">          
    <form id="payment_form" method="POST" onsubmit="interceptSubmit(); return false;">
        <div>
            <input type="hidden" name="transaction_token" id="transaction_token" />
            <input type="hidden" name="card_type" id="card_type" />
            <input type="hidden" name="last_four_digits" id="last_four_digits" />
            <div style="display: flex; flex-direction: column;">
                <label style="color: #686767; font-size: 14px;" for="card_holder">Card holder</label>
                <input type="text" id="card_holder" name="card_holder" style="height: 65px; border: 0.5px solid #979797; border-radius: 6.3px; color: #1A1919;
                margin-top: 10px; margin-bottom: 10px; padding-left: 16px;" />
            </div>
            <div style="display: flex; flex-direction: column;">
                <label style="color: #686767; font-size: 14px;" for="number_div">Card number</label>
                <div id="number_div" style="height: 85px;"></div>
            </div>
            <div style="display: flex; flex-direction: column;">
                <label style="color: #686767; font-size: 14px;" for="cvv_div">CVV</label>
                <div id="cvv_div" style="height: 85px;"></div>
            </div>

            <div style="display: flex; flex-direction: column;">
                <label style="color: #686767; font-size: 14px;" for="exp_month">Expiration Month</label>
                <input type="text" id="exp_month" name="exp_month" style="height: 65px; border: 0.5px solid #979797; border-radius: 6.3px; color: #1A1919;
                margin-top: 10px; margin-bottom: 10px; padding-left: 16px;" />
            </div>
            <div style="display: flex; flex-direction: column;">
                <label style="color: #686767; font-size: 14px;" for="exp_year">Expiration Year</label>
                <input type="text" id="exp_year" name="exp_year" style="height: 65px; border: 0.5px solid #979797; border-radius: 6.3px; color: #1A1919;
                margin-top: 10px; margin-bottom: 10px; padding-left: 16px;" />
            </div>
            <div style="display: ${
              token ? "flex" : "none"
            }; align-items: center;">
                <input type="checkbox" id="save_card" name="save_card"/>
                <label style="color: #686767; font-size: 14px; padding-left: 8px;" for="save_card"> Do you want to save your card?</label>
            </div>
        </div>
        <div>
            <input type="submit" value="Add a card" id="submitButton" style="height: 65px; width: 100%; color: white; background-color: #E23036;
            border: none; overflow: hidden; border-radius: 8px; font-size: 12px; text-transform: uppercase; margin-top: 10px;" />
        </div>
    </form>

    <script type="text/javascript">        
        var payment = new PaymentJs();
        var style = {
            'height': '65px',                
            'border-radius': '6.3px',                                 
            'color': '#1A1919',
            'margin-top': '10px',
            'margin-bottom': '10px',
            'border': '0.5px solid #979797', 
            'width': '100%',
            'padding-left': '16px'
        };

        payment.init('${publicIntegrationKey}', 'number_div', 'cvv_div', function(payment) {
            payment.setNumberStyle(style);
            payment.setCvvStyle(style);
        });    
        
        function interceptSubmit() {
            var data = {
                card_holder: $('#card_holder').val(),
                month: $('#exp_month').val(),
                year: $('#exp_year').val(),
                save_card: $('#save_card').is(':checked'),
            };                

            payment.tokenize(
                data, 
                function(token, cardData) {
                    var transactionToken = $('#transaction_token').val(token);
                    var lastFourDigits = $('#last_four_digits').val(cardData['last_four_digits']);
                    var cardType = $('#card_type').val(cardData['card_type']);                    

                    window.localStorage.setItem("cardData", JSON.stringify(data));
                    window.localStorage.setItem("transactionToken", JSON.stringify(transactionToken[0].value));
                    window.localStorage.setItem("lastFourDigits", JSON.stringify(lastFourDigits[0].value));
                    window.localStorage.setItem("cardType", JSON.stringify(cardType[0].value));
                    window.location = '/confirm-order';
                }, 
                function(errors) {
                    alert('Errors occurred');         
                }
            );
        }
    </script>
</div>`;
};
