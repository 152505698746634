import API from "../config/api/api";

const ENDPOINTS = {
  SEARCH_ARTICLES_BY_TITLE: "/article/searchbytitle",
  SEARCH_PRODUCTS_BY_SUBCATEGORY: "/product/searchproductsinsubcategorybyname",
  SEARCH_REVIEWS_BY_TITLE: "./review/searchByTitle",
};

const getLanguage = () => {
  if (localStorage.getItem("language") === "sr") return 0;
  return 1;
};

export const SEARCH_ArticlesByTitle = async (searchQuery) => {
  try {
    const response = await API.get(
      `${ENDPOINTS.SEARCH_ARTICLES_BY_TITLE}?Search=${searchQuery}`,
      {
        headers: {
          Language: getLanguage(),
        },
      }
    );

    return response.data;
  } catch (err) {
    console.log("Error @ searchService.js: SEARCH_ArticlesByTitle ->", err);
  }
};

export const SEARCH_products_by_subcategory = async (subCategoryId) => {
  try {
    const response = await API.get(
      `${ENDPOINTS.SEARCH_PRODUCTS_BY_SUBCATEGORY}?subCategoryId=${subCategoryId}`,
      {
        headers: {
          Language: getLanguage(),
        },
      }
    );

    return response.data;
  } catch (err) {
    console.log("Error @ searchService.js: SEARCH_ArticlesByTitle ->", err);
  }
};

export const SEARCH_products_by_subcategory_and_name = async (
  subCategoryId,
  name
) => {
  try {
    const response = await API.get(
      `${ENDPOINTS.SEARCH_PRODUCTS_BY_SUBCATEGORY}?subCategoryId=${subCategoryId}&Search=${name}`,
      {
        headers: {
          Language: getLanguage(),
        },
      }
    );

    return response.data;
  } catch (err) {
    console.log("Error @ searchService.js: SEARCH_ArticlesByTitle ->", err);
  }
};

export const SEARCH_ReviewsByTitle = async (searchQuery) => {
  try {
    const response = await API.get(
      `${ENDPOINTS.SEARCH_REVIEWS_BY_TITLE}?Search=${searchQuery}`,
      {
        headers: {
          Language: getLanguage(),
        },
      }
    );

    return response.data;
  } catch (err) {
    console.log("Error @ searchService.js: SEARCH_ReviewsByTitle ->", err);
  }
};
