import axios from "axios";

// export const BASE_URL_ROUTE = "http://localhost:5000";
export const BASE_URL_ROUTE = "https://api.cafe-factory.net";
export const API_URL_ROUTE = `${BASE_URL_ROUTE}/api`

const API = axios.create({
  baseURL: API_URL_ROUTE,
  headers: {
    "Content-Type": "application/json",
  },
});

export default API;
