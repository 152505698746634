import i18next from "i18next";

// Store
import { UPDATE_CART_SAGA } from "../../store/sagas/localStorageSaga";

// Services
import { CREATE_order, INITIALIZE_Payment } from "../../services/orderService";

// Constants
import {
  getCurrentUserIndex,
  updateWebSocketConnections,
} from "./OrderFlowHelper";
import { connectionOn, establishConnection } from "./WebSocketHelper";

const transactionToken = JSON.parse(localStorage.getItem("transactionToken"));

export const TAKE_AWAY_SUBCATEGORY_ID = 6007;

export const validatePayment = (
  location,
  creditCard,
  checkButtonClicked,
  reactAlert,
) => {
  if (!location.state?.cash && !creditCard) {
    reactAlert.error(i18next.t("ENTER_CREDIT_CARD"));
    return false;
  }

  if (!checkButtonClicked) {
    reactAlert.error(i18next.t("TERMS_OF_PAYMENT_ALERT"));
    return false;
  }

  return true;
};

export const validateUserInformation = (
  user,
  email,
  isValidEmail,
  isDeliverable,
  isProductAvailable,
  reactAlert,
  order,
) => {
  if (!user.email && !email) {
    reactAlert.error(i18next.t("EMPTY_EMAIL"));
    return false;
  }

  if (!isValidEmail) {
    reactAlert.error(i18next.t("WRONG_EMAIL_FORMAT"));
    return false;
  }

  if (
    isDeliverable &&
    (!order.AddressAndBuildingNumber || !order.PhoneNumber)
  ) {
    reactAlert.error(i18next.t("FULFILL_ALL_ADDRESS_INFO"));
    return false;
  }

  if (!isProductAvailable) {
    reactAlert.error(i18next.t("ORDER_AVAILABILITY"));
    return false;
  }

  if (typeof order.OrderOption !== "number") {
    reactAlert.error(i18next.t("CHOOSE_ORDER_OPTION"));
    return false;
  }

  if (!order.ShopLocationId) {
    reactAlert.error(i18next.t("CHOOSE_C&F"));
    return false;
  }

  return true;
};


export const validateOrderInformation = (products, reactAlert) => {
  let isValidate;

  const deliveryProduct = products.some(
    (product) => product.isDeliverable === true
  );

  const coffeeToGoProduct = products.some(
    (product) => product.productSubcategoryId === TAKE_AWAY_SUBCATEGORY_ID
  );

  const otherProduct = products.some(
    (product) =>
      product.isDeliverable === false &&
      product.productSubcategoryId !== TAKE_AWAY_SUBCATEGORY_ID
  );

  if (deliveryProduct && coffeeToGoProduct && otherProduct) {
    isValidate = false;
    reactAlert.error(i18next.t("ORDER_DISABLED"));
  } else {
    const xor = deliveryProduct ^ coffeeToGoProduct ^ otherProduct;

    if (xor) {
      isValidate = true;
    } else {
      isValidate = false;
      reactAlert.error(i18next.t("ORDER_DISABLED"));
    }
  }

  return isValidate;
};

export const handlePay = async (
  user,
  creditCard,
  activeOrders,
  cart,
  token,
  updateActiveOrders,
  dispatch,
  connections,
  updateConnections,
  paymentOption,
  reactAlert,
  history,
  location,
  checkButtonClicked,
  additionalBilingData
) => {
  try {
    const isValidPayment = validatePayment(
      location,
      creditCard,
      checkButtonClicked,
      reactAlert,
      additionalBilingData
    );

    const isBillingDataValid =
      additionalBilingData?.firstName?.length &&
      additionalBilingData?.lastName?.length &&
      additionalBilingData?.billingAddress?.length &&
      additionalBilingData?.billingCity?.length && true;

    if(paymentOption !== 0 && !isBillingDataValid) {
      reactAlert.error(i18next.t("FULFILL_ALL_ADDRESS_INFO"));
      return;
    }

    const responseOrder = JSON.parse(localStorage.getItem("order"));

    if (isValidPayment || ( paymentOption !== 0 && isBillingDataValid)) {
      const paymentObject = {
        order: {
          ...responseOrder,
          ...additionalBilingData,
        },
        paymentOption: paymentOption,
        withRegister: creditCard?.save_card ?? false,
        referenceUuid: creditCard?.referenceUid ?? null,
        transactionToken: creditCard?.cardHolder ? transactionToken : null,
        isMobile: false,
      };

      localStorage.removeItem("cardData");
      localStorage.removeItem("transactionToken");
      localStorage.removeItem("lastFourDigits");
      localStorage.removeItem("cardType");

      const paymentResult = await INITIALIZE_Payment(paymentObject);

      let newActiveOrders = {};
      let temporaryActiveOrdersObject = [...activeOrders];

      let newConnections = {};
      let temporaryConnections = [...connections];

      const userIndex = getCurrentUserIndex(user, temporaryActiveOrdersObject);

      if (paymentOption !== 0 && !paymentResult?.response?.success) {
        localStorage.removeItem("order");
        history.push("/error");
      }

      if (paymentResult?.response?.returnType === "REDIRECT") {
        updateWebSocketConnections(
          temporaryActiveOrdersObject,
          newActiveOrders,
          "activeOrders",
          paymentResult,
          user?.id,
          updateActiveOrders,
          userIndex
        );

        localStorage.removeItem("order");
        window.location.replace(paymentResult?.response?.redirectUrl);
      }

      if (paymentResult?.response?.returnType === "ERROR") {
        localStorage.removeItem("order");
        history.push("/error");
      }

      if (paymentOption === 0 && (paymentResult === undefined || paymentResult === null) ){
        localStorage.removeItem("order");
        history.push("/error");
      }

      if (
        paymentOption === 0 ||
        paymentResult?.response?.returnType === "FINISHED"
      ) {
        const orderID = responseOrder?.id;
        const connection = establishConnection(orderID);
        updateWebSocketConnections(
          temporaryConnections,
          newConnections,
          "connections",
          connection,
          user?.id,
          updateConnections,
          userIndex
        );

        updateWebSocketConnections(
          temporaryActiveOrdersObject,
          newActiveOrders,
          "activeOrders",
          paymentResult,
          user?.id,
          updateActiveOrders,
          userIndex
        );

        connectionOn(
          connection,
          userIndex,
          updateActiveOrders,
          user,
          reactAlert
        );

        if (token) {
          localStorage.removeItem("order");
          history.push("/profile");
          window.scrollTo(0, document.body.scrollHeight / 2.9);
        } else {
          localStorage.removeItem("order");
          history.push("/");
        }
      }

      let copyCartObject = [...cart];
      let userIndexCart = getCurrentUserIndex(user, copyCartObject);

      if (userIndexCart !== -1) {
        copyCartObject[userIndexCart].cart = [];
        dispatch({ type: UPDATE_CART_SAGA, cart: copyCartObject });
      }
    }
    let copyCartObject = [...cart];
    let userIndexCart = getCurrentUserIndex(user, copyCartObject);

    if (userIndexCart !== -1) {
      copyCartObject[userIndexCart].cart = [];
      dispatch({ type: UPDATE_CART_SAGA, cart: copyCartObject });
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleContinueToConfirmation = async (
  user,
  email,
  isValidEmail,
  location,
  isProductDeliverable,
  isProductAvailable,
  reactAlert,
  order,
  history
) => {
  const isInformationValid = validateUserInformation(
    user,
    email,
    isValidEmail,
    isProductDeliverable,
    isProductAvailable,
    reactAlert,
    order
  );

  const isOrderValid = validateOrderInformation(order.OrderLine, reactAlert);

  if (isInformationValid && isOrderValid ) {
    try {
      const responseOrder = await CREATE_order(order);
      localStorage.setItem("order", JSON.stringify(responseOrder));

      history.push({
        pathname: "/confirm-order",
        state: {
          cash: location?.state?.cash,
          order: responseOrder,
        },
      });
    } catch (err) {
      console.log(err);
    }
  }
};
