import React from "react";

// Images
import starIcon from "../../../assets/images/landingPage/StarCopy.png";

const StarIcon = () => {
  return (
    <div className="star">
      <img alt="star" src={starIcon} className="star-icon"></img>
      <img alt="star" src={starIcon} className="star-icon"></img>
      <img alt="star" src={starIcon} className="star-icon"></img>
      <img alt="star" src={starIcon} className="star-icon"></img>
      <img alt="star" src={starIcon} className="star-icon"></img>
    </div>
  );
};

export default StarIcon;
