import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

// Components
import ReadMoreButton from "../../buttons/readMoreButton/ReadMoreButton";

const ArticleWithDescription = (props) => {
  const { article, showDescription } = props;

  const { t } = useTranslation();

  const history = useHistory();

  const style = {
    backgroundColor: "white",
    backgroundImage: article.imageUrl ? `url(${article.imageUrl})` : "none",
    backgroundSize: "cover",
  };

  return (
    <div className="article-with-desc-container">
      <div style={style} alt={article.name} className="article-image"></div>

      <div className="text-container">
        <p className="article-with-desc-title">{article.title}</p>

        {showDescription && (
          <p className="article-with-desc-description">{article.description}</p>
        )}
      </div>

      <ReadMoreButton
        text={t("READ_MORE")}
        onClick={() => {
          history.push(`knowledge/show-single/${article.id}`);
        }}
      />
    </div>
  );
};

export default ArticleWithDescription;
