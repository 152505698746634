import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// Store
import { GET_USERS_CREDIT_CARDS_SAGA } from "../../../store/sagas/userSaga";

// Components
import DashBoardButton from "../dashboardButton/DashBoardButton";

// Constants
import getDashboardButtonData from "../../../util/functions/ProfileDashboardHelper";
import ORDER_BUTTONS from "../dashboardButton/dashboardButtonConstants";
import { getAlertTextAfterPayment } from "../../../util/functions/OrderFlowHelper";
import { useAlert } from "react-alert";

const Dashboard = () => {
  const token = useSelector((state) => state.authReducer.token);
  const user = useSelector((state) => state.userReducer.user);
  const activeOrders = useSelector(
    (state) => state.localStorageReducer.activeOrders
  );
  const creditCards = useSelector((state) => state.userReducer.creditCards);

  const [active, setActive] = useState("orders");
  const [renderData, setRenderData] = useState();
  const [orderButtonID, setOrderButtonID] = useState(1);
  const [userActiveOrders, setUserActiveOrders] = useState();

  const prevActiveOrders = useRef();

  const dispatch = useDispatch();
  const alert = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    prevActiveOrders.current = userActiveOrders;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userActiveOrders?.length > 0) {
      const lastOrder = userActiveOrders[userActiveOrders.length - 1]?.order;
      const lastPaymentResult =
        userActiveOrders[userActiveOrders.length - 1]?.response;

      alert.show(
        getAlertTextAfterPayment(
          lastOrder,
          lastPaymentResult,
          userActiveOrders,
          prevActiveOrders
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActiveOrders?.length, prevActiveOrders?.current?.length, alert]);

  useEffect(() => {
    const userIndex = activeOrders.findIndex(
      (order) => order.userID === user?.id
    );

    if (userIndex >= 0) {
      setUserActiveOrders(activeOrders[userIndex].activeOrders);
    }
  }, [activeOrders, user?.id]);

  useEffect(() => {
    getDashboardButtonData(
      active,
      user.id,
      token,
      setRenderData,
      orderButtonID,
      userActiveOrders,
      creditCards
    );
  }, [active, token, user.id, orderButtonID, userActiveOrders, creditCards]);

  useEffect(() => {
    dispatch({ type: GET_USERS_CREDIT_CARDS_SAGA, token: token });
  }, [token, dispatch]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-buttons">
        <DashBoardButton
          buttonType="orders"
          text={t("ORDER_HISTORY")}
          activeType={active}
          setActive={setActive}
        />

        <DashBoardButton
          buttonType="experiences"
          text={t("MY_EXPERIENCES")}
          activeType={active}
          setActive={setActive}
        />

        <DashBoardButton
          buttonType="articles"
          text={t("MY_ARTICLES")}
          activeType={active}
          setActive={setActive}
        />

        <DashBoardButton
          buttonType="creditCards"
          text={t("MY_CREDIT_CARDS")}
          activeType={active}
          setActive={setActive}
        />
      </div>

      {active === "orders" && (
        <div className="order-buttons-container">
          {ORDER_BUTTONS.map((ob) => (
            <button
              onClick={() => setOrderButtonID(ob.id)}
              key={ob.id}
              className={
                orderButtonID === ob.id
                  ? "order-button-status-history_clicked"
                  : "order-button-status-history"
              }
            >
              {ob.text}
            </button>
          ))}
        </div>
      )}

      <div className="dashboard-data">{renderData}</div>
    </div>
  );
};

export default Dashboard;
