import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

// Services
import { FETCH_PopularChoices } from "../../../services/productsService";

// Constants
import PopularChoiceButton from "../../buttons/popularChoiceButton/PopularChoiceButton";

const PopularChoices = () => {
  const [popularChoices, setPopularChoices] = useState([]);
  const [randomPopularChoices, setRandomPopularChoices] = useState([]);

  const { t } = useTranslation();

  const history = useHistory();

  const handleOnArticleClick = (popularChoice) => {
    history.push({
      pathname: "/single-product-page",
      state: {
        product: popularChoice,
      },
    });
  };

  useEffect(() => {
    setRandomPopularChoices(
      popularChoices.sort(() => 0.5 - Math.random()).slice(0, 3)
    );
  }, [popularChoices]);

  useEffect(() => {
    async function fetchCategories() {
      const popularChoices = await FETCH_PopularChoices();

      setPopularChoices(popularChoices);
    }

    fetchCategories();
    return () => setPopularChoices([]);
  }, []);

  return (
    <div>
      <h3 className="popular-choices-subtitle">{t("POPULAR_CHOICES")}</h3>
      <div className="popular-choices-render-container">
        {randomPopularChoices.map((popularChoice) => (
          <PopularChoiceButton
            onClick={() => handleOnArticleClick(popularChoice)}
            key={popularChoice.id}
            popularChoice={popularChoice}
          />
        ))}
      </div>
    </div>
  );
};

export default PopularChoices;
