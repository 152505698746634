import * as signalR from "@microsoft/signalr";
import { BASE_URL_ROUTE } from "../../config/api/api";

// Services.
import { getActiveOrders } from "../../services/localStorageService";
import {
  GET_order_by_id,
  FETCH_PaymentResult,
} from "../../services/orderService";

// Constants.
import {
  deleteExistingActiveOrder,
  getAlertTextFromBackoffice,
  getCurrentUserIndex,
} from "./OrderFlowHelper";

export const establishConnection = (orderID) => {
  let connection = new signalR.HubConnectionBuilder()
    .withUrl(`${BASE_URL_ROUTE}/OrderHub`)
    .build();

  connection
    .start()
    .then(() => connection.invoke("SubscribeToOrder", orderID.toString()));

  return connection;
};

export const connectionOn = async (
  connection,
  userIndex,
  updateActiveOrders,
  user,
  reactAlert
) => {
  connection.on("Order", (data) => {
    async function fetchOrderByID() {
      let order = await GET_order_by_id(data.id);
      let paymentResult = await FETCH_PaymentResult(data.id);

      let temporaryActiveOrdersObject = getActiveOrders("activeOrders");

      if (userIndex < 0) {
        let userIndexEmptyList = getCurrentUserIndex(
          user,
          temporaryActiveOrdersObject
        );

        deleteExistingActiveOrder(
          userIndexEmptyList,
          temporaryActiveOrdersObject,
          order.id,
          updateActiveOrders
        );
      } else {
        deleteExistingActiveOrder(
          userIndex,
          temporaryActiveOrdersObject,
          order.id,
          updateActiveOrders
        );
      }

      if (order.orderStatus === 3 || order.orderStatus === 4) {
        reactAlert.show(getAlertTextFromBackoffice(order, paymentResult));

        connection.stop();
      }
    }

    fetchOrderByID();
  });
};
