import { put, takeEvery } from "redux-saga/effects";

// Reducers
import {
  fetchConnectionsFromReducer,
  setUpdatedConnectionsToReducer,
} from "../reducers/webSocketReducer";

const BASE = "cnf/saga/webSocket";

export const FETCH_CONNECTIONS_SAGA = BASE + "/fetchConnections";
export const UPDATE_CONNECTIONS_SAGA = BASE + "/updateConnections";

export function* fetchConnections() {
  console.log(`webSocketSaga.js: saga: ${FETCH_CONNECTIONS_SAGA}`);
  try {
    yield put(fetchConnectionsFromReducer());
  } catch (err) {
    console.log(
      `Error @ webSocketSaga.js -> saga: ${FETCH_CONNECTIONS_SAGA}`,
      err
    );
  }
}

export function* updateConnections({ data }) {
  console.log(`webSocketSaga.js: saga: ${UPDATE_CONNECTIONS_SAGA}`);
  try {
    yield put(setUpdatedConnectionsToReducer(data));
  } catch (err) {
    console.log(
      `Error @ webSocketSaga.js -> saga: ${UPDATE_CONNECTIONS_SAGA}`,
      err
    );
  }
}

export function* watchFetchConnections() {
  yield takeEvery(FETCH_CONNECTIONS_SAGA, fetchConnections);
}

export function* watchUpdateConnections() {
  yield takeEvery(UPDATE_CONNECTIONS_SAGA, updateConnections);
}
