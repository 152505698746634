import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Images
import whiteLogo from "../../assets/images/footer/logo_white.png";
import instagramLogo from "../../assets/images/footer/instagram.png";
import facebookLogo from "../../assets/images/footer/facebook.png";
import twitterLogo from "../../assets/images/footer/twitter.png";
import linkedinLogo from "../../assets/images/footer/linkedin.png";

// Components
import FooterLogos from "../footerLogos/FooterLogos";

const Footer = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const handleClickTermsAndConditions = () => {
    history.push("/terms-and-conditions");
    window.scrollTo(0, 0);
  };

  const footerClassName = useMemo(() => {
    if (
      window.location.pathname.includes("/verify") ||
      window.location.pathname.includes("/successfullyAddedCard") ||
      window.location.pathname.includes("/orderError") ||
      window.location.pathname.includes("/orderSuccess") ||
      window.location.pathname.includes("/registerSuccess") ||
      window.location.pathname.includes("/registerError") ||
      window.location.pathname.includes("/successfullyRegisteredCard")
    ) {
      return "footer_hidden";
    }

    return "footer";
  }, []);

  return (
    <div className={footerClassName}>
      <div className="footer_top_container">
        <img className="footer_logo" alt="Logo-white" src={whiteLogo}></img>
        <div className="footer_visit_us_text_container">
          <div className="footer_category_container">
            <h3 className="footer_title">{t("VISIT_US")}</h3>
            <h4 className="footer_text">Maglajska 34</h4>
            <h4 className="footer_text">{t("ADDRESS")}</h4>
            <h4 className="footer_text">PIB: 104159821</h4>
            <h4 className="footer_text">MB: 20104945</h4>
          </div>
          <div className="footer_category_container">
            <h3 className="footer_title">{t("CONTACT_US")}</h3>
            <h4 className="footer_text">P. +381 11 3675 646</h4>
            <h4 className="footer_text">M. +381 65 3675 647</h4>
            <a
              className="footer_email-link"
              href="mailto: info@cafe-factory.net"
            >
              <h4 className="footer_text">E. info@cafe-factory.net</h4>
            </a>
          </div>

          <div className="footer_category_container">
            <h3 className="footer_title">{t("SOCIAL_MEDIA")}</h3>
            <div className="footer_social_media">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/cafeandfactory/"
              >
                <img
                  className="footer_img"
                  alt="instagram"
                  src={instagramLogo}
                />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/cafe.factory.net/"
              >
                <img className="footer_img" alt="facebook" src={facebookLogo} />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://twitter.com/cafeandfactory"
              >
                <img className="footer_img" alt="twitter" src={twitterLogo} />
              </a>
              <img className="footer_img" alt="linkedin" src={linkedinLogo} />
            </div>

            <div>
              <button
                className="footer_terms-and-conditions-button"
                onClick={handleClickTermsAndConditions}
              >
                <p className="footer_text">{t("TERMS_AND_CONDITIONS_TITLE")}</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="footer_bottom">
          <h4 className="footer_text-small">{t("COPYRIGHT_PT1")}</h4>
          <h4 className="footer_text-small">
            {t("COPYRIGHT_PT2")}{" "}
            <a
              rel="noreferrer"
              target="_blank"
              className="footer_text-small"
              href="https://nora.digital/"
            >
              {t("COPYRIGHT_PT3")}
            </a>
          </h4>
        </div>

        <FooterLogos />
      </div>
    </div>
  );
};

export default Footer;
