import { combineReducers } from "redux";

// Reducers
import { authReducer } from "../../store/reducers/authReducer";
import { paymentReducer } from "../../store/reducers/paymentReducer";
import { userReducer } from "../../store/reducers/userReducer";
import { localStorageReducer } from "../../store/reducers/localStorageReducer";
import { webSocketReducer } from "../../store/reducers/webSocketReducer";

export default combineReducers({
  authReducer,
  paymentReducer,
  userReducer,
  localStorageReducer,
  webSocketReducer,
});
