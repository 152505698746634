import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

// Sagas
import { REGISTER_USER_SAGA } from "../../store/sagas/authSaga";

// Components
import InputField from "../../components/inputFields/InputField";
import PrimaryButton from "../../components/buttons/primaryButton/PrimaryButton";
import CheckboxButtonContainer from "../../components/checkboxButtonContainer/CheckboxButtonContainer";

// Constants
import { disableRegistrationButton } from "../../util/functions/UserHelper";
import {
  EMAIL_REGEX,
  handleEmptyField,
  handleValidField,
} from "../../util/constants/Validations";

// Images
import pressedCheckButton from "../../assets/images/orderFlow/pressed_check_button.png";
import emptyCheckButton from "../../assets/images/orderFlow/empty_check_button.png";

const RegistrationPage = () => {
  const registeredUser = useSelector((state) => state.authReducer.user);

  const [user, setUser] = useState({
    fullName: "",
    email: "",
    password: "",
    streetAddress: "",
    city: "",
    province: "",
    postalCode: "",
    phoneNumber: "",
  });

  const [checkButtonClicked, setCheckButtonClicked] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showEmptyFieldError, setEmptyFieldError] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();

  const regex = EMAIL_REGEX;

  const checkButtonSource = checkButtonClicked
    ? pressedCheckButton
    : emptyCheckButton;

  const handleChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };

  const handleRegister = () => {
    dispatch({ type: REGISTER_USER_SAGA, data: user, alert: alert });
  };

  const handleCheckButtonPress = () => {
    setCheckButtonClicked(!checkButtonClicked);

    if (!checkButtonClicked) setUser({ ...user, isTermsAccepted: true });
    else setUser({ ...user, isTermsAccepted: false });
  };

  useEffect(() => {
    if (
      !(
        Object.keys(registeredUser).length === 0 &&
        registeredUser.constructor === Object
      )
    ) {
      alert.show(t("CHECK_YOUR_EMAIL"));
      history.push("/login");
    }
  }, [registeredUser, history, t, alert]);

  return (
    <div className="registration-page">
      <h2 className="register-title">{t("REGISTER")}</h2>
      <h3 className="register-subtitle">{t("REGISTER_SUBTITLE")}</h3>

      <div className="registration-page_main-container">
        <div className="registration-page_input-container">
          <InputField
            size="large"
            placeholder={t("FULL_NAME")}
            type="text"
            value={user?.fullName}
            onChange={(e) => handleChange("fullName", e.target.value)}
            onBlur={(e) => handleEmptyField(e.target.value, setEmptyFieldError)}
          />

          {showEmptyFieldError && !user?.fullName && (
            <p className="input-field_email-error">
              {t("THIS_FIELD_CAN_NOT_BE_EMPTY")}
            </p>
          )}

          <InputField
            size="large"
            placeholder={t("EMAIL")}
            type="text"
            value={user?.email}
            onChange={(e) => handleChange("email", e.target.value)}
            onBlur={(e) =>
              handleValidField(
                e.target.value,
                setIsValidEmail,
                setShowEmailError,
                setEmptyFieldError,
                regex
              )
            }
          />

          {showEmailError && (
            <p className="input-field_email-error">{t("WRONG_EMAIL_FORMAT")}</p>
          )}

          {showEmptyFieldError && !user?.email && (
            <p className="input-field_email-error">
              {t("THIS_FIELD_CAN_NOT_BE_EMPTY")}
            </p>
          )}

          <InputField
            size="large"
            placeholder={t("PASSWORD")}
            type="password"
            value={user?.password}
            onChange={(e) => handleChange("password", e.target.value)}
            onBlur={(e) =>
              handleValidField(
                e.target.value,
                setIsValidPassword,
                setShowPasswordError,
                setEmptyFieldError
              )
            }
          />

          {showPasswordError && (
            <p className="input-field_email-error">
              {t("WRONG_PASSWORD_FORMAT")}
            </p>
          )}

          {showEmptyFieldError && !user?.password && (
            <p className="input-field_email-error">
              {t("THIS_FIELD_CAN_NOT_BE_EMPTY")}
            </p>
          )}

          <InputField
            size="large"
            placeholder={t("STREET_ADDRESS")}
            type="text"
            value={user?.streetAddress}
            onChange={(e) => handleChange("streetAddress", e.target.value)}
          />

          <InputField
            size="large"
            placeholder={t("CITY")}
            type="text"
            value={user?.city}
            onChange={(e) => handleChange("city", e.target.value)}
          />

          <InputField
            size="large"
            placeholder={t("PROVINCE_REGION")}
            type="text"
            value={user?.province}
            onChange={(e) => handleChange("province", e.target.value)}
          />

          <InputField
            size="large"
            placeholder={t("POST_CODE")}
            type="number"
            value={user?.postalCode}
            onChange={(e) => handleChange("postalCode", e.target.value)}
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === ".") && evt.preventDefault()
            }
          />

          <InputField
            size="large"
            placeholder={t("PHONE_NUMBER")}
            type="number"
            value={user?.phoneNumber}
            onChange={(e) => handleChange("phoneNumber", e.target.value)}
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === ".") && evt.preventDefault()
            }
          />

          <CheckboxButtonContainer
            handleCheckButtonPress={handleCheckButtonPress}
            checkButtonSource={checkButtonSource}
          />

          <PrimaryButton
            disabled={disableRegistrationButton(
              user,
              checkButtonClicked,
              isValidEmail,
              isValidPassword
            )}
            onClick={handleRegister}
            size="large"
            title={t("REGISTER")}
          />
        </div>

        <h4 className="register-text">
          {t("ALREADY_HAVE_ACCOUNT")}
          <a href="/login" className="login-link">
            {" "}
            <span className="register-link">{t("LOG_IN")}</span>{" "}
          </a>
        </h4>
      </div>
    </div>
  );
};

export default RegistrationPage;
