import React from "react";
import { useTranslation } from "react-i18next";

// Constants
import getIcon from "../../../util/functions/ProfileHelper";

const DashBoardButton = (props) => {
  const { buttonType, text, activeType, setActive } = props;

  const { t } = useTranslation();

  return (
    <div
      className={`dashboard-button-container ${
        activeType === buttonType ? "active-container" : ""
      }`}
    >
      <div className="dashboard-button-content">
        <div className="icon-container">{getIcon(buttonType)}</div>
        <span className="dashboard-button-font">{text}</span>
        <button onClick={() => setActive(buttonType)} className="view-all-btn">
          {t("VIEW_ALL")}
        </button>
      </div>
    </div>
  );
};

export default DashBoardButton;
