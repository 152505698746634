import API from "../config/api/api";

const ENDPOINTS = {
    FETCH_ALL_SHOPLOCATIONS: '/shoplocation/getall'
}

const getLanguage = () => {
  if(localStorage.getItem('language') === 'sr') return 0;
  return 1; 
}

export const FETCH_All_Shoplocations = async () => {
    try{
        const response = await API.get(`${ENDPOINTS.FETCH_ALL_SHOPLOCATIONS}`, {headers: {
          Language: getLanguage(),
        }});

        return response.data;
    } catch (err){
        console.log('Error @ shoplocationService.js: FETCH_All_Shoplocations -> ', err);
    }
}