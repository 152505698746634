import React from "react";

const SizeButton = (props) => {
  const { onClick, size, selected } = props;

  const className = selected ? "size-button-red" : "size-button-gray";

  return (
    <button onClick={onClick} className={className}>
      {size}
    </button>
  );
};

export default SizeButton;
