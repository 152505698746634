import i18next from "i18next";

// Constants.
import translation from "../../util/functions/TranslationFunctions";

const ARTICLE_CATEGORIES = [
  {
    id: 1,
    text: i18next.t("CAFE_AND_STORIES"),
  },
  {
    id: 2,
    text: i18next.t("TUTORIALS"),
  },
];

const updateTranslations = () => {
  ARTICLE_CATEGORIES[0].text = i18next.t("CAFE_AND_STORIES");
  ARTICLE_CATEGORIES[1].text = i18next.t("TUTORIALS");
};

translation(updateTranslations);

export default ARTICLE_CATEGORIES;
