export const getSearchIcon = (style) => {
  return (
    <svg
      width="27"
      height="30"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7409 18.6414L26.7006 25.4898C27.041 25.8252 27.0411 26.3695 26.6998 26.7048C26.3594 27.0402 25.8067 27.0402 25.4663 26.7048L18.4796 19.8294C16.5298 21.3856 14.0476 22.3217 11.3412 22.3217C5.07741 22.3217 0 17.3252 0 11.1609C0 4.9965 5.07741 0 11.3412 0C17.605 0 22.6824 4.9965 22.6833 11.1609C22.6833 14.0425 21.5634 16.6612 19.7409 18.6414ZM1.745 11.1609C1.745 16.3765 6.04135 20.6046 11.3412 20.6046C16.6411 20.6046 20.9374 16.3765 20.9374 11.1609C20.9374 5.94524 16.6411 1.71716 11.3412 1.71716C6.04135 1.71716 1.745 5.94524 1.745 11.1609Z"
        fill="black"
      />
    </svg>
  );
};

export const cupIcon = (
  <svg
    width="31"
    height="25"
    viewBox="0 0 31 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.09449 1H20.8167C21.7955 1 22.5889 1.78661 22.5889 2.75694V16.8125C22.5889 20.6938 19.4151 23.8403 15.5 23.8403H8.41115C4.49607 23.8403 1.32227 20.6938 1.32227 16.8125V2.75694C1.32227 1.78661 2.11572 1 3.09449 1"
      stroke="#E23036"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5898 6.27075H26.1343C28.0918 6.27075 29.6787 7.84397 29.6787 9.78464V13.2985C29.6787 15.2392 28.0918 16.8124 26.1343 16.8124H22.5898"
      stroke="#E23036"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const flavourIcon = (
  <svg
    width="25"
    height="30"
    viewBox="0 0 25 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4991 1.4375L20.608 9.45833C24.9749 13.8668 24.9263 20.935 20.4992 25.2843C16.0721 29.6336 8.92605 29.6336 4.49893 25.2843C0.0718055 20.935 0.02319 13.8668 4.39008 9.45833L12.4991 1.4375"
      stroke="#E23036"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const trophyIcon = (
  <svg
    width="87"
    height="87"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_0_3528)">
      <circle cx="43.5" cy="35.5" r="33.5" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_d_0_3528"
        x="0"
        y="0"
        width="87"
        height="87"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.150805 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_0_3528"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_0_3528"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const uploadIcon = (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.5" cy="15.5" r="15.5" fill="#D8D8D8" />
    <path
      d="M8.46094 22.5385H12.0592L22.2542 12.3435C23.2478 11.3498 23.2478 9.73885 22.2542 8.74522C21.2606 7.75159 19.6496 7.75159 18.6559 8.74522L8.46094 18.9402V22.5385"
      stroke="#1A1A1A"
      strokeWidth="1.02"
      strokeLinecap="square"
    />
    <path
      d="M16.7695 11.1155L19.8849 14.2309"
      stroke="#1A1A1A"
      strokeWidth="1.02"
      strokeLinecap="round"
    />
  </svg>
);
