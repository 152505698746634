import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const CheckboxButtonContainer = (props) => {
  const { handleCheckButtonPress, checkButtonSource, loginPage, orderFlow } =
    props;

  const { t } = useTranslation();
  const history = useHistory();

  const container = useMemo(() => {
    if (loginPage) {
      return "checkbox-button-container-login-page";
    } else if (orderFlow) {
      return "checkbox-button-container-order-flow";
    }

    return "checkbox-button-container";
  }, [loginPage, orderFlow]);

  const handlePressLinkButton = (url, privacyPolicy) => {
    privacyPolicy
      ? history?.push({ pathname: url, state: privacyPolicy })
      : history?.push(url);
    window.scrollTo(0, 0);
  };

  return (
    <div className={container}>
      {!loginPage && (
        <React.Fragment>
          <button
            className="check-button"
            type="button"
            onClick={handleCheckButtonPress}
          >
            <img
              className="check-button_image-pressed"
              alt="Pressed check button"
              src={checkButtonSource}
            />
          </button>

          {!orderFlow && (
            <p style={{ textAlign: "center" }} className="check-button_text">
              {t("TERMS_OF_USE_PT1")} {""}
              <button
                onClick={() => handlePressLinkButton("/terms-and-conditions")}
                className="check-button_link-button"
              >
                {t("TERMS_OF_USE_PT2")}
              </button>{" "}
              {t("TERMS_OF_USE_PT3")}{" "}
              <button
                onClick={() =>
                  handlePressLinkButton("/terms-and-conditions", true)
                }
                className="check-button_link-button"
              >
                {" "}
                {t("TERMS_OF_USE_PT4")}
              </button>
            </p>
          )}

          {orderFlow && (
            <button
              onClick={() => handlePressLinkButton("/terms-and-conditions")}
              className="check-button_link-button"
            >
              {t("TERMS_OF_PAYMENT")}
            </button>
          )}
        </React.Fragment>
      )}

      {loginPage && (
        <React.Fragment>
          <div className="checkbox-button-container-login-page_first-part">
            <button
              className="check-button"
              type="button"
              onClick={handleCheckButtonPress}
            >
              <img
                className="check-button_image-pressed"
                alt="Pressed check button"
                src={checkButtonSource}
              />
            </button>

            <p className="check-button_text">
              {t("REMEMBER_ME")} {""}
            </p>
          </div>
          <p className="check-button_text">{t("FORGOT_PASSWORD")}</p>
        </React.Fragment>
      )}
    </div>
  );
};

export default CheckboxButtonContainer;
