import { useEffect, useState } from "react";

// Services
import { CHECK_OrderAvailability } from "../services/orderService";

export const useIsProductAvailable = (orderLine, shopLocationID) => {
  const [isProductDeliverable, setIsProductDeliverable] = useState(true);

  useEffect(() => {
    async function checkOrderAvailability() {
      if (shopLocationID && orderLine) {
        const orderAvailabilityObject = {
          orderLine: orderLine,
          shopLocationId: shopLocationID,
        };

        const isProductDeliverableAPI = await CHECK_OrderAvailability(
          orderAvailabilityObject
        );

        setIsProductDeliverable(isProductDeliverableAPI);
      }
    }

    checkOrderAvailability();
    return () => setIsProductDeliverable(true);
  }, [shopLocationID, orderLine]);

  return isProductDeliverable;
};
