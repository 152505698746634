import React, { useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

// Constants
import { addZeroes } from "../../../util/functions/AddZeroes";

const OrderHistory = (props) => {
  const { order, nonLoggedUser, setShowAlert } = props;

  const displayOrder = useMemo(() => {
    return order?.order ? order?.order : order;
  }, [order]);

  const { t } = useTranslation();

  const getBorderColorByOrderStatus = (orderStatus) => {
    switch (orderStatus) {
      case 0:
        if (nonLoggedUser) {
          return "order-history-bar-in-process_non-logged-user";
        }
        return "order-history-bar-in-process";
      case 1:
        if (nonLoggedUser) {
          return "order-history-bar-in-process_non-logged-user";
        }
        return "order-history-bar-in-process";
      case 2:
        if (nonLoggedUser) {
          return "order-history-bar-in-process_non-logged-user";
        }
        return "order-history-bar-in-process";
      case 3:
        if (nonLoggedUser) {
          return "order-history-bar-accepted_non-logged-user";
        }
        return "order-history-bar-accepted";
      case 4:
        if (nonLoggedUser) {
          return "order-history-bar-rejected_non-logged-user";
        }
        return "order-history-bar-rejected";
      default:
        if (nonLoggedUser) {
          return "order-history-bar-in-process_non-logged-user";
        }
        return "order-history-bar-in-process";
    }
  };

  return (
    <div className={getBorderColorByOrderStatus(displayOrder?.orderStatus)}>
      {nonLoggedUser  && (
        <div className="order_header_wrapper">
        <p className="order-history-text">
          {order?.response?.returnData
            ? t("SUCCESS_TRANSACTION")
            : t("ORDER_IN_PROCESS")}
        </p>
        <div
          className="order_close_icon"
          onClick={() => setShowAlert(displayOrder?.id)}
        >x</div>
        </div>
      )}
      <div className={nonLoggedUser ? "top-row_non-logged-user" : "top-row"}>
        <div className="top-row_attribute-list">
          <span className="top-row_span-left">
            {displayOrder.shopLocationName}
          </span>
          {nonLoggedUser && (
            <>
              <span className="top-row_span-left">{t("ORDER_NUMBER")}</span>
              {order?.response?.returnData && (
                <>
                  <span className="top-row_span-left">{t("CARD_TYPE")}</span>
                  <span className="top-row_span-left">
                    {t("BANK_AUTHORIZATION_CODE")}
                  </span>
                </>
              )}
            </>
          )}
        </div>
        <div className="top-row_attribute-list">
          <span className="top-row_span-right">
            {moment(displayOrder.createdAt).format("DD.MM.YYYY HH:mm:ss")}
          </span>
          {nonLoggedUser && (
            <>
              <span className="top-row_span-right">{displayOrder?.id}</span>
              {order?.response?.returnData && (
                <>
                  <span className="top-row_span-right">
                    {order?.response?.returnData?.type +
                      " **** - " +
                      order?.response?.returnData?.lastFourDigits}
                  </span>
                  <span className="top-row_span-right">
                    {order?.response?.extraData?.authCode ?? "XXXX"}
                  </span>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {displayOrder.products.map((e, index) => (
        <div
          key={`${index}-${displayOrder.products.length}`}
          className={
            nonLoggedUser ? "order-item_non-logged-user" : "order-item"
          }
        >
          <span className="product-name_first-part">{`${e.amount} x ${e.productName}`}</span>
          <span className="product-name">
            {addZeroes(e.price * e.amount)} {t("DIN")}
          </span>
        </div>
      ))}

      {nonLoggedUser && (
        <React.Fragment>
          <div
            className={
              nonLoggedUser ? "order-item_non-logged-user" : "order-item"
            }
          >
            <span className="product-name_first-part">
              {t("DELIVERY_PRICE")}
            </span>
            <span className="product-name">
              {addZeroes(displayOrder.deliveryPrice)} {t("DIN")}
            </span>
          </div>

          <br />

          <div
            className={
              nonLoggedUser ? "order-item_non-logged-user" : "order-item"
            }
          >
            <span className="product-name_first-part">{t("TOTAL_PRICE")}</span>
            <span className="product-name">
              {addZeroes(displayOrder.priceTotal)} {t("DIN")}
            </span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default OrderHistory;
