import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Services
import { FETCH_Articles } from "../../services/productsService";
import { SEARCH_ArticlesByTitle } from "../../services/searchService";

// Components
import ArticleWithDescription from "../../components/knowledgePage/ArticleWithDescription/ArticleWithDescription";
import SearchInputField from "../../components/inputFields/SearchInputField";
import TitleWithDescription from "../../components/title/TitleWithDescription";

// Constants
import ARTICLE_CATEGORIES from "./knowledgePageConstants";
import getArticlesForDisplayHelper from "../../util/functions/GetArticlesForDisplay";

// Images
import whiteLogo from "../../assets/images/footer/logo_white.png";

const KnowledgePage = () => {
  const [articles, setArticles] = useState([]);
  const [selectedCategoryButtonID, setSelectedCategoryButtonID] = useState(1);

  const { t } = useTranslation();

  const articlesForDisplay = useMemo(() => {
    return getArticlesForDisplayHelper(selectedCategoryButtonID, articles);
  }, [selectedCategoryButtonID, articles]);

  useEffect(() => {
    (async function fetchArticles() {
      const articles = await FETCH_Articles();

      setArticles(articles);
    })();
  }, []);

  return (
    <main className="knowledge-page">
      <div className="title-container">
        <TitleWithDescription
          titleAdditionalClass="knowledge-page-title"
          subtitleAdditionalClass="knowledge-page-title"
          title={t("KNOWLEDGE_HEADER")}
          subtitle={t("KNOWLEDGE_HEADER_SUBTITLE")}
        />

        <div className="title-container_logo-container">
          <img alt="logo" src={whiteLogo} className="title-container_logo" />
        </div>
      </div>

      <div className="knowledge-page-content">
        <div className="all-articles-container">
          {articlesForDisplay?.map((e) => (
            <ArticleWithDescription key={e.id} article={e} />
          ))}
        </div>

        <div className="search-filter-articles-container">
          <SearchInputField
            searchFunction={SEARCH_ArticlesByTitle}
            returnResult={setArticles}
          />

          <p id="category-title">{t("CATEGORY")}</p>

          {ARTICLE_CATEGORIES.map((ac) => (
            <button
              id={
                ac.id === selectedCategoryButtonID
                  ? "article-category-button_clicked"
                  : "article-category-button"
              }
              key={ac.id}
              onClick={() => setSelectedCategoryButtonID(ac.id)}
            >
              <p
                id={
                  ac.id === selectedCategoryButtonID
                    ? "article-category-button_text_clicked"
                    : "article-category-button_text"
                }
              >
                {ac.text}
              </p>
            </button>
          ))}
        </div>
      </div>
    </main>
  );
};

export default KnowledgePage;
