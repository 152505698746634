import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Reducer
import { SET_GUEST } from "../../store/reducers/userReducer";

// Components
import CheckoutContainer from "../../components/orderFlow/checkoutContainer/CheckoutContainer";
import PaymentButton from "../../components/buttons/paymentButton/PaymentButton";

// Constants
import { getCurrentUserCart } from "../../util/functions/OrderFlowHelper";

// Images
import creditCardImage from "../../assets/images/payment/credit-card.png";
import moneyImage from "../../assets/images/payment/money.png";

const GuestOrderCheckoutPage = () => {
  const cart = useSelector((state) => state.localStorageReducer.cart);

  const [paymentType, setPaymentType] = useState(0);
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0.0);

  const [user, setUser] = useState({
    fullName: "",
    streetAddress: "",
    city: "",
    province: "",
    postalCode: "",
    phoneNumber: "",
    email: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const userCart = getCurrentUserCart(user, cart);

    setProducts(userCart?.cart);
    setTotalPrice(
      userCart?.cart.reduce((acc, val) => acc + val.amount * val.price, 0)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const handleChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };

  const handleContinue = () => {
    dispatch({ type: SET_GUEST, payload: user });

    history.push({
      pathname: "/order-checkout",
      state: {
        cash: paymentType === 1,
        email: user?.email,
      },
    });
  };

  const handleClickPaymentButton = (id) => {
    setPaymentType(id);
  };

  const handlePressSignButton = (path) => {
    history.push(`/${path}`);
  };

  return (
    <div className="container">
      <div className="title-container">
        <h2 className="register-title">{t("GUEST_OR_RETURNING_CUSTOMER")}</h2>
        <h3 className="register-subtitle">
          {t("HOW_DO_YOU_WANT_TO_ORDER_AN_ITEM")}
        </h3>
      </div>

      <CheckoutContainer
        user={user}
        handleChange={handleChange}
        handleContinue={handleContinue}
        handleSignIn={() => handlePressSignButton("register")}
        handleSignUp={() => handlePressSignButton("login")}
        products={products}
        totalPrice={totalPrice}
      />

      <div>
        <p className="guest-title">{t("PAYMENT_TYPE")}</p>
        <p className="guest-subtitle">{t("HOW_WOULD_YOU_LIKE_TO_PAY")}</p>

        <div className="payment-buttons-container">
          <div className="payment-buttons-container_bank-card">
            <PaymentButton
              onClick={() => handleClickPaymentButton(0)}
              image={creditCardImage}
              text={t("BANK_CARD")}
              clicked={paymentType === 0}
            />
          </div>
          <PaymentButton
            onClick={() => handleClickPaymentButton(1)}
            image={moneyImage}
            text={t("PAY_CASH")}
            clicked={paymentType === 1}
          />
        </div>
      </div>
    </div>
  );
};

export default GuestOrderCheckoutPage;
