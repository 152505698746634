import React from "react";
import { useTranslation } from "react-i18next";

// Components
import StarIcon from "../starIcon/StarIcon";

const Article = (props) => {
  const { article, starIcon, community, onClick } = props;

  const { t } = useTranslation();

  return (
    <div className="article">
      {community && (
        <img
          src={article.imageUrls[0]}
          alt={article.title}
          className="article-image-community"
        ></img>
      )}

      {!community && (
        <img
          src={article.imageUrl}
          alt={article.title}
          className="article-image-knowledge"
        ></img>
      )}

      {starIcon && (
        <div style={{ marginTop: "15px" }}>
          <StarIcon />
        </div>
      )}

      {community && (
        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
          <span className="review-title">{article.description}</span>
        </div>
      )}

      {!community && <p className="article-text">{article.title}</p>}

      <button onClick={onClick} className="article-button">
        {t("READ_MORE")}
      </button>
    </div>
  );
};

export default Article;
