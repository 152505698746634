export const BASE = "cnf/reducer/localStorage";

export const FETCH_CART = BASE + "/fetchCart";
export const FETCH_ACTIVE_ORDERS = BASE + "/fetchActiveOrders";

const initialState = {
  cart: [],
  activeOrders: [],
};

export const localStorageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART:
      return { ...state, cart: action.payload ? action.payload : [] };
    case FETCH_ACTIVE_ORDERS:
      return { ...state, activeOrders: action.payload ? action.payload : [] };
    default:
      return state;
  }
};

export const setCartToReducer = (payload) => {
  return {
    type: FETCH_CART,
    payload,
  };
};

export const setActiveOrdersToReducer = (payload) => {
  return {
    type: FETCH_ACTIVE_ORDERS,
    payload,
  };
};
