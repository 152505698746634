import React from "react";

const PrimaryButton = (props) => {
  const { type, title, onClick, size, disabled } = props;

  const getClassName = () => {
    switch (size) {
      case "small":
        return "primary-button-small";
      case "medium":
        return "primary-button-medium";
      case "large":
        return "primary-button-large";
      default:
        return "primary-button-small";
    }
  };

  return (
    <button type={type} className={getClassName()} onClick={onClick} disabled={disabled}>
      {title}
    </button>
  );
};

export default PrimaryButton;
