import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import moment from "moment";

// Services
import { FETCH_SingleArticle } from "../../../services/articleService";

// Components
import ArticleSection from "../../../components/knowledgePage/ArticleSection/ArticleSection";
import Knowledge from "../../../components/landingPage/knowledge/Knowledge";
import LoadingIndicator from "../../../components/loadingIndicator/LoadingIndicator";

const ShowSingleArticle = () => {
  const [article, setArticle] = useState();

  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchArticle() {
      const article = await FETCH_SingleArticle(id);

      setArticle(article);
    }

    fetchArticle();
  }, [id]);

  return (
    <main className="show-single-article-page">
      {!article && <LoadingIndicator />}
      {article && (
        <>
          <img
            alt="article-cover"
            className="cover-image"
            src={`${article.coverImageUrl}`}
          />

          <div className="center-container">
            <article className="article-data-container">
              <h2 className="knowledge-page-title-single">{article.title}</h2>

              <h5 className="knowledge-page-date">
                {moment.utc(article.updatedAt).local().format("MMM, Do YYYY.")}
              </h5>

              {article.sections.map((e) => (
                <ArticleSection key={e.sectionIndex} section={e} />
              ))}
            </article>
          </div>
        </>
      )}

      <div className="knowledge-container">
        <Knowledge articleId={id} knowledgePage={true} />
      </div>
    </main>
  );
};

export default ShowSingleArticle;
