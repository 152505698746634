export const BASE = "cnf/reducer/payment";

export const CARD_CHECKOUT = BASE + "/card-checkout";

const initialState = {
  checkoutId: "",
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARD_CHECKOUT:
      return { ...state, checkoutId: action.payload };
    default:
      return state;
  }
};

export const cardCheckout = (value) => {
  return {
    type: CARD_CHECKOUT,
    payload: value,
  };
};
