import React from "react";
import { useTranslation } from "react-i18next";

// Constants
import OrderInfoText from "../orderInfoText/OrderInfoText";

const OrderDetailsContainer = (props) => {
  const {
    order,
    totalPrice,
    handleContinue,
    orderCheckoutPage,
    deliveryPrice,
  } = props;

  const { t } = useTranslation();

  const orderDetailsBlock = orderCheckoutPage
    ? "order-details-block-checkout"
    : "order-details-block";

  return (
    <div className="order-details-container-checkout">
      <p className="order-details-title">{t("ORDER_DETAILS")}</p>

      <div className={orderDetailsBlock}>
        {order.OrderLine?.map((product) => (
          <OrderInfoText key={product.productId} product={product} />
        ))}

        <OrderInfoText deliveryPrice={deliveryPrice} />
        <OrderInfoText totalPrice={totalPrice} />
      </div>

      <div style={{ marginTop: 41 }}>
        <button className="primary-button-large" onClick={handleContinue}>
          {t("CONTINUE")}
        </button>
      </div>
    </div>
  );
};

export default OrderDetailsContainer;
