import { call, put, takeEvery } from "@redux-saga/core/effects";

// Services
import {
  DELETE_CreditCard,
  FETCH_UserDataFromToken,
  GET_CreditCards,
} from "../../services/userService";

// Reducers
import {
  setDeleteCreditCard,
  setUser,
  setUsersCreditCards,
} from "../reducers/userReducer";

const BASE = "cnf/saga/user";

export const GET_USER_BY_TOKEN = BASE + "/getUserByToken";
export const GET_USERS_CREDIT_CARDS_SAGA = BASE + "/getUsersCreditCards";
export const DELETE_CREDIT_CARD_SAGA = BASE + "/deleteCreditCard";

export function* getUserByToken({ data }) {
  console.log(`userSaga.js: saga: ${GET_USER_BY_TOKEN}`);
  try {
    let response = yield call(FETCH_UserDataFromToken, data);

    yield put(setUser(response));
  } catch (err) {
    console.log(`Error @ userSaga.js -> saga: ${GET_USER_BY_TOKEN}`, err);
  }
}

export function* getUsersCreditCards({ token }) {
  console.log(`userSaga.js: saga: ${GET_USERS_CREDIT_CARDS_SAGA}`);
  try {
    const usersCreditCards = yield call(GET_CreditCards, token);

    yield put(setUsersCreditCards(usersCreditCards));
  } catch (err) {
    console.log(
      `Error @ userSaga.js -> saga: ${GET_USERS_CREDIT_CARDS_SAGA}`,
      err
    );
  }
}

export function* deleteCreditCard({ creditCard, token }) {
  console.log(`userSaga.js: saga: ${DELETE_CREDIT_CARD_SAGA}`);
  try {
    const response = yield call(DELETE_CreditCard, creditCard, token);

    if (response.status === 200) {
      yield put(setDeleteCreditCard(creditCard));
    }
  } catch (err) {
    console.log(`Error @ userSaga.js -> saga: ${DELETE_CREDIT_CARD_SAGA}`, err);
  }
}

export function* watchGetUserByToken() {
  yield takeEvery(GET_USER_BY_TOKEN, getUserByToken);
}

export function* watchGetUsersCreditCards() {
  yield takeEvery(GET_USERS_CREDIT_CARDS_SAGA, getUsersCreditCards);
}

export function* watchDeleteCreditCard() {
  yield takeEvery(DELETE_CREDIT_CARD_SAGA, deleteCreditCard);
}
