import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Store
import { GET_USER_BY_TOKEN } from "../../store/sagas/userSaga";

// Images
import creditCardImage from "../../assets/images/payment/credit-card.png";
import moneyImage from "../../assets/images/payment/money.png";

const PaymentMethodPage = () => {
  const token = useSelector((state) => state.authReducer.token);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handlePay = (isCash) => {
    history.push({
      pathname: "/order-checkout",
      state: {
        cash: isCash,
      },
    });
  };

  useEffect(() => {
    token && dispatch({ type: GET_USER_BY_TOKEN, data: token });
  }, [dispatch, token]);

  return (
    <div
      style={{ display: "grid", justifyContent: "center", textAlign: "center" }}
    >
      <h2 className="register-title">{t("PAYMENT_METHOD")}</h2>
      <h3 className="register-subtitle">{t("HOW_WOULD_YOU_LIKE_TO_PAY")}</h3>
      <div style={{ justifyContent: "center", marginBottom: 100 }}>
        <div className="main-container">
          <button
            className="payment-method-button"
            type="text"
            onClick={() => handlePay(false)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "30px",
              }}
            >
              <img
                alt="Pressed bank card"
                src={creditCardImage}
                style={{
                  marginRight: "20px",
                }}
              />
              <span className="check-button_text">{t("BANK_CARD")}</span>
            </div>
          </button>
          <button
            className="payment-method-button"
            type="text"
            onClick={() => handlePay(true)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "30px",
              }}
            >
              <img
                alt="Pressed pay in cash"
                src={moneyImage}
                style={{
                  marginRight: "20px",
                }}
              />
              <span className="payment-text">{t("PAY_CASH")}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodPage;
