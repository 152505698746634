import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const usePaymentOption = (creditCard) => {
  const location = useLocation();

  const [paymentOption, setPaymentOption] = useState(0);

  useEffect(() => {
    if (location.state?.cash) {
      setPaymentOption(0);
    } else {
      if (!creditCard?.save_card) {
        if (creditCard?.referenceUid) {
          setPaymentOption(2);
        } else {
          setPaymentOption(1);
        }
      } else {
        setPaymentOption(1);
      }
    }

    return () => setPaymentOption(0);
  }, [location.state, creditCard]);

  return { paymentOption };
};
