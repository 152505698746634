import i18next from "i18next";

// Constants.
import translation from "../../../util/functions/TranslationFunctions";

const ORDER_BUTTONS = [
  {
    id: 1,
    text: i18next.t("ORDER_STATUS"),
  },
  {
    id: 2,
    text: i18next.t("ORDER_HISTORY"),
  },
];

const updateTranslations = () => {
  ORDER_BUTTONS[0].text = i18next.t("ORDER_STATUS");
  ORDER_BUTTONS[1].text = i18next.t("ORDER_HISTORY");
};

translation(updateTranslations);

export default ORDER_BUTTONS;
