import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useAlert } from "react-alert";

import "./scss/main.scss";
import "./index.css";

// Store
import {
  FETCH_CART_SAGA,
  SET_TOKEN_SAGA,
  FETCH_ACTIVE_ORDERS_SAGA,
  UPDATE_ACTIVE_ORDERS_SAGA,
} from "./store/sagas/localStorageSaga";
import { GET_USER_BY_TOKEN } from "./store/sagas/userSaga";

// Services
import { GET_order_by_id, FETCH_PaymentResult } from "./services/orderService";
import { getActiveOrders } from "./services/localStorageService";

// Components
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

// Pages
import {
  AddCardPage,
  CheckboxQuestion,
  CommunityPage,
  ConfirmPaymentPage,
  ErrorPage,
  FirstQuestion,
  GuestOrderCheckoutPage,
  InfoTextPage,
  KnowledgePage,
  LandingPage,
  LoginPage,
  OrderCheckoutPage,
  PaymentMethodPage,
  ProductsListPage,
  RegistrationPage,
  ShowSingleArticle,
  ShowSingleReview,
  SingleProductPage,
  TermsAndConditionsPage,
  UserProfilePage,
  VerifyMailPage,
} from "./pages";

// Constants
import {
  deleteExistingActiveOrder,
  getAlertTextFromBackoffice,
  getCurrentUserIndex,
  updateWebSocketConnections,
} from "./util/functions/OrderFlowHelper";
import {
  connectionOn,
  establishConnection,
} from "./util/functions/WebSocketHelper";
import {
  FETCH_CONNECTIONS_SAGA,
  UPDATE_CONNECTIONS_SAGA,
} from "./store/sagas/webSocketSaga";
import { t } from "i18next";

function App() {
  const token = useSelector((state) => state.authReducer.token);
  const user = useSelector((state) => state.userReducer.user);
  const registeredUser = useSelector((state) => state.authReducer.user);
  const connections = useSelector(
    (state) => state.webSocketReducer.connections
  );

  const alert = useAlert();
  const dispatch = useDispatch();

  const updateActiveOrders = (activeOrders) => {
    dispatch({ type: UPDATE_ACTIVE_ORDERS_SAGA, activeOrders: activeOrders });
  };

  const updateConnections = (connections) => {
    dispatch({ type: UPDATE_CONNECTIONS_SAGA, data: connections });
  };

  useEffect(() => {
    dispatch({ type: FETCH_CART_SAGA });
  }, [dispatch]);

  useEffect(() => {
    if (!token) dispatch({ type: SET_TOKEN_SAGA });
  }, [token, dispatch]);

  useEffect(() => {
    if (token) dispatch({ type: GET_USER_BY_TOKEN, data: token });
  }, [token, dispatch]);

  useEffect(() => {
    dispatch({ type: FETCH_ACTIVE_ORDERS_SAGA });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: FETCH_CONNECTIONS_SAGA });
  }, [dispatch]);

  useEffect(() => {
    let userIndex;
    let temporaryActiveOrders = getActiveOrders("activeOrders");

    if (token) {
      userIndex = temporaryActiveOrders?.findIndex(
        (order) => order.userID === user?.id
      );
    } else {
      userIndex = temporaryActiveOrders?.findIndex(
        (order) => order.userID === 0
      );
    }

    let newConnections = {};
    let temporaryConnections = [...connections];

    if (userIndex >= 0) {
      const userActiveOrders = temporaryActiveOrders[userIndex].activeOrders;

      userActiveOrders?.forEach((activeOrder) => {
        async function fetchOrderByID() {
          let order = await GET_order_by_id(activeOrder.order.id);

          if (order) {
            if (order.orderStatus === 3 || order.orderStatus === 4) {
              let paymentResult = await FETCH_PaymentResult(
                activeOrder.order.id
              );

              alert.show(getAlertTextFromBackoffice(order, paymentResult));

              deleteExistingActiveOrder(
                userIndex,
                temporaryActiveOrders,
                activeOrder.order.id,
                updateActiveOrders
              );
            } else {
              const connection = establishConnection(order.id);

              const userIndexConnections = getCurrentUserIndex(
                user?.id,
                temporaryConnections
              );

              updateWebSocketConnections(
                temporaryConnections,
                newConnections,
                "connections",
                connection,
                user?.id,
                updateConnections,
                userIndexConnections
              );

              connectionOn(
                connection,
                userIndex,
                updateActiveOrders,
                user,
                alert
              );
            }
          } else {
            deleteExistingActiveOrder(
              userIndex,
              temporaryActiveOrders,
              activeOrder.order.id,
              updateActiveOrders
            );
          }
        }

        fetchOrderByID();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <Router>
      <div>
        <Navbar />

        {!token && (
          <React.Fragment>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/register" component={RegistrationPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/user/verify/:id" component={VerifyMailPage} />
            <Route exact path="/knowledge" component={KnowledgePage} />
            <Route
              exact
              path="/knowledge/show-single/:id"
              component={ShowSingleArticle}
            />
            <Route exact path="/community" component={CommunityPage} />
            <Route
              exact
              path="/community/show-single/:id"
              component={ShowSingleReview}
            />
            <Route exact path="/payment-method" component={PaymentMethodPage} />
            <Route exact path="/add-card" component={AddCardPage} />
            <Route
              exact
              path="/guest-order-checkout"
              component={GuestOrderCheckoutPage}
            />
            <Route exact path="/order-checkout" component={OrderCheckoutPage} />
            <Route exact path="/confirm-order" component={ConfirmPaymentPage} />
            <Route
              exact
              path="/products-page/:id"
              component={ProductsListPage}
            />
            <Route
              exact
              path="/single-product-page"
              component={SingleProductPage}
            />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditionsPage}
            />
            <Route exact path="/error" component={ErrorPage} />

            <Route exact path="/successfullyAddedCard">
              <InfoTextPage text={t("SUCCESSFULLY_ADDED_CARD")} />
            </Route>
            <Route exact path="/orderError">
              <InfoTextPage text={t("ORDER_ERROR")} />
            </Route>
            <Route exact path="/orderSuccess">
              <InfoTextPage text={t("ORDER_SUCCESS")} />
            </Route>
            <Route exact path="/successfullyRegisteredCard">
              <InfoTextPage text={t("SUCCESS_CARD_REGISTRATION")} />
            </Route>
          </React.Fragment>
        )}

        {token && registeredUser?.isFirstLogin && (
          <React.Fragment>
            <Route
              exact
              path="/onboarding/question-one"
              component={FirstQuestion}
            />
            <Route exact path="/onboarding/question-two">
              <CheckboxQuestion isFlavour={true} />
            </Route>
            <Route exact path="/onboarding/question-three">
              <CheckboxQuestion isFlavour={false} />
            </Route>
          </React.Fragment>
        )}

        {token && (
          <React.Fragment>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/profile" component={UserProfilePage} />
            <Route exact path="/knowledge" component={KnowledgePage} />
            <Route
              exact
              path="/knowledge/show-single/:id"
              component={ShowSingleArticle}
            />
            <Route exact path="/community" component={CommunityPage} />
            <Route
              exact
              path="/community/show-single/:id"
              component={ShowSingleReview}
            />
            <Route exact path="/payment-method" component={PaymentMethodPage} />
            <Route exact path="/add-card" component={AddCardPage} />
            <Route exact path="/order-checkout" component={OrderCheckoutPage} />
            <Route exact path="/confirm-order" component={ConfirmPaymentPage} />
            <Route
              exact
              path="/products-page/:id"
              component={ProductsListPage}
            />
            <Route
              exact
              path="/single-product-page"
              component={SingleProductPage}
            />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditionsPage}
            />
            <Route exact path="/error" component={ErrorPage} />

            <Route exact path="/successfullyAddedCard">
              <InfoTextPage text={t("SUCCESSFULLY_ADDED_CARD")} />
            </Route>
            <Route exact path="/orderError">
              <InfoTextPage text={t("ORDER_ERROR")} />
            </Route>
            <Route exact path="/orderSuccess">
              <InfoTextPage text={t("ORDER_SUCCESS")} />
            </Route>
            <Route exact path="/registerSuccess">
              <InfoTextPage text={t("REGISTER_CARD_SUCCESS")} />
            </Route>
            <Route exact path="/registerError">
              <InfoTextPage text={t("REGISTER_CARD_ERROR")} />
            </Route>

          </React.Fragment>
        )}

        <Footer />
      </div>
    </Router>
  );
}

export default App;
