import { React, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Services
import { ADD_Onboarding_questions } from "../../../services/productsService";

// Components
import PrimaryButton from "../../../components/buttons/primaryButton/PrimaryButton";

// Images
import plusSign from "../../../assets/images/onboarding/plus_sign.png";
import minusSign from "../../../assets/images/onboarding/minus_sign.png";

const FirstQuestion = () => {
  const token = useSelector((state) => state.authReducer.token);
  const user = useSelector((state) => state.userReducer.user);

  const [counter, setCounter] = useState(0);

  const { t } = useTranslation();
  const history = useHistory();

  const answerDataNumberOfCoffee = {
    type: 0,
    UserId: user.id,
    Data: [counter],
  };

  const handleButtonClick = () => {
    ADD_Onboarding_questions(token, answerDataNumberOfCoffee);
    history.push("/onboarding/question-two");
  };

  const counterReduce = () => {
    if (counter > 0) {
      setCounter(counter - 1);
    }
  };

  const counterIncrease = () => {
    setCounter(counter + 1);
  };

  const handleSkipQuestion = () => {
    history.push("/");
  };

  return (
    <div className="onboarding-question">
      <div className="onboarding-question-heading">
        <h3 className="onboarding-question-title">{t("LETS_GET_TO_KNOW")}</h3>
        <p className="onboarding-question-subtitle">{t("HOW_OFTEN_COFFEE")}</p>
      </div>

      <div className="first-question-counter">
        <button onClick={counterReduce} className="first-question-minus-sign">
          <img alt="minus-sign" src={minusSign}></img>
        </button>
        <p className="first-question-counter">{counter}</p>
        <button onClick={counterIncrease} className="first-question-plus-sign">
          <img alt="plus-sign" src={plusSign}></img>
        </button>
      </div>

      <PrimaryButton
        onClick={handleButtonClick}
        title={t("NEXT")}
        size="medium"
      />

      <button onClick={handleSkipQuestion} className="skip-button">
        {t("LATER")}
      </button>
    </div>
  );
};

export default FirstQuestion;
