import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Services
import { FETCH_Reviews } from "../../services/reviewService";
import { SEARCH_ReviewsByTitle } from "../../services/searchService";

// Components
import SearchInputField from "../../components/inputFields/SearchInputField";
import Review from "../../components/review/Review";
import TitleWithDescription from "../../components/title/TitleWithDescription";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";

const CommunityPage = () => {
  const [reviews, setReviews] = useState([]);

  const { t } = useTranslation();

  const displayLoadingIndicator = reviews && reviews.length === 0;

  useEffect(() => {
    (async function fetchReviews() {
      const reviews = await FETCH_Reviews();

      setReviews(reviews);
    })();
  }, []);

  return (
    <main className="community-page-main-container">
      {displayLoadingIndicator && <LoadingIndicator />}
      {!displayLoadingIndicator && (
        <React.Fragment>
          <div className="communityPage-title-search-container">
            <TitleWithDescription
              subtitleAdditionalClass="communityPage-subtitle"
              titleAdditionalClass="communityPage-title"
              title={t("COMMUNITY_HEADER")}
              subtitle={t("COMMUNITY_HEADER_SUBTITLE")}
            />

            <SearchInputField
              searchFunction={SEARCH_ReviewsByTitle}
              returnResult={setReviews}
            />
          </div>

          <div className="communityPage-content">
            <div className="all-reviews-container">
              {reviews &&
                reviews.map((e) => (
                  <Review key={e.id} review={e} showAuthor={true} />
                ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </main>
  );
};

export default CommunityPage;
