import React, { useState } from "react";

const OnboardCheckboxButton = (props) => {
  const { flavour, setResultSet, resultSet } = props;

  const [checked, setChecked] = useState(false);

  const handleFlavourClick = (id) => {
    setChecked(!checked);

    if (!checked) setResultSet([...resultSet, id]);
    else setResultSet(resultSet.filter((item) => item !== id));
  };

  const buttonClassName = checked
    ? "onboarding-coffee-flavour-button_clicked"
    : "onboarding-coffee-flavour-button";

  return (
    <button
      id={`${flavour.name}-btn`}
      className={buttonClassName}
      onClick={() => handleFlavourClick(flavour.id)}
    >
      {flavour.name}
    </button>
  );
};

export default OnboardCheckboxButton;
