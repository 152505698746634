// Icons
import {
  myArticlesIcon,
  myExperiencesIcon,
  orderHistoryIcon,
  myCreditCardsIcon,
} from "../constants/DashboardIcons";

const getIcon = (buttonType) => {
  switch (buttonType) {
    case "orders":
      return orderHistoryIcon;
    case "experiences":
      return myExperiencesIcon;
    case "creditCards":
      return myCreditCardsIcon;
    default:
      return myArticlesIcon;
  }
};

export default getIcon;
