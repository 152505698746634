import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { RatingView } from "react-simple-star-rating";

// Components
import UserDetailsSmall from "../userDetailsSmall/UserDetailsSmall";
import ReadMoreButton from "../buttons/readMoreButton/ReadMoreButton";

// Constants
import { getReviewTitle } from "../../util/functions/GetReviewTitle";

const Review = (props) => {
  const [title, setTitle] = useState("");

  const { review, showAuthor } = props;

  const { t } = useTranslation();

  const history = useHistory();

  const style = {
    backgroundColor: "white",
    backgroundImage:
      review.imageUrls.length !== 0 ? `url(${review.imageUrls[0]}` : "none",
    backgroundSize: "cover",
    border: review.imageUrls.length === 0 ? "1px solid black" : "none",
  };

  useEffect(() => {
    let title = getReviewTitle(review.description);

    setTitle(title);
  }, [review.description]);

  return (
    <div className="review-container">
      <div style={style} alt={title} className="review-image"></div>

      <div className="review-content-container">
        {showAuthor && (
          <UserDetailsSmall
            levelText=""
            imageUrl={review.userProfileImageUrl}
            fullName={review.userFullName}
          />
        )}

        <div className={`${showAuthor ? "" : "reverse-order"}`}>
          <span className="review-title">{title}</span>

          <div
            className={`${
              showAuthor ? "stars-container" : "stars-container-reverse"
            }`}
          >
            <RatingView
              ratingValue={review.value}
              transition={false}
              size={40}
            />
          </div>
        </div>

        <ReadMoreButton
          text={t("READ_MORE")}
          onClick={() => {
            history.push(`community/show-single/${review.id}`);
          }}
        />
      </div>
    </div>
  );
};

export default Review;
