import React from "react";

const ReadMoreButton = (props) => {
  const { text, onClick, coverImage } = props;

  const btnClassName = coverImage ? "change-cover-btn" : "read-more-btn";

  return (
    <button className={btnClassName} onClick={onClick}>
      {text}
    </button>
  );
};

export default ReadMoreButton;
