import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import SizeButton from "../../components/buttons/sizeButton/SizeButton";
import AllCategories from "../../components/landingPage/allCategories/AllCategories";
import QuantityContainer from "../../components/orderFlow/quantityContainer/QuantityContainer";
import YouMightBeInterested from "../../components/youMightbeInterested/YouMightBeInterested";

// Constants
import { addZeroes } from "../../util/functions/AddZeroes";

const SingleProductPage = () => {
  const location = useLocation();
  const product = location.state.product;

  const [productSizes, setProductSizes] = useState();
  const [productSizeLabel, setProductSizeLabel] = useState();
  const [productSize, setProductSize] = useState();
  const [amount, setAmount] = useState(1);

  const { t } = useTranslation();

  const handleSetAmount = (option) => {
    setAmount(option);
  };

  const handleProductChange = (size) => {
    setProductSizeLabel(size);
    const productSize = productSizes.find(
      (s) => s.sizeLabel === productSizeLabel
    );

    setProductSize(productSize);
  };

  useEffect(() => {
    productSizes &&
      productSizes.length > 0 &&
      setProductSizeLabel(productSizes[0].sizeLabel);
  }, [productSizes]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setProductSizes(product.sizes);
  }, [product]);

  useEffect(() => {
    if (productSizes && productSizes.length > 0) {
      const productSize = productSizes.find(
        (s) => s.sizeLabel === productSizeLabel
      );

      setProductSize(productSize);
    }
  }, [productSizes, productSizeLabel]);

  return (
    <div>
      <div className="single-product-page">
        <div className="single-product-image-container">
          <img
            alt="product"
            className="single-product-image"
            src={product.imageUrl}
          ></img>
        </div>

        <div className="product-order-options-container">
          <p className="single-product-page-description">
            {t("FOOD_DRINKS_SERVING")}
          </p>

          <div className="single-product-name-and-price">
            <p className="single-product-name">{product.name}</p>
            <p className="single-product-price">
              {addZeroes(productSize?.price * amount)} {t("DIN")}
            </p>
          </div>

          <div className="single-product-size-container">
            {productSizes &&
              productSizes.length > 0 &&
              productSizes.map((s) => (
                <SizeButton
                  onClick={() => handleProductChange(s.sizeLabel)}
                  key={s.id}
                  size={s.sizeLabel}
                  selected={s.sizeLabel === productSizeLabel}
                />
              ))}

            <QuantityContainer
              amount={amount}
              handleSetAmount={handleSetAmount}
              product={product}
              productSize={productSize}
            />
          </div>
        </div>
      </div>

      <YouMightBeInterested />

      <div className="other-categories-container">
        <AllCategories heading="Other Categories" />
      </div>
    </div>
  );
};

export default SingleProductPage;
