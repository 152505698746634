export const getReviewTitle = (text) => {
  if (!text) {
    return "";
  }

  if (text.length <= 25) {
    return text;
  }

  const title = text.slice(0, 23);

  return `${title}...`;
};

export const getReviewTitleWithValue = (text, value) => {
  const title = getReviewTitle(text);

  return `${value}++ ${title}`;
};
