import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Dropdown from "react-dropdown";

import "react-dropdown/style.css";

// Services
import { getLanguage, setLanguage } from "../../services/localStorageService";

// Components
import NavbarButton from "../buttons/navbarButton/NavbarButton";

// Constants
import LANGUAGE_OPTIONS from "./languageConstants";
import { getCurrentUserCart } from "../../util/functions/OrderFlowHelper";

// Images
import darkLogo from "../../assets/images/landingPage/navbar/logo_dark.png";
import cartIcon from "../../assets/images/landingPage/navbar/cart_icon.png";
import fullCart from "../../assets/images/landingPage/navbar/Oval.png";
import hamburgerMenu from "../../assets/images/landingPage/navbar/menu-right.png";
import closeIcon from "../../assets/images/landingPage/navbar/close-icon.png";

const Navbar = () => {
  const token = useSelector((state) => state.authReducer.token);
  const user = useSelector((state) => state.userReducer.user);
  const cart = useSelector((state) => state.localStorageReducer.cart);

  const userCart = getCurrentUserCart(user, cart);

  const [defaultLanguageOption, setDefaultLanguageOption] = useState();
  const [hideFullCart, setHideFullCart] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const navbarClassName = useMemo(() => {
    if (
      window.location.pathname.includes("/verify") ||
      window.location.pathname.includes("/successfullyAddedCard") ||
      window.location.pathname.includes("/orderError") ||
      window.location.pathname.includes("/orderSuccess") ||
      window.location.pathname.includes("/registerSuccess") ||
      window.location.pathname.includes("/registerError") ||
      window.location.pathname.includes("/successfullyRegisteredCard")
    ) {
      return "navbar_hidden";
    }

    return "navbar";
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setShowHeader(!showHeader);
  };

  const handleDropDownMenu = (e) => {
    setLanguage(e.value);
    i18n.changeLanguage(e.value);

    const index = LANGUAGE_OPTIONS.findIndex((o) => e.value === o.value);
    setDefaultLanguageOption(LANGUAGE_OPTIONS[index]);

    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/single-product-page" ||
      window.location.pathname === "/onboarding/question-two" ||
      window.location.pathname === "/onboarding/question-three" ||
      window.location.pathname.includes("/products-page")
    ) {
      window.location.reload();
    }
  };

  const handleClickCartButton = () => {
    if (!hideFullCart) {
      if (userCart.userID === 0) history.push("/guest-order-checkout");
      else history.push("/payment-method");
    }
  };

  useEffect(() => {
    const language = getLanguage();

    if (language) {
      const index = LANGUAGE_OPTIONS.findIndex((o) => language === o.value);
      setDefaultLanguageOption(LANGUAGE_OPTIONS[index]);
    } else {
      setDefaultLanguageOption(LANGUAGE_OPTIONS[0]);
    }
  }, []);

  useEffect(() => {
    userCart && userCart?.cart.length > 0
      ? setHideFullCart(false)
      : setHideFullCart(true);
  }, [userCart]);

  return (
    <div className={navbarClassName}>
      {showHeader && (
        <div className="navbar_header">
          <img className="navbar_logo" alt="Logo-dark" src={darkLogo} />
          <div className="navbar_buttons">
            <div className="navbar_links">
              <NavbarButton title={t("SHOP")} />
              <NavbarButton title={t("KNOWLEDGE")} />
              <NavbarButton title={t("COMMUNITY")} />

              {token && <NavbarButton title={t("PROFILE")} />}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="navbar_register">
                {token && <NavbarButton title={t("LOGOUT")} forLogout={true} />}
                {!token && (
                  <NavbarButton title={t("REGISTER")} forRegistration={true} />
                )}
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      alt="cart"
                      style={{ cursor: "pointer" }}
                      src={cartIcon}
                      className="navbar_icon"
                      onClick={handleClickCartButton}
                    />
                  </div>

                  <div style={{ marginLeft: "-22px", marginTop: "-11px" }}>
                    <span hidden={hideFullCart}>
                      <img alt="full cart" src={fullCart}></img>
                    </span>
                  </div>
                </div>

                <Dropdown
                  options={LANGUAGE_OPTIONS}
                  onChange={handleDropDownMenu}
                  value={defaultLanguageOption}
                />
                <img
                  className="navbar_hamburger"
                  src={hamburgerMenu}
                  onClick={toggleSidebar}
                  alt="menu"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="navbar__sidebar_position">
        <TransitionGroup component={null}>
          {showSidebar && (
            <CSSTransition timeout={500} classNames="sidebar">
              <div className="navbar__overlay">
                <div
                  className={
                    showSidebar ? "navbar__sidebar--active" : "navbar__sidebar"
                  }
                >
                  <div className="navbar__close_icon_container">
                    <img
                      className="navbar__close"
                      src={closeIcon}
                      onClick={toggleSidebar}
                      alt="close-menu"
                    />
                  </div>
                  <div className="navbar__sidebar_content">
                    <NavbarButton title={t("SHOP")} />
                    <NavbarButton title={t("KNOWLEDGE")} />
                    <NavbarButton title={t("COMMUNITY")} />
                    {token && (
                      <React.Fragment>
                        <NavbarButton title={t("PROFILE")} />
                        <NavbarButton title={t("LOGOUT")} forLogout={true} />
                      </React.Fragment>
                    )}
                    {!token && (
                      <NavbarButton
                        title={t("REGISTER")}
                        forRegistration={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default Navbar;
