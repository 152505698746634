import React from "react";
import { useTranslation } from "react-i18next";

const CashPayment = () => {
  const { t } = useTranslation();

  return (
    <div className="cash-payment">
      <input
        className="cash-payment_input-cash-payment"
        placeholder={t("CASH")}
        disabled
      />
    </div>
  );
};

export default CashPayment;
