import { useState, useEffect } from "react";

// Services
import { FETCH_DeliveryPrice } from "../services/orderService";

export const useGetDeliveryPrice = (orderLine, isProductDeliverable) => {
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  useEffect(async () => {
    if (isProductDeliverable && orderLine) {
      const deliveryPriceAPI = await FETCH_DeliveryPrice(orderLine);

      setDeliveryPrice(deliveryPriceAPI);
    }

    return () => setDeliveryPrice(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderLine]);

  return { deliveryPrice };
};
