import { call, put, takeEvery } from "@redux-saga/core/effects";

// Services
import { REGISTER_User } from "../../services/authService";
import { LOGIN_User } from "../../services/authService";
import { removeToken, setToken } from "../../services/localStorageService";

// Reducers
import {
  userRegistration,
  userLogin,
  userLogout,
} from "../reducers/authReducer";

const BASE = "cnf/saga/auth";

export const REGISTER_USER_SAGA = BASE + "/register";
export const LOGIN_USER_SAGA = BASE + "/login";
export const LOGOUT_USER_SAGA = BASE + "/logout";

export function* registerUser({ data, alert }) {
  console.log(`authSaga.js: saga: ${REGISTER_USER_SAGA}`);
  try {
    let user = yield call(REGISTER_User, data, alert);

    yield put(userRegistration(user));
  } catch (err) {
    console.log(`Error @ authSaga.js -> saga: ${REGISTER_USER_SAGA}`, err);
  }
}

export function* loginUser({ data, successCallback, alert }) {
  console.log(`authSaga.js: saga: ${LOGIN_USER_SAGA}`);
  try {
    const result = yield call(LOGIN_User, data, alert);

    yield call(setToken, result.token);

    successCallback();

    yield put(userLogin(result.token));
  } catch (err) {
    console.log(`Error @ authSaga.js -> saga: ${LOGIN_USER_SAGA}`, err);
  }
}

export function* logoutUser() {
  console.log(`authSaga.js: saga: ${LOGOUT_USER_SAGA}`);
  try {
    yield call(removeToken);

    yield put(userLogout());
  } catch (err) {
    console.log(`Error @ authSaga.js -> saga: ${LOGOUT_USER_SAGA}`, err);
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER_SAGA, registerUser);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER_SAGA, loginUser);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER_SAGA, logoutUser);
}
