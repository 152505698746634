import React from "react";

const PaymentButton = (props) => {
  const { image, text, onClick, clicked } = props;

  const className = clicked
    ? "payment-method-button-clicked"
    : "payment-method-button";

  return (
    <button className={className} type="text" onClick={onClick}>
      <div className="button-info">
        <img
          alt="Payment type"
          src={image}
          style={{
            marginRight: "20px",
          }}
        />
        <span className="check-button_text">{text}</span>
      </div>
    </button>
  );
};

export default PaymentButton;
