import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Services
import { FETCH_Articles } from "../../../services/productsService";

// Components
import Article from "../article/Article";
import PrimaryButton from "../../buttons/primaryButton/PrimaryButton";

const Knowledge = (props) => {
  const token = useSelector((state) => state.authReducer.token);

  const [articles, setArticles] = useState([]);
  const [randomArticles, setRandomArticles] = useState([]);

  const { knowledgePage, articleId } = props;

  const { t } = useTranslation();

  const history = useHistory();

  const title = knowledgePage
    ? t("YOU_MAY_WANT_TO_READ")
    : t("LANDING_PAGE_KNOWLEDGE");

  const handleOnArticleClick = (id) => {
    history.push("/knowledge/show-single/" + id);
  };

  const handleSignUp = () => {
    history.push("/register");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchArticles() {
      const articles = await FETCH_Articles();

      setArticles(articles);
    }

    fetchArticles();
    return () => setArticles([]);
  }, []);

  useEffect(() => {
    setRandomArticles(articles.sort(() => 0.5 - Math.random()).slice(0, 3));
  }, [articles, articleId]);

  return (
    <div className="knowledge">
      <div className="knowledge-padding-template">
        <div className="knowledge-subtitle">
          <h3 className="knowledge-heading">{title}</h3>
          <p className="knowledge-paragraph">
            {t("LANDING_PAGE_KNOWLEDGE_READ_DISCOVER_INVOLVED")}
          </p>
        </div>

        <div className="knowledge-image-container">
          {randomArticles.map((item) => (
            <Article
              onClick={() => handleOnArticleClick(item.id)}
              key={item.id}
              article={item}
            />
          ))}
        </div>
      </div>

      {!token && !knowledgePage && (
        <div className="knowledge-read-more">
          <h3 className="read-more-heading">{t("WANT_TO_READ_MORE")}</h3>

          <p className="read-more-subtitle">
            {t("WANT_TO_READ_MORE_SUBTITLE_PT1")}
          </p>

          <p className="read-more-subtitle">
            {t("WANT_TO_READ_MORE_SUBTITLE_PT2")}
          </p>

          <PrimaryButton
            title={t("SIGN_UP")}
            onClick={handleSignUp}
            size="medium"
          />
        </div>
      )}
    </div>
  );
};

export default Knowledge;
