export const getArrivalTimeString = (arrivalTime) => {
  const date = new Date(arrivalTime);

  return (
    date &&
    date.getHours().toString() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes().toString()
  );
};

export const newDateISOString = new Date().toISOString();
