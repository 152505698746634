import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useAlert } from "react-alert";

// Sagas
import { LOGIN_USER_SAGA } from "../../store/sagas/authSaga";

// Components
import InputField from "../../components/inputFields/InputField";
import PrimaryButton from "../../components/buttons/primaryButton/PrimaryButton";

// Constants
import {
  EMAIL_REGEX,
  handleEmptyField,
  handleValidField,
} from "../../util/constants/Validations";

const LoginPage = () => {
  const registeredUser = useSelector((state) => state.authReducer.user);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showEmptyFieldError, setEmptyFieldError] = useState(false);

  const regex = EMAIL_REGEX;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();

  const successCallback = () => {
    if (registeredUser?.id) history.push("/onboarding/question-one");
    else history.push("/");
  };

  const handleChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };

  const handleLogin = () => {
    localStorage.removeItem("cardData");
    localStorage.removeItem("transactionToken");
    localStorage.removeItem("lastFourDigits");
    localStorage.removeItem("cardType");
    dispatch({
      type: LOGIN_USER_SAGA,
      data: user,
      successCallback: successCallback,
      alert: alert,
    });
  };

  const handleDisableLogin = () => {
    if (!user.email || !user.password || !isValidEmail) return true;
    else return false;
  };

  return (
    <div className="login-page">
      <h2 className="register-title">{t("LOGIN")}</h2>
      <h3 className="register-subtitle">{t("LOGIN_SUBTITLE")}</h3>

      <div className="login-page_main-container">
        <div className="login-page_input-container">
          <InputField
            size="large"
            placeholder={t("EMAIL")}
            type="text"
            value={user?.email}
            onChange={(e) => handleChange("email", e.target.value)}
            onBlur={(e) =>
              handleValidField(
                e.target.value,
                setIsValidEmail,
                setShowEmailError,
                setEmptyFieldError,
                regex
              )
            }
          />

          {showEmailError && (
            <p className="input-field_email-error">{t("WRONG_EMAIL_FORMAT")}</p>
          )}

          {showEmptyFieldError && !user?.email && (
            <p className="input-field_email-error">
              {t("THIS_FIELD_CAN_NOT_BE_EMPTY")}
            </p>
          )}

          <InputField
            size="large"
            placeholder={t("PASSWORD")}
            type="password"
            value={user?.password}
            onChange={(e) => handleChange("password", e.target.value)}
            onBlur={(e) => handleEmptyField(e.target.value, setEmptyFieldError)}
          />

          {showEmptyFieldError && !user?.password && (
            <p className="input-field_email-error">
              {t("THIS_FIELD_CAN_NOT_BE_EMPTY")}
            </p>
          )}

          <PrimaryButton
            onClick={handleLogin}
            disabled={handleDisableLogin()}
            size="large"
            title={t("LOGIN")}
          />
        </div>

        <h4 className="register-text">
          {t("DONT_HAVE_ACCOUNT")}
          <a href="/register" className="login-link">
            {" "}
            <span className="register-link">{t("SIGN_UP")}</span>{" "}
          </a>
        </h4>
      </div>
    </div>
  );
};

export default LoginPage;
