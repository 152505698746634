import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Constants
import { addZeroes } from "../../../util/functions/AddZeroes";

const YouMightBeInterestedButton = (props) => {
  const { interestedItem } = props;

  const history = useHistory();

  const { t } = useTranslation();

  const goToSingleProductPage = () => {
    history.push({
      pathname: "/single-product-page",
      state: {
        product: interestedItem,
      },
    });
  };

  return (
    <button className="interested-item-button" onClick={goToSingleProductPage}>
      <div
        style={{
          backgroundImage: `url("${interestedItem.imageUrl}")`,
        }}
        className="interested-item-image-container"
      ></div>

      <p className="interested-item-name">{interestedItem.name}</p>
      <p className="interested-item-price">
        {addZeroes(interestedItem.sizes[0].price)} {t("DIN")}
      </p>
    </button>
  );
};

export default YouMightBeInterestedButton;
