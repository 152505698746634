import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// Constants
import { getDisplayText } from "./termsAndConditionsHelper";

const TermsAndConditionsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const privacyPolicy = location.state;

  return (
    <div className="terms-and-conditions-page">
      <p className="terms-and-conditions-page_title">
        {privacyPolicy
          ? t("PRIVACY_POLICY_TITLE")
          : t("TERMS_AND_CONDITIONS_TITLE")}
      </p>

      <div className="terms-and-conditions-page_text-container">
        {getDisplayText(privacyPolicy)}
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
