import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { t } from "i18next";

// Services
import { FETCH_Reviews } from "../../../services/reviewService";

// Components
import Article from "../article/Article";

const Community = () => {
  const [reviews, setReviews] = useState([]);
  const [randomReviews, setRandomReviews] = useState([]);

  const history = useHistory();

  const handleOnReviewClick = (id) => {
    history.push("community/show-single/" + id);
  };

  useEffect(() => {
    async function fetchReviews() {
      const reviews = await FETCH_Reviews();

      setReviews(reviews);
    }

    fetchReviews();
    return () => setReviews([]);
  }, []);

  useEffect(() => {
    setRandomReviews(reviews.sort(() => 0.5 - Math.random()).slice(0, 3));
  }, [reviews]);

  return (
    <div className="community">
      <div className="community-padding-template">
        <div className="community-subtitle">
          <h3 className="community-heading">{t("LANDING_PAGE_COMMUNITY")}</h3>
          <p className="community-paragraph">
            {t("LANDING_PAGE_COMMUNITY_SUBTITLE")}
          </p>
        </div>

        <div className="community-image-container">
          {randomReviews.map((item) => (
            <Article
              onClick={() => handleOnReviewClick(item.id)}
              starIcon={true}
              key={item.id}
              community={true}
              article={item}
            />
          ))}
        </div>
      </div>

      {/* subscribe component - later

        <div className="Community-subtitle">
            <h3 className="Community-heading">Subscribe</h3>
            <p className="Community-paragraph">Subscribe to our mail list and get the latest updates</p>
        </div>

        <div className="horizontal-line"></div>
       
        <PrimaryButton
        title="SUBSCRIBE" size="medium" />*/}
    </div>
  );
};

export default Community;
