import React from "react";

const ArticleSection = (props) => {
  const { section } = props;

  const renderSection = () => {
    if (!section?.imageUrl){
      return (<p className="section-text">{section.text}</p>)
    }

    if(section?.imageUrl.endsWith('.mp4')){
      return (<video
                className="section-image"
                controls
                autoPlay={false}  
              >
                <source 
                  src={section.imageUrl}
                  type='video/mp4'
                />
              </video>)
    }
    return (<img
              alt="section-img"
              className="section-image"
              src={`${section.imageUrl}`}
            />)
  }

  return (
    <div className="article-section-container">
      {renderSection()}
    </div>
  );
};

export default ArticleSection;
