export const orderHistoryIcon = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="35" fill="#E23036" />
    <path
      d="M35 29.1082V35.0001L37.946 37.946"
      stroke="white"
      strokeWidth="2.25"
    />
    <path
      d="M21.8164 39.4191V38.2941H20.6914V39.4191H21.8164ZM22.9356 33.6412C23.561 27.5017 28.6956 22.8101 34.8664 22.7396L34.8407 20.4897C27.5255 20.5733 21.4386 26.1351 20.6972 33.4132L22.9356 33.6412ZM34.8664 22.7396C41.0372 22.6691 46.2777 27.2423 47.0431 33.3658L49.2757 33.0868C48.3683 25.8275 42.156 20.4062 34.8407 20.4897L34.8664 22.7396ZM47.0431 33.3658C47.8086 39.4894 43.8551 45.2118 37.8568 46.6625L38.3857 48.8494C45.4964 47.1297 50.1831 40.346 49.2757 33.0868L47.0431 33.3658ZM37.8568 46.6625C31.8585 48.1131 25.727 44.8297 23.6096 39.0331L21.4962 39.8051C24.0063 46.6767 31.2749 50.5691 38.3857 48.8494L37.8568 46.6625ZM22.9414 46.784V39.4191H20.6914V46.784H22.9414ZM21.8164 40.5441H29.1813V38.2941H21.8164V40.5441Z"
      fill="white"
    />
  </svg>
);

export const myExperiencesIcon = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="35" fill="#E23036" />
    <path
      d="M46.5449 37.4339C47.0354 36.9481 47.0392 36.1566 46.5534 35.6661C46.0677 35.1756 45.2762 35.1718 44.7857 35.6576L46.5449 37.4339ZM34.618 47.487L33.7384 48.3752C34.2255 48.8576 35.0104 48.8576 35.4976 48.3752L34.618 47.487ZM24.4502 35.6576C23.9597 35.1718 23.1683 35.1756 22.6825 35.6661C22.1967 36.1566 22.2005 36.9481 22.691 37.4339L24.4502 35.6576ZM22.6989 37.4416C23.1936 37.923 23.985 37.9122 24.4665 37.4175C24.9479 36.9227 24.9371 36.1313 24.4424 35.6499L22.6989 37.4416ZM34.618 26.8741L33.6147 27.6198C33.8498 27.9361 34.2204 28.123 34.6145 28.1241C35.0086 28.1252 35.3802 27.9404 35.617 27.6254L34.618 26.8741ZM44.7894 35.6628C44.2969 36.1465 44.2898 36.938 44.7735 37.4305C45.2573 37.923 46.0487 37.9301 46.5412 37.4463L44.7894 35.6628ZM44.7857 35.6576L33.7384 46.5989L35.4976 48.3752L46.5449 37.4339L44.7857 35.6576ZM35.4976 46.5989L24.4502 35.6576L22.691 37.4339L33.7384 48.3752L35.4976 46.5989ZM24.4424 35.6499C22.7929 34.0448 22.1844 31.6496 22.8678 29.4519L20.4805 28.7096C19.5178 31.8058 20.3751 35.1803 22.6989 37.4416L24.4424 35.6499ZM22.8678 29.4519C23.5511 27.2542 25.4105 25.6263 27.6793 25.2395L27.2592 22.7751C24.0629 23.3199 21.4432 25.6134 20.4805 28.7096L22.8678 29.4519ZM27.6793 25.2395C29.9481 24.8527 32.2418 25.7726 33.6147 27.6198L35.6212 26.1285C33.687 23.5261 30.4555 22.2302 27.2592 22.7751L27.6793 25.2395ZM35.617 27.6254C36.9957 25.7919 39.2845 24.8842 41.5451 25.2746L41.9705 22.811C38.7858 22.2611 35.5612 23.5398 33.6189 26.1229L35.617 27.6254ZM41.5451 25.2746C43.8056 25.665 45.6575 27.2877 46.3413 29.4774L48.7277 28.7321C47.7642 25.6472 45.1552 23.361 41.9705 22.811L41.5451 25.2746ZM46.3413 29.4774C47.0252 31.6671 46.426 34.0553 44.7894 35.6628L46.5412 37.4463C48.8469 35.1817 49.6911 31.817 48.7277 28.7321L46.3413 29.4774Z"
      fill="white"
    />
  </svg>
);

export const myArticlesIcon = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="35" fill="#E23036" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8627 48.6645C24.8455 48.6645 22.3784 46.3091 22.1998 43.3368L22.1895 42.9912V24.8367C22.1895 23.4659 23.2313 22.3385 24.5663 22.2029L24.837 22.1892H39.9657C41.3365 22.1892 42.464 23.231 42.5996 24.566L42.6132 24.8367L42.6125 25.2129L46.0172 25.215C47.388 25.215 48.5155 26.2568 48.6511 27.5918L48.6647 27.8625V44.5041C48.6647 46.6974 46.9675 48.4942 44.8148 48.6531L44.5043 48.6645H27.8627ZM40.0853 24.4778L39.9657 24.4585H24.837C24.6699 24.4585 24.5281 24.5669 24.478 24.7172L24.4588 24.8367V42.9912C24.4588 44.7722 25.8266 46.2338 27.569 46.3827L27.8627 46.3952L40.7992 46.3938L40.7306 46.258C40.5238 45.8139 40.3932 45.3271 40.3553 44.8146L40.3439 44.5041V24.8367C40.3439 24.6696 40.2356 24.5278 40.0853 24.4778ZM42.6132 44.5041C42.6132 45.5485 43.4599 46.3952 44.5043 46.3952C45.4741 46.3952 46.2735 45.6651 46.3827 44.7246L46.3954 44.5041V27.8625C46.3954 27.6954 46.287 27.5536 46.1367 27.5035L46.0172 27.4843L42.6125 27.4822L42.6132 44.5041Z"
      fill="#F5F5F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.4264 28.2407C36.053 28.2407 36.561 28.7487 36.561 29.3754C36.561 29.9394 36.1496 30.4072 35.6104 30.4952L35.4264 30.51H29.3749C28.7482 30.51 28.2402 30.002 28.2402 29.3754C28.2402 28.8114 28.6517 28.3435 29.1908 28.2556L29.3749 28.2407H35.4264Z"
      fill="#F5F5F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.4264 34.2922C36.053 34.2922 36.561 34.8002 36.561 35.4269C36.561 35.9909 36.1496 36.4588 35.6104 36.5467L35.4264 36.5615H29.3749C28.7482 36.5615 28.2402 36.0535 28.2402 35.4269C28.2402 34.8629 28.6517 34.395 29.1908 34.3071L29.3749 34.2922H35.4264Z"
      fill="#F5F5F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.4264 40.3438C36.053 40.3438 36.561 40.8518 36.561 41.4784C36.561 42.0424 36.1496 42.5103 35.6104 42.5982L35.4264 42.6131H29.3749C28.7482 42.6131 28.2402 42.1051 28.2402 41.4784C28.2402 40.9144 28.6517 40.4465 29.1908 40.3586L29.3749 40.3438H35.4264Z"
      fill="#F5F5F5"
    />
  </svg>
);

export const myCreditCardsIcon = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="35" fill="#E23036" />
    <g transform="translate(20, 25)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 20H3C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0H25C26.6569 0 28 1.34315 28 3V17C28 18.6569 26.6569 20 25 20ZM3 2C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H25C25.5523 18 26 17.5523 26 17V3C26 2.44772 25.5523 2 25 2H3Z"
        fill="white"
      />
      <rect x="1" y="6" width="26" height="2" fill="#F5F5F5" />
      <rect x="17" y="13" width="6" height="2" fill="#F5F5F5" />
    </g>
  </svg>
);
