import i18next from "i18next";

// Constants
import translation from "../../util/functions/TranslationFunctions";

const TYPE_OF_ORDERS = [
  {
    id: 0,
    text: i18next.t("ORDER_TO_GO"),
  },
  {
    id: 1,
    text: i18next.t("ORDER_AHEAD"),
  },
  {
    id: 2,
    text: i18next.t("POST_EXPRESS_DELIVERY"),
  },
];

const updateTranslations = () => {
  TYPE_OF_ORDERS[0].text = i18next.t("ORDER_TO_GO");
  TYPE_OF_ORDERS[1].text = i18next.t("ORDER_AHEAD");
  TYPE_OF_ORDERS[2].text = i18next.t("POST_EXPRESS_DELIVERY");
};

translation(updateTranslations);

export default TYPE_OF_ORDERS;
