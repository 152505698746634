import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

// Store
import {
  FETCH_ACTIVE_ORDERS_SAGA,
  UPDATE_ACTIVE_ORDERS_SAGA,
} from "../../store/sagas/localStorageSaga";
import {
  FETCH_CONNECTIONS_SAGA,
  UPDATE_CONNECTIONS_SAGA,
} from "../../store/sagas/webSocketSaga";

// Hooks
import { usePaymentOption } from "../../hooks/usePaymentOption";

// Components
import CardPayment from "../../components/orderFlow/cardPayment/CardPayment";
import CashPayment from "../../components/orderFlow/cashPayment/CashPayment";
import CheckboxButtonContainer from "../../components/checkboxButtonContainer/CheckboxButtonContainer";

// Constants
import { handlePay } from "../../util/functions/OrderCheckoutFunctions";

// Images
import pressedCheckButton from "../../assets/images/orderFlow/pressed_check_button.png";
import emptyCheckButton from "../../assets/images/orderFlow/empty_check_button.png";
import AdditionalOrderInformation from "../../components/orderFlow/additionalOrderInformation/AdditionalOrderInformation";

const ConfirmPaymentPage = () => {
  const user = useSelector((state) => state.userReducer.user);
  const token = useSelector((state) => state.authReducer.token);
  const activeOrders = useSelector(
    (state) => state.localStorageReducer.activeOrders
  );
  const connections = useSelector(
    (state) => state.webSocketReducer.connections
  );
  const cart = useSelector((state) => state.localStorageReducer.cart);

  const [creditCard, setCreditCard] = useState();
  const [checkButtonClicked, setCheckButtonClicked] = useState(false);
  const [additionalBilingData, setAdditionalBilingData] = useState({
    firstName: '',
    lastName: '',
    billingAddress: '',
    billingCity: '',
    billingCountry: 'RS',
  });

  const location = useLocation();
  const history = useHistory();
  const reactAlert = useAlert();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { paymentOption } = usePaymentOption(creditCard);

  const checkButtonSource = checkButtonClicked
    ? pressedCheckButton
    : emptyCheckButton;

  const handleCheckButtonPress = () => {
    setCheckButtonClicked(!checkButtonClicked);
  };

  const updateActiveOrders = (activeOrders) => {
    dispatch({ type: UPDATE_ACTIVE_ORDERS_SAGA, activeOrders: activeOrders });
  };

  const updateConnections = (connections) => {
    dispatch({ type: UPDATE_CONNECTIONS_SAGA, data: connections });
  };

  const handlePayButton = () => {
    handlePay(
      user,
      creditCard,
      activeOrders,
      cart,
      token,
      updateActiveOrders,
      dispatch,
      connections,
      updateConnections,
      paymentOption,
      reactAlert,
      history,
      location,
      checkButtonClicked,
      additionalBilingData
    );
  };

  const handleBillingDataChange = (fieldName, value) => {
    setAdditionalBilingData({
      ...additionalBilingData,
      [fieldName]: value,
    });
  }

  useEffect(() => {
    if (activeOrders && !activeOrders.length > 0) {
      dispatch({ type: FETCH_ACTIVE_ORDERS_SAGA });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connections && !connections.length > 0) {
      dispatch({ type: FETCH_CONNECTIONS_SAGA });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="confirm-payment-page">
      <p style={{ textAlign: "center" }} className="register-title">
        {t("CONFIRM_ORDER_TITLE")}
      </p>

      {!location?.state?.cash && (
        <div className="confirm-payment-page_container">
          <div className="confirm-payment-page_container-card">
            <CardPayment
              card={creditCard}
              handleAddCard={() => history.push("/add-card")}
              setCreditCard={setCreditCard}
            />
          </div>

          <AdditionalOrderInformation
            additionalOrderInformation={additionalBilingData}
            updateBillingData={handleBillingDataChange}
          />
        </div>
      )}

      {location?.state?.cash && <CashPayment />}

      <CheckboxButtonContainer
        checkButtonSource={checkButtonSource}
        handleCheckButtonPress={handleCheckButtonPress}
        orderFlow
      />

      <p className="confirm-payment-page_text">{t("CAFE_FACTORY_MERCHANT")}</p>

      <div className="confirm-payment-page_button">
        <button className="primary-button-large" onClick={handlePayButton}>
          {t("PAY")}
        </button>
      </div>
    </div>
  );
};

export default ConfirmPaymentPage;
