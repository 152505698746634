export const getTopContainerImageStyle = (review) => {
  if (review.imageUrls.length !== 0) {
    return {
      backgroundImage: `url(${review.imageUrls[0]})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "9px",
    };
  }

  return {
    background: "white",
    border: "1px solid black",
  };
};

const getImageSectionImageStyle = (url) => {
  return {
    backgroundImage: `url(${url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "307px",
    borderRadius: "15px",
  };
};

export const renderImages = (review) => {
  if (review) {
    if (review.imageUrls.length > 1) {
      return review.imageUrls.map((element, index) => {
        if (index >= 1) {
          return (
            <div className="image-container">
              <div style={getImageSectionImageStyle(element)} />
            </div>
          );
        }

        return <></>;
      });
    }
  }
};
