import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

// Services
import { VERIFY_User } from "../../services/userService";

// Images
import verifyMail from "../../assets/images/verifyMail/verify-mail.png";

const VerifyMailPage = () => {
  const { id } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    VERIFY_User(id).then((e) => {
      console.log("verified " + id);
    });
  }, [id]);

  return (
    <div className="verify-mail">
      <h2 className="verify-mail_title">{t("THANK_YOU")}</h2>
      <img
        className="verify-mail_image"
        alt="Pressed check button"
        src={verifyMail}
      />
      <h3 className="verify-mail_title">{t("SUCCESSFULLY_VERIFIED")}</h3>
    </div>
  );
};

export default VerifyMailPage;
