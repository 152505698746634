import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Store
import { UPDATE_CART_SAGA } from "../../../store/sagas/localStorageSaga";

// Services
import { getCurrentUserIndex } from "../../../util/functions/OrderFlowHelper";

// Constants
import { addZeroes } from "../../../util/functions/AddZeroes";

// Images
import deleteIcon from "../../../assets/images/singleProductPage/delete_button.png";

const OrderInfoText = (props) => {
  const user = useSelector((state) => state.userReducer.user);
  const cart = useSelector((state) => state.localStorageReducer.cart);

  const { product, totalPrice, guestOrderCheckout, deliveryPrice } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const text = useMemo(() => {
    if (product) {
      return `${product.amount} x ${product.productName}`;
    } else if (totalPrice) {
      return t("TOTAL_PRICE");
    } else {
      return t("DELIVERY_PRICE");
    }
  }, [product, totalPrice, t]);

  const price = useMemo(() => {
    if (product) {
      return product.price * product.amount;
    } else if (totalPrice) {
      return totalPrice;
    } else {
      return deliveryPrice;
    }
  }, [product, totalPrice, deliveryPrice]);

  const totalPriceClassName = guestOrderCheckout
    ? undefined
    : "total-price-text";

  const displayDivider = product || deliveryPrice === 0 || deliveryPrice;

  const handleDeleteItem = (product) => {
    let copyCart = [...cart];

    let currentUserIndex = getCurrentUserIndex(user, copyCart);
    let currentUserCart = copyCart[currentUserIndex].cart;

    let cartProductIndex = currentUserCart.findIndex(
      (item) => item.productId === product.productId
    );

    currentUserCart.splice(cartProductIndex, 1);

    dispatch({ type: UPDATE_CART_SAGA, cart: copyCart });

    currentUserCart.length === 0 && history.push("/");
  };

  return (
    <div>
      {product && (
        <div className="order-info-text">
          <p className="order-info-text_text">{text}</p>
          <div className="delete-icon-container">
            <p>
              {addZeroes(price)} {t("DIN")}
            </p>
            {!guestOrderCheckout && (
              <button
                className="delete-btn"
                onClick={() => handleDeleteItem(product)}
              >
                <img
                  className="delete-icon"
                  alt="delete-icon"
                  src={deleteIcon}
                />
              </button>
            )}
          </div>
        </div>
      )}

      {!product && (
        <div className="order-info-text">
          <p className="order-info-text_text">{text}</p>
          <p className={totalPriceClassName}>
            {addZeroes(price)} {t("DIN")}
          </p>
        </div>
      )}

      {displayDivider && <hr style={{ width: "90%" }} />}
    </div>
  );
};

export default OrderInfoText;
