export const BASE = "cnf/reducer/auth";

export const REGISTER_USER = BASE + "/register";
export const LOGIN_USER = BASE + "/login";
export const LOG_IN_ERROR = BASE + "/login-error";
export const LOGOUT_USER = BASE + "/userLogout";

const initialState = {
  user: {},
  token: null,
  signInError: "",
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return { ...state, user: action.payload };
    case LOGIN_USER:
      return { ...state, token: action.payload };
    case LOG_IN_ERROR:
      return {
        ...state,
        token: "",
        signInError: action.payload,
      };
    case LOGOUT_USER:
      return { ...state, token: "", user: {} };
    default:
      return state;
  }
};

export const signInError = (value) => ({
  type: LOG_IN_ERROR,
  payload: value,
});

export const userRegistration = (payload) => {
  return {
    type: REGISTER_USER,
    payload,
  };
};

export const userLogin = (payload) => {
  return {
    type: LOGIN_USER,
    payload,
  };
};

export const userLogout = () => {
  return {
    type: LOGOUT_USER,
  };
};
