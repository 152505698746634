import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactFileReader from "react-file-reader";

// Store
import { GET_USER_BY_TOKEN } from "../../store/sagas/userSaga";

// Services
import { PUT_UserCoverImage } from "../../services/userService";

// Components
import ReadMoreButton from "../../components/buttons/readMoreButton/ReadMoreButton";
import FloatingUser from "../../components/userProfilePage/floatingUser/FloatingUser";
import ProgressOverview from "../../components/userProfilePage/progressOverview/ProgressOverview";
import Dashboard from "../../components/userProfilePage/dashboard/Dashboard";

// Images
import freshmanBadge from "../../assets/images/profile/freshman_badge.png";
import enthusiastBadge from "../../assets/images/profile/enthusiast_badge.png";
import ambassadorBadge from "../../assets/images/profile/ambassador_badge.png";

const UserProfilePage = () => {
  const token = useSelector((state) => state.authReducer.token);
  const reducerUser = useSelector((state) => state.userReducer.user);

  const [coverImageChange, setCoverImageChange] = useState(false);
  const [profileImageChange, setProfileImageChange] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const coverImageStyle = {
    backgroundImage: `url(${reducerUser?.titleImageUrl})`,
  };

  const handleSetProfileImageChange = (option) => {
    setProfileImageChange(option);
  };

  const handleFiles = (files) => {
    (async function UpdateCoverPhoto() {
      const formData = new FormData();

      formData.append("Image", files[0], files[0].name);

      await PUT_UserCoverImage(formData, token);
      setCoverImageChange(!coverImageChange);
    })();
  };

  useEffect(() => {
    dispatch({ type: GET_USER_BY_TOKEN, data: token });
  }, [token, dispatch, coverImageChange, profileImageChange]);

  return (
    <main className="profile-page-container">
      {reducerUser && (
        <>
          <div style={coverImageStyle} className="cover-container">
            <ReactFileReader handleFiles={handleFiles}>
              <ReadMoreButton text={t("CHANGE_COVER")} coverImage={true} />
            </ReactFileReader>
          </div>

          <div className="user-level-icons-container">
            <img
              src={freshmanBadge}
              className="user-level-icons-container_img"
              alt="freshman"
            />
            <img
              src={enthusiastBadge}
              className="user-level-icons-container_img"
              alt="enthusiast"
            />
            <img
              src={ambassadorBadge}
              className="user-level-icons-container_img"
              alt="ambassador"
            />
          </div>

          <div>
            <FloatingUser
              handleSetProfileImageChange={handleSetProfileImageChange}
              profileImageChange={profileImageChange}
              reducerUser={reducerUser}
            />
          </div>

          <div className="profile-data">
            <div className="progress-overview-section">
              <span className="profile-page-titles-font">
                {t("PROGRESS_OVERVIEW")}
              </span>

              <ProgressOverview
                coffeeBeansAmount={reducerUser?.coffeebeanAmount}
              />
            </div>

            <div className="dashboard-section">
              <span className="profile-page-titles-font">{t("DASHBOARD")}</span>

              <Dashboard />
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default UserProfilePage;
