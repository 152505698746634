export const BASE = "cnf/reducer/webSocket";

export const FETCH_CONNECTIONS = BASE + "/fetchConnections";
export const UPDATE_CONNECTIONS = BASE + "/updateConnections";

const initialState = {
  connections: [],
};

export const webSocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONNECTIONS:
      return { ...state, connections: state.connections };
    case UPDATE_CONNECTIONS:
      return { ...state, connections: action.payload };
    default:
      return state;
  }
};

export const fetchConnectionsFromReducer = () => {
  return {
    type: FETCH_CONNECTIONS,
  };
};

export const setUpdatedConnectionsToReducer = (payload) => {
  return {
    type: UPDATE_CONNECTIONS,
    payload,
  };
};
