import React from "react";

const PopularChoiceButton = (props) => {
  const { popularChoice, onClick } = props;

  return (
    <button className="popular-choice-button" onClick={onClick}>
      <div
        style={{
          backgroundImage: `url("${popularChoice.imageUrl}")`,
          cursor: "pointer",
        }}
        className="popular-choice-button_image-background"
      ></div>
      <div className="popular-choice-button_text-container">
        <h2 className="popular-choice-button_name">{popularChoice.name}</h2>
      </div>
    </button>
  );
};

export default PopularChoiceButton;
