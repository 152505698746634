import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Services
import { FETCH_Data } from "../../../services/landingPageContentService";

// Components
import LoadingIndicator from "../../loadingIndicator/LoadingIndicator";

const HeaderBackground = () => {
  const [data, setData] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      const data = await FETCH_Data();

      setData(data);
    }

    fetchData();
    return () => setData("");
  }, []);

  const style = {
    backgroundImage: data
      ? `url(${data.image})`
      : 'url("../../../assets/images/landingPage/header_image.png")',
  };

  const handleClick = () => {
    window.scrollTo(0, document.body.scrollHeight / 3);
  };

  return (
    <div className="header-background" style={style}>
      {!data && <LoadingIndicator />}
      {data && (
        <div className="header-background_text-container">
          <h1 className="header-background_title">{t("WELCOME_TO_CNF")}</h1>
          <h3 className="header-background_text">
            {t("WELCOME_TO_CNF_SUBTITLE")}
          </h3>
          <div className="header-background_button-container">
            <button onClick={handleClick} className="primary-button-medium">
              {t("ORDER_NOW")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderBackground;
