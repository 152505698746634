import React from "react";
import { useTranslation } from "react-i18next";

// Constants
import {
  getIconFromLevelText,
  getNextLevelTextFromCoffeeBeans,
  getPointsLeftForNextLevel,
} from "../../../util/functions/CoffeeBeansHelper";

const NextLevelIcon = (props) => {
  const { coffeeBeansAmount } = props;
  const { t } = useTranslation();

  return (
    <div className="next-level-icon-container">
      <div className="icons">
        <svg
          width="118"
          height="118"
          viewBox="0 0 118 118"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="59" cy="59" r="59" fill="#3F3F3F" />
        </svg>

        {getIconFromLevelText(
          getNextLevelTextFromCoffeeBeans(coffeeBeansAmount)
        )}
      </div>

      <div className="text-container">
        <span>
          {t("COLLECT")} {getPointsLeftForNextLevel(coffeeBeansAmount)}{" "}
          {t("MORE_AND_UNLOCK")}{" "}
          {getNextLevelTextFromCoffeeBeans(coffeeBeansAmount)} {t("TROPHY")}
        </span>
      </div>
    </div>
  );
};

export default NextLevelIcon;
