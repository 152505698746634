import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Constants
import { printFlavoursAndTypesList } from "../../util/functions/PrintFlavoursAndTypesList";
import TYPE_OF_ORDERS from "./typeOfOrdersConstants";
import { cupIcon, flavourIcon } from "../../util/constants/Icons";

// Images
import logoSmall from "../../assets/images/communityPage/coffee-factory-logo-small.png";

const OrderDetailsContainer = (props) => {
  const [typeOfOrder, setTypeOfOrder] = useState();

  const { review } = props;

  const { t } = useTranslation();

  useEffect(() => {
    setTypeOfOrder(
      TYPE_OF_ORDERS.find((o) => o.id === review.typeOfOrder).text
    );
  }, [review.typeOfOrder]);

  return (
    <div className="order-details-container">
      <span className="order-details-title-font">
        {t("ORDER_DETAILS_COMMUNITY")}
      </span>

      <div className="details-container">
        <img alt="logo-small" className="logo-small" src={logoSmall} />

        <div className="details-text-container">
          <span className="details-factory-name-font">
            {review.shopLocationName}
          </span>

          <span className="details-order-option-font">{typeOfOrder}</span>
        </div>
      </div>

      <div className="details-products-container">
        {cupIcon}
        <span className="product-span">
          <span className="review-names-flavours-font">
            {printFlavoursAndTypesList(review.productNames)}
          </span>
        </span>
      </div>

      <div className="details-products-container">
        {flavourIcon}
        <span className="flavour-span">
          <span className="review-names-flavours-font">
            {printFlavoursAndTypesList(review.flavourProfiles)}
          </span>
        </span>
      </div>
    </div>
  );
};

export default OrderDetailsContainer;
