import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Services
import { FETCH_Categories } from "../../../services/productsService";

// Constants
import CategoryButton from "../../buttons/categoryButton/CategoryButton";

const AllCategories = () => {
  const [allCategories, setAllCategories] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetchCategories() {
      const allCategories = await FETCH_Categories();

      setAllCategories(allCategories);
    }

    fetchCategories();
    return () => setAllCategories([]);
  }, []);

  return (
    <div className="allCategories">
      <h3 id="section1" className="allCategories_subtitle">
        {t("ALL_CATEGORIES")}
      </h3>
      <div className="allCategories_wrapper">
        {allCategories.map((item) => (
          <CategoryButton key={item.id} category={item} />
        ))}
      </div>
    </div>
  );
};

export default AllCategories;
