import API from "../config/api/api";

const ENDPOINTS = {
    FETCH_DATA: '/landingpagecontent/getcontent'
};

const getLanguage = () => {
  if(localStorage.getItem('language') === 'sr') return 0;
  return 1; 
}

export const FETCH_Data = async () => {
    try{
        const response = await API.get(`${ENDPOINTS.FETCH_DATA}`, {headers: {
          Language: getLanguage(),
        }});

        return response.data;
    } catch (err) {
        console.log('Error @ landingPageContentService.js: FETCH_Data ->', err);
    }
}