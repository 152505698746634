import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import countryList from 'react-select-country-list'

const AdditionalOrderInformation = ({ additionalOrderInformation, updateBillingData }) => {
    const { t } = useTranslation();

    const options = useMemo(() =>
        countryList().getData(),
        []);

    return (
        <div className="additional-order-information-container">
            <div className="additional-order-information-container_title-container">
                <p className="card-info-title">{t("ADDITIONAL_BILLING_INFORMATION")}</p>
            </div>

            <div className="additional-order-information-container_input-container">
                <input
                    className="input-payment-card_city-input"
                    style={{ marginRight: 10 }}
                    type="text"
                    placeholder={t("FIRST_NAME")}
                    value={additionalOrderInformation?.firstName}
                    onChange={(e) => updateBillingData("firstName", e.target.value)}
                />
                <input
                    style={{ marginRight: 0 }}
                    className="input-payment-card_city-input"
                    type="text"
                    placeholder={t("LAST_NAME")}
                    value={additionalOrderInformation?.lastName}
                    onChange={(e) => updateBillingData("lastName", e.target.value)}
                />
            </div>

            <input
                className="input-payment-card"
                type="text"
                placeholder={t("BILLING_ADDRESS")}
                value={additionalOrderInformation?.billingAddress}
                onChange={(e) => updateBillingData("billingAddress", e.target.value)}
            />

            <div className="additional-order-information-container_input-container">
                <input
                    className="input-payment-card_city-input"
                    style={{ marginRight: 10 }}
                    type="text"
                    placeholder={t("BILLING_CITY")}
                    value={additionalOrderInformation?.billingCity}
                    onChange={(e) => updateBillingData("billingCity", e.target.value)}
                />
                <div className="input-payment-card_city-input" style={{ marginRight: 0, cursor: 'pointer' }} >
                    <Select
                        options={options}
                        placeholder={additionalOrderInformation?.billingCountry}
                        onChange={(value) => updateBillingData("billingCountry", value.value)}
                        value={additionalOrderInformation?.billingCountry}
                    />
                </div>
            </div>
        </div>
    );
}

export default AdditionalOrderInformation;