import React from "react";

const TitleWithDescription = (props) => {
  const {
    title = "",
    subtitle = "",
    titleAdditionalClass = "",
    subtitleAdditionalClass = "",
    singleReviewPage,
  } = props;

  const containerClassName = singleReviewPage
    ? "title-with-description-container-community-page"
    : "title-with-description-container";

  return (
    <div className={containerClassName}>
      <h1 className={`title-with-description-title ${titleAdditionalClass}`}>
        {title}
      </h1>
      <h3
        className={`title-with-description-subtitle ${subtitleAdditionalClass}`}
      >
        {subtitle}
      </h3>
    </div>
  );
};

export default TitleWithDescription;
