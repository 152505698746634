import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// Service
import { FETCH_All_Shoplocations } from "../../../services/shoplocationService";

// Images
import SearchIcon from "../../../assets/images/singleProductPage/search.png";

const ShopLocationModal = (props) => {
  const [shopLocations, setShopLocations] = useState([]);
  const [searchText, setSearchText] = useState("");

  const {
    showModal,
    handleShowModal,
    handleOrderChange,
    handleShopLocationChange,
  } = props;

  const { t } = useTranslation();

  const factories = useMemo(() => {
    if (searchText) {
      let foundLocations = [];

      shopLocations.forEach((l) => {
        if (l.name.includes(searchText) || l.address.includes(searchText)) {
          foundLocations.push(l);
        }
      });

      return foundLocations;
    } else {
      return shopLocations;
    }
  }, [searchText, shopLocations]);

  const onCafeteriaButtonClick = (shopLocation) => {
    handleShopLocationChange(shopLocation);
    handleOrderChange("ShopLocationId", shopLocation.id);
    handleShowModal(undefined);
  };

  const handleSearchChange = (e) => {
    setSearchText(e);
  };

  useEffect(() => {
    let modal = document.getElementById("shop-location-modal");
    if (showModal) {
      modal.style.display = "block";
    } else {
      modal.style.display = "none";
    }
  }, [showModal]);

  useEffect(() => {
    (async function FetchShopLocations() {
      const data = await FETCH_All_Shoplocations();

      setShopLocations(data);
    })();
  }, []);

  return (
    <div className="modal-container">
      <div id="shop-location-modal">
        <div className="shop-location-modal-container">
          <div className="shop-location-modal-content">
            <img
              style={{ marginLeft: 20, marginBottom: -45 }}
              src={SearchIcon}
              alt="search-icon"
            ></img>

            <input
              className="shop-location-modal-description"
              placeholder={t("CHOOSE_YOUR_CNF_LOCAL")}
              type="text"
              value={searchText}
              onChange={(e) => handleSearchChange(e.target.value)}
            />

            {factories?.length > 0 &&
              factories?.map((e) => (
                <button
                  key={e.id}
                  onClick={() => onCafeteriaButtonClick(e)}
                  className="shop-location-modal-option"
                >
                  {e.name}, {e.address}
                </button>
              ))}
          </div>

          <div className="location-confirm-button-container">
            <button
              onClick={() => handleShowModal(undefined)}
              className="shop-location-time-confirm-button"
            >
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopLocationModal;
