export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const getLanguage = () => {
  return localStorage.getItem("language");
};

export const setLanguage = (language) => {
  localStorage.setItem("language", language);
};

export const getCart = () => {
  return JSON.parse(localStorage.getItem("cart"));
};

export const setCart = (cart) => {
  localStorage.setItem("cart", JSON.stringify(cart));

  return getCart();
};

export const removeCart = () => {
  localStorage.removeItem("cart");
};

export const getActiveOrders = () => {
  return JSON.parse(localStorage.getItem("activeOrders"));
};

export const setActiveOrders = (activeOrders) => {
  localStorage.setItem("activeOrders", JSON.stringify(activeOrders));

  return getActiveOrders();
};
