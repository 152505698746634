import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Services
import { FETCH_Categories } from "../../../services/productsService";

// Components
import OrderFastButton from "../../buttons/orderFastButton/OrderFastButton";

const OrderFast = () => {
  const [categories, setCategories] = useState([]);
  const [randomCategories, setRandomCategories] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetchCategories() {
      const categories = await FETCH_Categories();

      setCategories(categories);
    }

    fetchCategories();
    return () => setCategories([]);
  }, []);

  useEffect(() => {
    setRandomCategories(categories.sort(() => 0.5 - Math.random()).slice(0, 4));
  }, [categories]);

  return (
    <div>
      <h3 className="order-fast-subtitle">{t("ORDER_FAST")}</h3>
      <div className="order-fast-category">
        {randomCategories.map((category) => (
          <OrderFastButton key={category.id} category={category} />
        ))}
      </div>
    </div>
  );
};

export default OrderFast;
