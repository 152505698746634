import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Services
import {
  FETCH_Coffee_flavours,
  FETCH_Coffee_types,
} from "../../../services/productsService";
import { ADD_Onboarding_questions } from "../../../services/productsService";

// Components
import PrimaryButton from "../../../components/buttons/primaryButton/PrimaryButton";
import OnboardCheckboxButton from "../../../components/buttons/onboardCheckboxButton/OnboardCheckboxButton";

const CheckboxQuestion = (props) => {
  const token = useSelector((state) => state.authReducer.token);
  const user = useSelector((state) => state.userReducer.user);

  const [resultSet, setResultSet] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState([]);

  const { isFlavour } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const title = useMemo(() => {
    return isFlavour
      ? t("WHAT_ABOUT_THE_FLAVOURS")
      : t("WHAT_ABOUT_THE_COFFEE_TYPES");
  }, [isFlavour, t]);

  const subtitle = useMemo(() => {
    return isFlavour ? t("CHOOSE_FLAVOURS") : t("CHOOSE_TYPE_OF_COFFEE");
  }, [isFlavour, t]);

  const answerData = {
    type: isFlavour ? 1 : 2,
    userID: user?.id,
    data: resultSet,
  };

  const handleButtonClick = () => {
    ADD_Onboarding_questions(token, answerData);

    isFlavour ? history.push("/onboarding/question-three") : history.push("/");
  };

  const handleSkipQuestion = () => {
    history.push("/");
  };

  useEffect(() => {
    if (isFlavour) {
      async function fetchCoffeeFlavours() {
        const coffeeFlavours = await FETCH_Coffee_flavours(token);

        setCheckboxOptions(coffeeFlavours);
      }

      fetchCoffeeFlavours();
      return () => setCheckboxOptions([]);
    } else {
      async function fetchCoffeeTypes() {
        const coffeeTypes = await FETCH_Coffee_types(token);

        setCheckboxOptions(coffeeTypes);
      }

      fetchCoffeeTypes();
      return () => setCheckboxOptions([]);
    }
  }, [isFlavour, token]);

  return (
    <div className="onboarding-question">
      <div className="onboarding-question-heading">
        <h3 className="onboarding-question-title">{title}</h3>
        <p className="onboarding-question-subtitle">{subtitle}</p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="onboarding-coffee-flavours-container">
          {checkboxOptions.map((flavour) => {
            return (
              <OnboardCheckboxButton
                key={flavour.id}
                flavour={flavour}
                setResultSet={setResultSet}
                resultSet={resultSet}
              />
            );
          })}
        </div>
      </div>

      <PrimaryButton
        onClick={handleButtonClick}
        title={t("NEXT")}
        size="medium"
      />

      <button onClick={handleSkipQuestion} className="skip-button">
        {t("LATER")}
      </button>
    </div>
  );
};

export default CheckboxQuestion;
