// Images
import serbianFlag from "../../assets/images/landingPage/navbar/serbian_flag.png";
import engFlag from "../../assets/images/landingPage/navbar/eng_flag.png";

const LANGUAGE_OPTIONS = [
  {
    value: "sr",
    label: (
      <img alt="serbian-flag" className="dropdown-image" src={serbianFlag} />
    ),
  },
  {
    value: "en",
    label: <img alt="english-flag" className="dropdown-image" src={engFlag} />,
  },
];

export default LANGUAGE_OPTIONS;
