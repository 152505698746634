import API from "../config/api/api";

const ENDPOINTS ={
    FETCH_ARTICLES: '/article/getall',
    FETCH_SINGLE: '/article/getarticlewithsectionsbyid',
    FETCH_MY_ARTICLES: '/article/getbyuserid'
};

const getLanguage = () => {
  if(localStorage.getItem('language') === 'sr') return 0;
  return 1; 
}

export const FETCH_SingleArticle = async (id) => {
    try{
        const response = await API.get(`${ENDPOINTS.FETCH_SINGLE}/${id}`, {headers: {
          Language: getLanguage(),
        }});

        return response.data;
    } catch (err) {
        console.log('Error @ articleService.js: FETCH_Single ->', err);
    }
};

export const FETCH_MyArticles = async (token) => {
    try {
        const response = await API.get(`${ENDPOINTS.FETCH_MY_ARTICLES}`, {
            headers: {
                Authorization: 'Bearer '.concat(token),
                Language: getLanguage(),
            }});

        return response.data;
    } catch (err) {
        console.log('Error @ reviewService.js: FETCH_MyReviews ->', err);
    }
}