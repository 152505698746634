import i18next from "i18next";

// Icons
import {
  ambassadorIcon,
  enthusiastIcon,
  freshmanIcon,
} from "../constants/TrophyIcons";

export const getLevelTextFromCoffeeBeans = (coffeeBeans) => {
  if (coffeeBeans < 5000) return i18next.t("FRESHMAN");
  else if (coffeeBeans >= 5000 && coffeeBeans < 50000)
    return i18next.t("ENTHUSIAST");

  return i18next.t("AMBASSADOR");
};

export const getNextLevelTextFromCoffeeBeans = (coffeeBeans) => {
  if (coffeeBeans < 5000) return i18next.t("ENTHUSIAST");
  else if (coffeeBeans > 5000 && coffeeBeans < 50000)
    return i18next.t("AMBASSADOR");

  return "";
};

export const getPointsLeftForNextLevel = (coffeeBeans) => {
  if (coffeeBeans < 5000) return 5000 - coffeeBeans;
  else if (coffeeBeans > 5000 && coffeeBeans < 50000)
    return 50000 - coffeeBeans;

  return 0;
};

export const getGoalPointsForNextLevel = (coffeeBeans) => {
  if (coffeeBeans < 5000) return 5000;
  else if (coffeeBeans > 5000 && coffeeBeans < 50000) return 50000;

  return "";
};

export const calculatePercentageOfLevel = (coffeeBeans) => {
  const goalPoints = getGoalPointsForNextLevel(coffeeBeans);

  if (goalPoints === 0) {
    return 0;
  }

  return (coffeeBeans * 100) / goalPoints;
};

export const getIconFromLevelText = (level) => {
  if (i18next.t("FRESHMAN")) {
    return freshmanIcon;
  } else if (i18next.t("ENTHUSIAST")) {
    return enthusiastIcon;
  }

  return ambassadorIcon;
};
