import API from "../config/api/api";

const ENDPOINTS = {
  FETCH_CATEGORIES: "/productcategory/getall",
  FETCH_CATEGORY_BY_ID: "/productcategory/getbyid",
  FETCH_POPULAR_CHOICES: "/product/getmostpopular",
  FETCH_ARTICLES: "/article/getall",
  FETCH_COFFEE_FLAVOURS: "/flavour/getall",
  FETCH_COFFEE_TYPES: "/typeofcoffee/getall",
  ADD_ONBOARDING_QUESTIONS: "/onboardinganswer/add",
  FETCH_SUBCATEGORIES_AND_PRODUCTS:
    "/productsubcategory/getallwithproductsbycategoryid",
  FETCH_YOU_MIGHT_LIKE_THIS: "/product/getyoumightlikethis",
};

export const FETCH_Categories = async () => {
  console.log("productService.js: FETCH_Categories");
  try {
    const response = await API.get(ENDPOINTS.FETCH_CATEGORIES, {
      headers: {
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ productsService.js: FETCH_Categories ->", err);
  }
};

const getLanguage = () => {
  if (localStorage.getItem("language") === "sr") return 0;
  return 1;
};

export const FETCH_CategoryById = async (id) => {
  console.log("productService.js: FETCH_CategoryById");
  try {
    const response = await API.get(`${ENDPOINTS.FETCH_CATEGORY_BY_ID}/${id}`, {
      headers: {
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ productService.js FETCH_CategoryById -> ", err);
  }
};

export const FETCH_PopularChoices = async () => {
  console.log("productService.js: FETCH_PopularChoices");
  try {
    const response = await API.get(ENDPOINTS.FETCH_POPULAR_CHOICES, {
      headers: {
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ productsService.js: FETCH_PopularChoices ->", err);
  }
};

export const FETCH_Articles = async () => {
  console.log("productService.js: FETCH_Articles");
  try {
    const response = await API.get(ENDPOINTS.FETCH_ARTICLES, {
      headers: {
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ productsService.js: FETCH_Articles ->", err);
  }
};

export const ADD_Onboarding_questions = async (token, answers) => {
  console.log("productService.js: ADD_onboarding_questions");
  try {
    await API.post(ENDPOINTS.ADD_ONBOARDING_QUESTIONS, answers, {
      headers: {
        Authorization: "Bearer ".concat(token),
        Language: getLanguage(),
      },
    });
  } catch (err) {
    console.log("Error @ productsService.js: ADD_onboarding_questions ->", err);
  }
};

export const FETCH_Coffee_flavours = async (token) => {
  console.log("productService.js: FETCH_Coffee_flavours");
  try {
    const response = await API.get(ENDPOINTS.FETCH_COFFEE_FLAVOURS, {
      headers: {
        Authorization: "Bearer ".concat(token),
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ productsService.js: FETCH_Coffee_flavours ->", err);
  }
};

export const FETCH_Coffee_types = async (token) => {
  console.log("productService.js: FETCH_Coffee_types");

  try {
    const response = await API.get(ENDPOINTS.FETCH_COFFEE_TYPES, {
      headers: {
        Authorization: "Bearer ".concat(token),
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ productsService.js: FETCH_Coffee_types ->", err);
  }
};

export const FETCH_Subcategories_and_products = async (id) => {
  console.log("productService.js: FETCH_Subcategories_and_products");

  try {
    const response = await API.get(
      `${ENDPOINTS.FETCH_SUBCATEGORIES_AND_PRODUCTS}/${id}`,
      {
        headers: {
          Language: getLanguage(),
        },
      }
    );

    return response.data;
  } catch (err) {
    console.log(
      "Error @ productsService.js: FETCH_Subcategories_and_products ->",
      err
    );
  }
};

export const FETCH_You_might_like_this = async () => {
  console.log("productService.js: FETCH_You_might_like_this");

  try {
    const response = await API.get(ENDPOINTS.FETCH_YOU_MIGHT_LIKE_THIS, {
      headers: {
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log(
      "Error @ productsService.js: FETCH_You_might_like_this ->",
      err
    );
  }
};
