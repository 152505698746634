import React from "react";
import { useHistory } from "react-router";

const OrderFastButton = (props) => {
  const { category } = props;

  const history = useHistory();

  const handleClick = (id) => {
    history.push("products-page/" + id);
  };

  return (
    <button
      onClick={() => handleClick(category.id)}
      style={{ cursor: "pointer" }}
      className="order-fast-button"
    >
      <div className="order-fast-button_image-container">
        <img
          src={category.iconUrl}
          alt={category.name}
          className="order-fast-button_image"
        ></img>
      </div>
      <h3 className="order-fast-button_title">{category.name}</h3>
    </button>
  );
};

export default OrderFastButton;
