import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { store } from "./config/redux/configureStore";
import "./i18n/i18n";
import reportWebVitals from "./reportWebVitals";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import App from "./App";

const options = {
  position: positions.BOTTOM_RIGHT,
  offset: "10px",
  transition: transitions.SCALE,
  timeout: 0,
};

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <Provider store={store}>
      <App />
    </Provider>
  </AlertProvider>,
  document.getElementById("root")
);

reportWebVitals();
