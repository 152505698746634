export const BASE = "cnf/reducer/user";

export const SET_USER = BASE + "/getUser";
export const SET_GUEST = BASE + "/getGuest";
export const GET_USERS_CREDIT_CARDS = BASE + "/getUsersCreditCards";
export const DELETE_CREDIT_CARD = BASE + "/deleteCreditCard";

const initialState = {
  user: {
    id: "",
    fullName: "",
    bio: null,
    streetAddress: null,
    city: null,
    province: null,
    postalCode: "",
    phoneNumber: null,
    email: "",
    profileImageUrl: "",
    titleImageUrl: "",
    coffeebeanAmount: 0,
    userLevel: 0,
    isEmailVerified: false,
    isFirstLogin: false,
    createdAt: "",
    updatedAt: "",
    role: null,
  },

  guest: {
    fullName: "",
    streetAddress: "",
    city: "",
    province: "",
    postalCode: "",
    phoneNumber: "",
    email: "",
  },

  creditCards: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_GUEST:
      return {
        ...state,
        guest: action.payload,
      };
    case GET_USERS_CREDIT_CARDS:
      return { ...state, creditCards: action.payload };
    case DELETE_CREDIT_CARD:
      const copyCreditCards = [...state.creditCards];

      const creditCardIndex = copyCreditCards.indexOf(action.payload);
      if (creditCardIndex !== -1) {
        copyCreditCards.splice(creditCardIndex, 1);
      }

      return { ...state, creditCards: copyCreditCards };
    default:
      return state;
  }
};

export const setUser = (value) => {
  return {
    type: SET_USER,
    payload: value,
  };
};

export const setGuest = (value) => {
  return {
    type: SET_GUEST,
    payload: value,
  };
};

export const setUsersCreditCards = (payload) => {
  return {
    type: GET_USERS_CREDIT_CARDS,
    payload,
  };
};

export const setDeleteCreditCard = (payload) => {
  return {
    type: DELETE_CREDIT_CARD,
    payload,
  };
};
