import { useMemo } from "react";
import { useSelector } from "react-redux";

// Constants
import { getCurrentUserCart } from "../util/functions/OrderFlowHelper";

export const useIsProductDeliverable = () => {
  const user = useSelector((state) => state.userReducer.user);
  const cart = useSelector((state) => state.localStorageReducer.cart);
  const userCart = getCurrentUserCart(user, cart);

  const isProductDeliverable = useMemo(() => {
    let notDeliverable = userCart?.cart.some(
      (product) => !product.isDeliverable
    );

    return !notDeliverable;
  }, [userCart]);

  return { isProductDeliverable };
};
