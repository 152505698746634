import React from "react";
import { useTranslation } from "react-i18next";
import ProgressBar from "@ramonak/react-progress-bar";

// Constants
import {
  calculatePercentageOfLevel,
  getGoalPointsForNextLevel,
  getLevelTextFromCoffeeBeans,
  getNextLevelTextFromCoffeeBeans,
} from "../../../util/functions/CoffeeBeansHelper";

// Icons
import NextLevelIcon from "../nextLevelIcon/NextLevelIcon";

const ProgressOverview = (props) => {
  const { coffeeBeansAmount } = props;

  const { t } = useTranslation();

  return (
    <div className="progress-overview-container">
      <div className="coffeebeans-data">
        <div className="first-row">
          <span className="coffeebeans-data-text">{t("TOTAL_COLLECTED")}</span>

          <span>{t("GOAL")}</span>
        </div>

        <div className="second-row">
          <span className="coffeebeans-data-text">{`${coffeeBeansAmount} Coffee Beans`}</span>

          <span>{getGoalPointsForNextLevel(coffeeBeansAmount)}</span>
        </div>

        <ProgressBar
          bgColor={"#E23036"}
          completed={calculatePercentageOfLevel(coffeeBeansAmount)}
          isLabelVisible={false}
        />

        <div className="fourth-row">
          <span>{getLevelTextFromCoffeeBeans(coffeeBeansAmount)}</span>

          <span>{getNextLevelTextFromCoffeeBeans(coffeeBeansAmount)}</span>
        </div>
      </div>

      <div className="next-level-container">
        <NextLevelIcon coffeeBeansAmount={coffeeBeansAmount} />
      </div>
    </div>
  );
};

export default ProgressOverview;
