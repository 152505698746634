import React from "react";
import i18n from "../../i18n/i18n";

const link = (
  <a
    style={{
      fontFamily: "Poppins-Regular",
      fontSize: 16,
      color: "#e23036",
      textDecoration: "none",
    }}
    href="mailto: info@cafe-factory.net"
  >
    info@cafe-factory.net
  </a>
);

const getTitle = (title, blankRow) => {
  return (
    <p
      style={{
        fontFamily: "Poppins-Regular",
        fontSize: 18,
        color: "black",
      }}
    >
      {title}
      {blankRow && "\n"}
    </p>
  );
};

const getPartOfDisplayText = (
  title,
  titleBlankRow,
  text,
  hasLink,
  blankRow,
  secondText
) => {
  return (
    <div>
      {title && getTitle(title, titleBlankRow)}
      <p
        style={{
          fontFamily: "Poppins-Regular",
          fontSize: 16,
          color: "#383838",
        }}
      >
        {text}
        {hasLink && link}
        {blankRow && "\n"}
        {secondText}
      </p>
    </div>
  );
};

export const getDisplayText = (privacyPolicy) => {
  if (privacyPolicy) {
    return (
      <div>
        {getPartOfDisplayText(
          undefined,
          true,
          i18n.t("PRIVACY_POLICY_TEXT"),
          true,
          false,
          i18n.t("PRIVACY_POLICY_TEXT_SECOND_PART")
        )}
      </div>
    );
  }
  return (
    <div>
      {getPartOfDisplayText(
        undefined,
        false,
        i18n.t("TERMS_OF_USE_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("DISCLAIMER_AND_LIMITATION_OF_LIABILITY_TITLE"),
        false,
        i18n.t("DISCLAIMER_AND_LIMITATION_OF_LIABILITY_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("PRIVACY_POLICY_TITLE"),
        true,
        i18n.t("PRIVACY_POLICY_TEXT"),
        true,
        false,
        i18n.t("PRIVACY_POLICY_TEXT_SECOND_PART")
      )}
      {getPartOfDisplayText(
        i18n.t("USER_RIGHTS_TITLE"),
        false,
        i18n.t("USER_RIGHTS_TEXT"),
        true,
        true,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("WHO_DO_WE_SHARE_DATA_WITH_TITLE"),
        false,
        i18n.t("WHO_DO_WE_SHARE_DATA_WITH_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("DATA_TRANSFER_OUTSIDE_EEA_TITLE"),
        false,
        i18n.t("DATA_TRANSFER_OUTSIDE_EEA_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("COOKIE_POLICY_TITLE"),
        false,
        i18n.t("COOKIE_POLICY_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("COOKIES_TITLE"),
        false,
        i18n.t("COOKIES_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("WHAT_ARE_COOKIES_TITLE"),
        false,
        i18n.t("WHAT_ARE_COOKIES_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("PURPOSE_OF_COOKIES_TITLE"),
        false,
        i18n.t("PURPOSE_OF_COOKIES_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("FOR_WHAT_AND_HOW_WE_USE_COOKIES_TITLE"),
        false,
        i18n.t("FOR_WHAT_AND_HOW_WE_USE_COOKIES_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("PAYMENT_METHODS_TITLE"),
        false,
        i18n.t("PAYMENT_METHODS_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("BANK_CARD_DATA_PROTECTION_TITLE"),
        false,
        i18n.t("BANK_CARD_DATA_PROTECTION_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("REFUND_TITLE"),
        false,
        i18n.t("REFUND_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("PAYMENT_CONVERSION_STATEMENT_TITLE"),
        false,
        i18n.t("PAYMENT_CONVERSION_STATEMENT_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("CONTACT_INFORMATION_TITLE"),
        false,
        i18n.t("CONTACT_INFORMATION_NUMBER"),
        false,
        false,
        i18n.t("CONTACT_INFORMATION_MAIL"),
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("DELIVERY_TITLE"),
        false,
        i18n.t("DELIVERY_TEXT"),
        false,
        false,
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("CLAIMS_POLICY_TITLE"),
        false,
        i18n.t("CLAIMS_POLICY_DAMAGE_DURING_TRANSPORT"),
        false,
        true,
        i18n.t("CLAIMS_POLICY_DAMAGE_WRONG_TYPE_OF_GOODS"),
        ""
      )}
      {getPartOfDisplayText(
        i18n.t("VAT_STATMENT_TITLE"),
        false,
        i18n.t("VAT_STATMENT_TEXT"),
        false,
        true,
        ""
      )}
    </div>
  );
};
