import React from "react";

// Images
import spinner from "../../assets/images/loading_spinner.gif";

const LoadingIndicator = () => {
  return (
    <div className="loading-spinner">
      <img id="spinner" alt="spinner" src={spinner}></img>
    </div>
  );
};

export default LoadingIndicator;
