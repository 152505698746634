import home from "./home";
import knowledge from "./knowledge";
import community from "./community";
import register from "./register";
import onboarding from "./onboarding";
import profile from "./profile";
import singleProduct from "./singleProduct";
import payment from "./payment";
import order from "./order";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  translation: {
    ...home,
    ...knowledge,
    ...community,
    ...register,
    ...onboarding,
    ...profile,
    ...singleProduct,
    ...payment,
    ...order,
  },
};
