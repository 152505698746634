import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Components
import HeaderBackground from "../../components/landingPage/headerBackground/HeaderBackground";
import OrderFast from "../../components/landingPage/orderFast/OrderFast";
import PopularChoices from "../../components/landingPage/popularChoices/PopularChoices";
import AllCategories from "../../components/landingPage/allCategories/AllCategories";
import Knowledge from "../../components/landingPage/knowledge/Knowledge";
import Community from "../../components/landingPage/community/Community";
import OrderHistory from "../../components/userProfilePage/OrderHistory/OrderHistory";

const LandingPage = () => {
  const token = useSelector((state) => state.authReducer.token);
  const activeOrders = useSelector(
    (state) => state.localStorageReducer.activeOrders
  );

  const [nonLoggedUserActiveOrders, setNonLoggedUserActiveOrders] = useState();

  const { t } = useTranslation();

  const handleClick = () => {
    window.scrollTo(0, document.body.scrollHeight / 2.95);
  };

  useEffect(() => {
    const nonLoggedUserIndex = activeOrders?.findIndex(
      (order) => order.userID === 0
    );

    if (nonLoggedUserIndex >= 0) {
      setNonLoggedUserActiveOrders(
        activeOrders[nonLoggedUserIndex].activeOrders
      );
    }
  }, [activeOrders]);

  const closeAlert = (orderId) => {
    setNonLoggedUserActiveOrders(prevState => prevState.filter(order => {
        const orderToCheck = order?.order ? order?.order : order;

        return orderToCheck.id !== orderId;
      })
    );
  }

  return (
    <div>
      <HeaderBackground />
      <div className="landing-page-main-container">
        <div className="landing-page-padding-template">
          <div className="non-logged-user-order-map">
            {!token &&
              nonLoggedUserActiveOrders?.length > 0 &&
              nonLoggedUserActiveOrders?.map((order, index) => (
                <OrderHistory
                  key={`${index}-${nonLoggedUserActiveOrders.length}`}
                  nonLoggedUser
                  order={order}
                  setShowAlert={closeAlert}
                />
              ))}
          </div>
          <OrderFast />
          <PopularChoices />
          <button onClick={handleClick} className="primary-button-medium">
            {t("EXPLORE_ALL")}
          </button>
          <AllCategories heading="All Categories" />
        </div>
        <Knowledge />
        <Community />
        <div className="footer-background" />
      </div>
    </div>
  );
};

export default LandingPage;
