import API from "../config/api/api";

const ENDPOINTS = {
  REGISTER: "/user/register",
  LOGIN: "/auth/userlogin",
};

export const REGISTER_User = async (data, alert) => {
  try {
    const response = await API.post(ENDPOINTS.REGISTER, data);

    return response.data;
  } catch (err) {
    alert.error(err.response.data.title);
    console.log("Error @ authService.js: REGISTER_User ->", err);
  }
};

export const LOGIN_User = async (data, alert) => {
  try {
    const response = await API.post(ENDPOINTS.LOGIN, data);

    return response.data;
  } catch (err) {
    alert.error(err.response.data.title);
    console.log("Error @ authService.js: LOGIN_User ->", err);
  }
};
