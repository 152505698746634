const getArticlesForDisplayHelper = (selectedButton, articles) => {
  switch (selectedButton) {
    case 1:
      return articles.filter((a) => a.articleCategory === 0);
    case 2:
      return articles.filter((a) => a.articleCategory === 1);
    default:
      break;
  }
};

export default getArticlesForDisplayHelper;
