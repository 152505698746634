import React from "react";
import { useHistory } from "react-router";

const CategoryButton = (props) => {
  const { category } = props;

  const history = useHistory();

  const handleClick = (id) => {
    history.push("products-page/" + id);
  };

  return (
    <button
      className="category-button"
      onClick={() => handleClick(category.id)}
      style={{
        backgroundImage: `url(${category.imageUrl})`,
        backgroundSize: "cover",
      }}
    >
      <p className="category-button_title">{category.name}</p>
    </button>
  );
};

export default CategoryButton;
