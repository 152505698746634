import API from "../config/api/api";

const ENDPOINTS = {
  CREATE_ORDER: "/order/add",
  GET_ORDER_BY_ID: "/order/getbyid",
  FETCH_ALL_ORDERS_BY_USERID: "/order/getallbyuserid",
  FETCH_DELIVERY_PRICE: "/order/getdeliverypricefororder",
  FETCH_PAYMENT_RESULT: "/payment/GetPaymentResultDataByOrderId",
  CHECK_ORDER_AVAILABILITY: "/order/CheckOrderAvailability",
  INITIALIZE_PAYMENT: "/payment/InitializePayment",
};

const getLanguage = () => {
  if (localStorage.getItem("language") === "sr") return 0;
  return 1;
};

export const CREATE_order = async (data) => {
  try {
    const response = await API.post(ENDPOINTS.CREATE_ORDER, data, {
      headers: {
        Language: getLanguage(),
      }
    });

    return response.data;
  } catch (err) {
    console.log("Error @ searchService.js: SEARCH_ArticlesByTitle ->", err);
  }
};

export const GET_order_by_id = async (id) => {
  try {
    const response = await API.get(`${ENDPOINTS.GET_ORDER_BY_ID}/${id}`, {
      headers: {
        Language: getLanguage(),
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error @ searchService.js: SEARCH_ArticlesByTitle ->", err);
  }
};

export const FETCH_AllOrdersByUserId = async (id, token) => {
  try {
    const response = await API.get(
      `${ENDPOINTS.FETCH_ALL_ORDERS_BY_USERID}/${id}`,
      {
        headers: {
          Authorization: "Bearer ".concat(token),
          Language: getLanguage(),
        },
      }
    );

    return response.data;
  } catch (err) {
    console.log("Error @ orderService.js: FETCH_AllOrdersByUser ->", err);
  }
};

export const FETCH_DeliveryPrice = async (orderLine) => {
  try {
    const deliveryPrice = await API.post(
      ENDPOINTS.FETCH_DELIVERY_PRICE,
      orderLine
    );

    return deliveryPrice.data;
  } catch (err) {
    console.log("Error @ orderService.js: FETCH_DeliveryPrice ->", err);
  }
};

export const FETCH_PaymentResult = async (orderID) => {
  try {
    const paymentResult = await API.get(
      `${ENDPOINTS.FETCH_PAYMENT_RESULT}/${orderID}`
    );

    return paymentResult.data;
  } catch (err) {
    console.log("Error @ orderService.js: FETCH_PaymentResult ->", err);
  }
};

export const CHECK_OrderAvailability = async (orderAvailabilityObject) => {
  try {
    const orderAvailability = await API.post(
      ENDPOINTS.CHECK_ORDER_AVAILABILITY,
      orderAvailabilityObject
    );

    return orderAvailability.data;
  } catch (err) {
    console.log("Error @ orderService.js: CHECK_OrderAvailability ->", err);
  }
};

export const INITIALIZE_Payment = async (paymentObject) => {
  try {
    const paymentResult = await API.post(
      ENDPOINTS.INITIALIZE_PAYMENT,
      paymentObject
    );

    return paymentResult.data;
  } catch (err) {
    console.log("Error @ orderService.js: INITIALIZE_Payment ->", err);
  }
};
