import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";

const ChoseYourTimeModal = (props) => {
  const [time, setTime] = useState({
    hour: "",
    minutes: "",
  });

  const { showModal, handleShowModal, handleOrderChange } = props;

  const { t } = useTranslation();

  const alert = useAlert();

  const handlePressConfirmButton = () => {
    if (time.hour && time.minutes) {
      let arrivalTime = new Date();
      arrivalTime.setHours(time.hour);
      arrivalTime.setMinutes(time.minutes);

      if (new Date() >= arrivalTime) {
        alert.error(t("ENTER_VALID_TIME"));
        return;
      }

      handleOrderChange("ArrivalTime", arrivalTime.toISOString());
      handleShowModal(undefined);
    } else {
      if (!time.hour) {
        alert.error("ENTER_HOURS");
      } else if (!time.minutes) {
        alert.error("ENTER_MINUTES");
      }
    }
  };

  const handleChangeTime = (name, value) => {
    setTime({ ...time, [name]: value });
  };

  useEffect(() => {
    let modal = document.getElementById("time-modal");
    if (showModal) {
      modal.style.display = "block";
    } else {
      modal.style.display = "none";
    }
  }, [showModal]);

  return (
    <div id="time-modal">
      <div className="time-modal-container">
        <div className="time-modal-content">
          <div className="time-modal-clock">
            <input
              id="modal-hour"
              className="time-modal-set-time set-hour"
              placeholder={new Date().getHours()}
              value={time?.hour}
              onChange={(e) => handleChangeTime("hour", e.target.value)}
              type="number"
              min={0}
              max={23}
              onKeyDown={(evt) => "return false" && evt.preventDefault()}
            />

            <span style={{ zIndex: "2", margin: "0 10px" }}>:</span>

            <input
              id="modal-minutes"
              className="time-modal-set-time"
              placeholder={new Date().getMinutes()}
              value={time?.minutes}
              onChange={(e) => handleChangeTime("minutes", e.target.value)}
              type="number"
              min={0}
              max={59}
              onKeyDown={(evt) => "return false" && evt.preventDefault()}
            />
          </div>
        </div>

        <div className="time-confirm-button-container">
          <button
            onClick={() => handleShowModal(undefined)}
            className="cancel-button"
          >
            {t("CANCEL")}
          </button>

          <button
            onClick={handlePressConfirmButton}
            className="shop-location-time-confirm-button"
          >
            {t("CONFIRM")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChoseYourTimeModal;
