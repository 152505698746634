import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Constants
import { exportForm } from "./paymentForm";

const AddCardPage = () => {
  const token = useSelector((state) => state.authReducer.token);
  const contentRef = useRef();

  // const publicIntegrationKey = "uUj7Lwx2FuyukPKOvION"; // LOCAL
  const publicIntegrationKey = "U1Cq7DfDVUJpZOau0zia"; // PRODUCTION

  useEffect(() => {
    const fragment = document
      .createRange()
      .createContextualFragment(
        `<div style="width: 65%">${exportForm(
          publicIntegrationKey,
          token
        )}</div>`
      );

    contentRef.current.appendChild(fragment);

    const element = document.getElementById("div_payment_form");

    return () => {
      element.parentNode.removeChild(element);
    };
  });

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="addCardPageContainer">
        <h2 className="register-title">{t("ADD_YOUR_CARD")}</h2>
        <h3 className="register-subtitle">{t("ENTER_CARD")}</h3>
      </div>

      <div className="paymentFormContainer" ref={contentRef} />
    </React.Fragment>
  );
};

export default AddCardPage;
