import i18n from "../../i18n/i18n";
import i18next from "i18next";
import moment from "moment";

// Constants
import { addZeroes } from "./AddZeroes";

const getEarnedCoffeeBeans = (order) => {
  let message = " " + i18n.t("COFFEE_BEANS_EARNED");
  if (order?.coffeebeansTotal !== 0) {
    return order?.coffeebeansTotal + message;
  }

  return "";
};

const getRejectionText = (order) => {
  if (i18next.language === "SR") {
    return order?.rejectionReasonSerbian;
  }

  return order?.rejectionReasonEnglish;
};

export const getOrderStatusMessage = (order) => {
  switch (order.orderStatus) {
    case 3:
      return i18n.t("ORDER_ACCEPTED") + " " + getEarnedCoffeeBeans(order);
    case 4:
      return i18n.t("ORDER_REJECTED") + " " + getRejectionText(order);
    default:
      return "";
  }
};

// TODO
export const getAlertTextFromBackoffice = (order, paymentResult) => {
  let productsText = order.products
    .map((o) => `${o.amount} x ${o.productName}\n`)
    .join("");

  const formattedDate = moment
    .utc(order?.date)
    .local()
    .format("YYYY-MM-DD HH:mm:ss");

  const orderPriceText = addZeroes(parseInt(order.priceTotal));

  return `${i18next.t("ORDER_STATUS")}: ${getOrderStatusMessage(order)}
        \n${i18next.t("ORDER_NUMBER")}:\n${order.id}
        \n${i18next.t("TRANSACTION_TIME")}:\n${formattedDate}
        \n${
          paymentResult?.returnData?.type
            ? i18n.t("CARD_TYPE") +
              ":" +
              paymentResult?.returnData?.type +
              " **** - " +
              paymentResult?.returnData?.lastFourDigits
            : ""
        }
        \n${
          paymentResult?.extraData?.authCode
            ? i18n.t("BANK_AUTHORIZATION_CODE") +
              ": " +
              paymentResult?.extraData?.authCode
            : i18n.t("BANK_AUTHORIZATION_CODE") + ":" + "XXXX"
        }                
        \n${i18next.t("PRODUCTS")}:\n${productsText}        
        \n${i18next.t("ORDER_PRICE")}: ${orderPriceText} ${i18next.t("DIN")}`;
};

export const getAlertTextAfterPayment = (
  order,
  paymentResult,
  userActiveOrders,
  prevActiveOrders
) => {
  const prevActiveOrdersLength = prevActiveOrders?.length
    ? prevActiveOrders.length
    : 0;

  if (userActiveOrders?.length > prevActiveOrdersLength) {
    const orderPriceText = addZeroes(parseInt(order.priceTotal));

    const formattedDate = moment
      .utc(order?.date)
      .local()
      .format("YYYY-MM-DD HH:mm:ss");

    return `${i18next.t("SUCCESS_TRANSACTION")}
        \n${i18next.t("ORDER_NUMBER")}:\n${order.id}
        \n${i18next.t("TRANSACTION_TIME")}:\n${formattedDate}
        \n${
          paymentResult?.returnData?.type
            ? i18n.t("CARD_TYPE") +
              ":" +
              paymentResult?.returnData?.type +
              " **** - " +
              paymentResult?.returnData?.lastFourDigits
            : ""
        }
        \n${
          paymentResult?.extraData?.authCode
            ? i18n.t("BANK_AUTHORIZATION_CODE") +
              ": " +
              paymentResult?.extraData?.authCode
            : i18n.t("BANK_AUTHORIZATION_CODE") + ":" + "XXXX"
        }    
        \n${i18next.t("ORDER_PRICE")}: ${orderPriceText} ${i18next.t("DIN")}`;
  }
};

export const formatTemporaryCart = (amount, productSize, product) => {
  let temporaryCart = [];
  let productData = {
    amount: amount,
    grindOption: 0,
    price: productSize?.price,
    productId: product.id,
    productName: product.name,
    sizeId: productSize?.id,
    sizeLabel: productSize?.sizeLabel,
    sizeVolume: productSize?.sizeVolume,
    isDeliverable: product.isDeliverable,
    subcategoryId: product.subcategoryId,
  };

  temporaryCart.push(productData);
  return temporaryCart;
};

export const getCurrentUserIndex = (user, temporaryCartObject) => {
  if (user?.id) {
    return temporaryCartObject?.findIndex((obj) => obj.userID === user.id);
  } else {
    return temporaryCartObject?.findIndex((obj) => obj.userID === 0);
  }
};

export const getCurrentUserCart = (user, temporaryCartObject) => {
  if (user?.id) {
    return temporaryCartObject?.find((obj) => obj.userID === user.id);
  } else {
    return temporaryCartObject?.find((obj) => obj.userID === 0);
  }
};

export const addToEmptyCart = (
  user,
  newCart,
  temporaryCartObject,
  temporaryCart,
  addToCart
) => {
  newCart.userID = user?.id || 0;
  newCart.cart = temporaryCart;

  temporaryCartObject?.push(newCart);

  addToCart(temporaryCartObject);
};

const handleAddAlreadyPresentProduct = (cart, temporaryCart) => {
  temporaryCart.forEach((item) => {
    let found = false;
    cart.forEach((cartItem) => {
      if (
        item.productId === cartItem.productId &&
        item.sizeId === cartItem.sizeId
      ) {
        cartItem.amount += item.amount;
        found = true;
      }
    });

    !found && cart.push(item);
  });
};

export const addToExistingUserCart = (
  currentUserIndex,
  temporaryCartObject,
  temporaryCart,
  addToCart
) => {
  let copyCart = [...temporaryCartObject[currentUserIndex].cart];

  if (copyCart.length === 0) {
    copyCart = temporaryCart;

    temporaryCartObject[currentUserIndex].cart = copyCart;

    addToCart(temporaryCartObject);
  } else {
    handleAddAlreadyPresentProduct(copyCart, temporaryCart);
    temporaryCartObject[currentUserIndex].cart = copyCart;

    addToCart(temporaryCartObject);
  }
};

export const handleAddToEmptyObject = (
  newObject,
  fieldName,
  object,
  temporaryObject,
  userID,
  updateObject
) => {
  newObject.userID = userID || 0;
  newObject[fieldName] = [];

  newObject[fieldName].push(object);

  temporaryObject.push(newObject);
  updateObject(temporaryObject);
};

export const handleAddToExistingObject = (
  userIndex,
  fieldName,
  temporaryObject,
  object,
  updateObject
) => {
  let copyObject = [...temporaryObject[userIndex][fieldName]];

  if (copyObject) {
    copyObject.push(object);

    temporaryObject[userIndex][fieldName] = copyObject;
    updateObject(temporaryObject);
  }
};

export const deleteExistingActiveOrder = (
  userIndex,
  temporaryActiveOrdersObject,
  orderID,
  updateActiveOrders
) => {
  let copyActiveOrders = [
    ...temporaryActiveOrdersObject[userIndex]?.activeOrders,
  ];

  const orderIndex = copyActiveOrders.findIndex(
    (activeOrder) => activeOrder.id === orderID
  );

  copyActiveOrders.splice(orderIndex, 1);

  temporaryActiveOrdersObject[userIndex].activeOrders = copyActiveOrders;
  updateActiveOrders(temporaryActiveOrdersObject);
};

export const updateWebSocketConnections = (
  temporaryObject,
  newObject,
  fieldName,
  object,
  userID,
  updateWebSocketConnections,
  userIndex
) => {
  if (temporaryObject.length === 0) {
    handleAddToEmptyObject(
      newObject,
      fieldName,
      object,
      temporaryObject,
      userID,
      updateWebSocketConnections
    );
  } else {
    if (userIndex >= 0) {
      handleAddToExistingObject(
        userIndex,
        fieldName,
        temporaryObject,
        object,
        updateWebSocketConnections
      );
    } else {
      handleAddToEmptyObject(
        newObject,
        fieldName,
        object,
        temporaryObject,
        userID,
        updateWebSocketConnections
      );
    }
  }
};
