import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDebounce from "../../hooks/useDebounce";

// Constants
import { getSearchIcon } from "../../util/constants/Icons";

const SearchInputField = (props) => {
  const { searchFunction, returnResult } = props;

  const { t } = useTranslation();

  const [search, setSearch] = useState({
    query: "",
  });

  const searchWithDebounce = useDebounce(search.query, 300);

  useEffect(() => {
    if (searchWithDebounce && returnResult && searchFunction) {
      const searchQuery = searchWithDebounce.target.value;

      (async function searchArticles(searchQuery) {
        const articles = await searchFunction(searchQuery);

        returnResult(articles);
      })(searchQuery);
    }
  }, [searchWithDebounce, returnResult, searchFunction]);

  const handleOnInputChange = (value) => {
    setSearch({
      query: value,
    });
  };

  return (
    <div className="search-container">
      <label>
        <input
          type="text"
          className="search-bar"
          placeholder={t("SEARCH")}
          onChange={handleOnInputChange}
        />

        <span className="search-icon">{getSearchIcon()}</span>
      </label>
    </div>
  );
};

export default SearchInputField;
