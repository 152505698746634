import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Store
import { DELETE_CREDIT_CARD_SAGA } from "../../../store/sagas/userSaga";

// Images
import CreditCardLogo from "../../../assets/icons/credit_card.svg";
import DeleteIcon from "../../../assets/images/singleProductPage/delete_button.png";

const CreditCard = (props) => {
  const token = useSelector((state) => state.authReducer.token);

  const { card } = props;

  const dispatch = useDispatch();

  const handleDelete = (card) => {
    dispatch({
      type: DELETE_CREDIT_CARD_SAGA,
      creditCard: card,
      token: token,
    });
  };

  return (
    <div className="credit-card">
      <img src={CreditCardLogo} alt="credit-card" />
      <div>
        <p className="credit-card_credit-card-name">{card.cardName}</p>
        <p className="credit-card_credit-card-number">
          XXXX-XXXX-{card.lastDigits}
        </p>
      </div>
      <button
        className="credit-card_delete-button"
        onClick={() => handleDelete(card)}
      >
        <img
          src={DeleteIcon}
          alt="delete"
          className="credit-card_delete-button_icon"
        />
      </button>
    </div>
  );
};

export default CreditCard;
