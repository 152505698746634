// Components
import ChoseYourTimeModal from "../../components/Modal/choseYourTimeModal/ChoseYourTimeModal";
import ShopLocationModal from "../../components/Modal/shopLocationModal/ShopLocationModal";

export const handleModals = (
  showModal,
  handleShowModal,
  handleOrderChange,
  handleShopLocationChange
) => {
  switch (showModal) {
    case "shopLocation":
      return (
        <ShopLocationModal
          handleShowModal={handleShowModal}
          handleOrderChange={handleOrderChange}
          handleShopLocationChange={handleShopLocationChange}
          showModal={showModal}
        />
      );
    case "timePicker":
      return (
        <ChoseYourTimeModal
          handleShowModal={handleShowModal}
          showModal={showModal}
          handleOrderChange={handleOrderChange}
        />
      );
    default:
      return undefined;
  }
};
