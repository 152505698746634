import React from "react";

const UserDetailsSmall = (props) => {
  const { imageUrl, fullName, levelText } = props;

  return (
    <div className="user-data">
      <img alt="user-profile-img" src={`${imageUrl}`} />

      <div className="text-data">
        <span className="user-name-font">{fullName}</span>

        {levelText && <span className="user-level-text-font">{levelText}</span>}
      </div>
    </div>
  );
};

export default UserDetailsSmall;
