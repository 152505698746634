import { call, put, takeEvery } from "@redux-saga/core/effects";

// Services
import {
  getActiveOrders,
  getCart,
  getToken,
  setActiveOrders,
  setCart,
} from "../../services/localStorageService";

// Reducers
import { userLogin } from "../reducers/authReducer";
import {
  setActiveOrdersToReducer,
  setCartToReducer,
} from "../reducers/localStorageReducer";

const BASE = "cnf/saga/localStorage";

export const SET_TOKEN_SAGA = BASE + "/setToken";
export const FETCH_CART_SAGA = BASE + "/fetchCart";
export const UPDATE_CART_SAGA = BASE + "/updateCart";
export const FETCH_ACTIVE_ORDERS_SAGA = BASE + "/fetchActiveOrders";
export const UPDATE_ACTIVE_ORDERS_SAGA = BASE + "/updateActiveOrders";

export function* setToken() {
  console.log(`localStorage.js: saga: ${SET_TOKEN_SAGA}`);
  try {
    const token = yield call(getToken);

    yield put(userLogin(token));
  } catch (err) {
    console.log(`Error @ localStorage.js -> saga: ${SET_TOKEN_SAGA}`, err);
  }
}

export function* fetchCart() {
  console.log(`localStorage.js: saga: ${FETCH_CART_SAGA}`);
  try {
    const cart = yield call(getCart);

    yield put(setCartToReducer(cart));
  } catch (err) {
    console.log(`Error @ localStorage.js -> saga: ${FETCH_CART_SAGA}`, err);
  }
}

export function* updateCart({ cart }) {
  console.log(`localStorage.js: saga: ${UPDATE_CART_SAGA}`);
  try {
    const newCart = yield call(setCart, cart);

    yield put(setCartToReducer(newCart));
  } catch (err) {
    console.log(`Error @ localStorage.js -> saga: ${UPDATE_CART_SAGA}`, err);
  }
}

export function* fetchActiveOrders() {
  console.log(`localStorage.js: saga: ${FETCH_ACTIVE_ORDERS_SAGA}`);
  try {
    const activeOrders = yield call(getActiveOrders);

    yield put(setActiveOrdersToReducer(activeOrders));
  } catch (err) {
    console.log(
      `Error @ localStorage.js -> saga: ${FETCH_ACTIVE_ORDERS_SAGA}`,
      err
    );
  }
}

export function* updateActiveOrders({ activeOrders }) {
  console.log(`localStorage.js: saga: ${UPDATE_ACTIVE_ORDERS_SAGA}`);
  try {
    const newActiveOrders = yield call(setActiveOrders, activeOrders);

    yield put(setActiveOrdersToReducer(newActiveOrders));
  } catch (err) {
    console.log(
      `Error @ localStorage.js -> saga: ${UPDATE_ACTIVE_ORDERS_SAGA}`,
      err
    );
  }
}

export function* watchSetToken() {
  yield takeEvery(SET_TOKEN_SAGA, setToken);
}

export function* watchFetchCart() {
  yield takeEvery(FETCH_CART_SAGA, fetchCart);
}

export function* watchUpdateCart() {
  yield takeEvery(UPDATE_CART_SAGA, updateCart);
}

export function* watchFetchActiveOrders() {
  yield takeEvery(FETCH_ACTIVE_ORDERS_SAGA, fetchActiveOrders);
}

export function* watchUpdateActiveOrders() {
  yield takeEvery(UPDATE_ACTIVE_ORDERS_SAGA, updateActiveOrders);
}
