export const disableRegistrationButton = (
  user,
  checkButtonClicked,
  isValidEmail,
  isValidPassword
) => {
  if (
    !user.email ||
    !user.password ||
    !user.fullName ||
    !checkButtonClicked ||
    !isValidEmail ||
    !isValidPassword
  )
    return true;
  else return false;
};
