import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactFileReader from "react-file-reader";

// Services
import { PUT_UserProfileImage } from "../../../services/userService";

// Constants
import {
  getIconFromLevelText,
  getLevelTextFromCoffeeBeans,
} from "../../../util/functions/CoffeeBeansHelper";

// Icons
import { trophyIcon, uploadIcon } from "../../../util/constants/Icons";

const FloatingUser = (props) => {
  const token = useSelector((state) => state.authReducer.token);

  const { reducerUser, handleSetProfileImageChange, profileImageChange } =
    props;

  const { t } = useTranslation();

  const handleFiles = (files) => {
    (async function UpdateCoverPhoto() {
      const formData = new FormData();

      formData.append("Image", files[0], files[0].name);

      await PUT_UserProfileImage(formData, token);
      handleSetProfileImageChange(!profileImageChange);
    })();
  };

  return (
    <div className="floating-user-container">
      <div className="images-container">
        <img
          alt="profile-img"
          className="image profile-image"
          src={`${reducerUser?.profileImageUrl}`}
        />

        <div className="user-level-image">
          {trophyIcon}
          {getIconFromLevelText(
            getLevelTextFromCoffeeBeans(reducerUser?.coffeebeanAmount)
          )}
        </div>

        <div className="edit-image">
          <ReactFileReader handleFiles={handleFiles}>
            <button className="upload-btn">{uploadIcon}</button>
          </ReactFileReader>
        </div>
      </div>

      <div className="text-container">
        <span className="user-name-font">{reducerUser?.fullName}</span>

        <span className="coffeebeans-amount-font">
          {reducerUser?.coffeebeanAmount} {t("COFFEBEANS")}
        </span>
      </div>
    </div>
  );
};

export default FloatingUser;
