import { useMemo } from "react";
import { useSelector } from "react-redux";

// Constants
import { TAKE_AWAY_SUBCATEGORY_ID } from "../util/functions/OrderCheckoutFunctions";
import { getCurrentUserCart } from "../util/functions/OrderFlowHelper";

export const useIsProductToGo = () => {
  const user = useSelector((state) => state.userReducer.user);
  const cart = useSelector((state) => state.localStorageReducer.cart);
  const userCart = getCurrentUserCart(user, cart);

  const isProductToGo = useMemo(() => {
    const isNotProductToGo = userCart?.cart?.some(
      (product) => product.subcategoryId !== TAKE_AWAY_SUBCATEGORY_ID
    );

    return !isNotProductToGo;
  }, [userCart]);

  return { isProductToGo };
};
