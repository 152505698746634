import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Services
import { FETCH_You_might_like_this } from "../../services/productsService";

// Constants
import YouMightBeInterestedButton from "../buttons/youMightBeInterestedButton/YouMightBeInterestedButton";

const YouMightBeInterested = () => {
  const [youMightBeInterested, setYouMightBeInterested] = useState([]);
  const [randomYouMightBeInterested, setRandomYouMightBeInterested] = useState(
    []
  );

  const { t } = useTranslation();

  useEffect(() => {
    async function fetchYouMightBeInterested() {
      const youMightBeInterested = await FETCH_You_might_like_this();

      setYouMightBeInterested(youMightBeInterested);
    }

    fetchYouMightBeInterested();
  }, []);

  useEffect(() => {
    setRandomYouMightBeInterested(
      youMightBeInterested.sort(() => 0.5 - Math.random()).slice(0, 3)
    );
  }, [youMightBeInterested]);

  return (
    <div>
      <p className="you-might-be-interested-subtitle">
        {t("YOU_MIGHT_BE_INTERESTED")}
      </p>
      <div className="you-might-be-interested-container">
        {randomYouMightBeInterested.map((interestedItem) => (
          <YouMightBeInterestedButton
            key={interestedItem.id}
            interestedItem={interestedItem}
          />
        ))}
      </div>
    </div>
  );
};

export default YouMightBeInterested;
