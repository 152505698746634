import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Images
import editImage from "../../../assets/images/orderCheckout/edit.png";

const ShippingInfoContainer = (props) => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.userReducer.user);

  const [allowEdit, setAllowEdit] = useState(false);

  const { addressData, handleChange } = props;

  const handleAllowEdit = () => {
    setAllowEdit(!allowEdit);
  };

  return (
    <div className="shipping-info-container">
      <div className="shipping-info-container_title-container">
        <p className="card-info-title">{t("SHIPPING_INFO")}</p>
        <button
          className="shipping-info-container_edit-button"
          onClick={handleAllowEdit}
        >
          <img src={editImage} alt="Edit" />
        </button>
      </div>

      <input
        className="input-payment-card"
        placeholder={t("STREET_ADDRESS_REQUIRED")}
        type="text"
        value={addressData?.streetAddress || user?.streetAddress}
        onChange={(e) => handleChange("streetAddress", e.target.value)}
        disabled={!allowEdit}
      />

      <div className="shipping-info-container_input-container">
        <input
          className="input-payment-card_city-input"
          placeholder={t("CITY_REQUIRED")}
          value={addressData?.city || user?.city}
          onChange={(e) => handleChange("city", e.target.value)}
          disabled={!allowEdit}
        />

        <input
          className="input-payment-card_post-code-input"
          max="999"
          min="001"
          placeholder={t("POST_CODE_REQUIRED")}
          type="number"
          value={addressData?.postalCode || user?.postalCode}
          onChange={(e) => handleChange("postalCode", e.target.value)}
          disabled={!allowEdit}
        />
      </div>

      <input
        className="input-payment-card"
        placeholder={t("PHONE_NUMBER_REQUIRED")}
        type="text"
        value={addressData?.phoneNumber || user?.phoneNumber}
        onChange={(e) => handleChange("phoneNumber", e.target.value)}
        disabled={!allowEdit}
      />
    </div>
  );
};

export default ShippingInfoContainer;
