export const printFlavoursAndTypesList = (names) => {
  let result = "";

  for (let name of names) {
    result += `${name}, `;
  }

  result = result.length > 2 ? result.substring(0, result.length - 2) : result;

  return result;
};
